<template>
  <div id="user">
    <a-row style="margin-bottom: 20px" :gutter="16">
      <a-col :span="5">
        <a-input placeholder="输入用户姓名" v-model="search.name" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入用户手机号" v-model="search.phone" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入用户身份证号" v-model="search.idCard" />
      </a-col>
      <a-col :span="2">
        <a-button type="primary" @click="getData(1, pagination.pageSize)"
          >搜索</a-button
        >
      </a-col>
      <a-col :span="4">
        <a-select default-value="0" style="width: 150px" @change="selectType">
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option value="1">
            员工
          </a-select-option>
          <a-select-option value="2">
            合作方
          </a-select-option>
          <a-select-option value="3">
            客户
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <a-checkbox style="margin-top: 5px" @change="onChangeCheckBox">
          是否认证
        </a-checkbox>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="pagination"
      rowKey="id"
      @rowClick="handleRowClick"
    >
      <template slot="isAuth" slot-scope="text, record, index">
        <template v-if="text">
          是
        </template>
        <template v-else>
          否
        </template>
      </template>
      <template slot="isSales" slot-scope="text, record, index">
        <template v-if="text">
          是
        </template>
        <template v-else>
          否
          <a-popconfirm
            title="确定要设该用户为员工吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="setUserAuth(record.id, 1)"
          >
            <a-button size="small" @click.stop>设为员工</a-button>
          </a-popconfirm>
        </template>
      </template>
      <template slot="isCooper" slot-scope="text, record, index">
        <template v-if="text">
          是
        </template>
        <template v-else>
          否
          <a-popconfirm
            title="确定要设该用户为合作方吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="setUserAuth(record.id, 2)"
          >
            <a-button size="small" @click.stop>设为合作方</a-button>
          </a-popconfirm>
        </template>
      </template>
      <template slot="isClient" slot-scope="text, record, index">
        <template v-if="text">
          是
        </template>
        <template v-else>
          否
        </template>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <template v-if="record.reviewStatus === 1">
          <a-button
            size="small"
            type="primary"
            @click.stop="seeInfo(record, 0)"
          >
            审核
          </a-button>
        </template>
        <a-button size="small" @click.stop="seeInfo(record, 1)">查看</a-button>
        <a-button size="small" @click.stop="updateInfo(record)">修改</a-button>
        <!--        <a-button size="small">修改</a-button>-->
      </template>
    </a-table>

    <a-modal
      :visible="seeInfoModel"
      @cancel="seeInfoModel = false"
      :footer="null"
      :width="800"
    >
      <a-descriptions title="用户信息" :column="1">
        <a-descriptions-item label="用户名">
          {{ seeInfoData.userName }}
        </a-descriptions-item>
        <a-descriptions-item label="手机号">
          {{ seeInfoData.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ seeInfoData.idCard }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证正面">
          <a
            href="javascript:void(0)"
            @click="
              previewVisible = true;
              previewImage = seeInfoData.idCardPositive;
            "
          >
            <img
              alt="example"
              style="width: 40%"
              :src="seeInfoData.idCardPositive"
            />
          </a>
        </a-descriptions-item>
        <a-descriptions-item label="身份证反面">
          <a
            href="javascript:void(0)"
            @click="
              previewVisible = true;
              previewImage = seeInfoData.idCardNegative;
            "
          >
            <img
              alt="example"
              style="width: 40%"
              :src="seeInfoData.idCardNegative"
            />
          </a>
        </a-descriptions-item>
      </a-descriptions>
      <template v-if="seeInfoType === 0">
        <div style="padding-bottom: 20px;text-align: right">
          <a-popconfirm
            title="确定要审核通过吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="review(2)"
          >
            <a-button type="primary">审核通过</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="确定要审核不通过吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="review(3)"
          >
            <a-button type="danger">审核不通过</a-button>
          </a-popconfirm>
        </div>
      </template>
    </a-modal>

    <a-modal
      :visible="previewVisible"
      @cancel="previewVisible = false"
      :footer="null"
      :width="800"
      :z-index="9999"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal
      :width="800"
      :visible="addOrUpdateModel"
      @cancel="addOrUpdateModel = false"
      okText="修改"
      @ok="sublimeOk"
    >
      <a-form-model
        :model="form"
        ref="form"
        :labelAlign="'left'"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="用户姓名" ref="userName" prop="userName">
          <a-input v-model="form.userName" />
        </a-form-model-item>
        <a-form-model-item label="用户手机号" ref="phone" prop="phone">
          <a-input v-model="form.phone" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "系统ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "微信openId",
    dataIndex: "openId",
    scopedSlots: { customRender: "openId" },
  },
  {
    title: "昵称",
    dataIndex: "nickName",
    scopedSlots: { customRender: "nickName" },
  },
  {
    title: "手机号",
    dataIndex: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "姓名",
    dataIndex: "userName",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "身份证号",
    dataIndex: "idCard",
    scopedSlots: { customRender: "idCard" },
  },
  {
    title: "是否认证",
    dataIndex: "isAuth",
    scopedSlots: { customRender: "isAuth" },
  },
  {
    title: "员工",
    dataIndex: "isSales",
    scopedSlots: { customRender: "isSales" },
  },
  {
    title: "合作方",
    dataIndex: "isCooper",
    scopedSlots: { customRender: "isCooper" },
  },
  {
    title: "客户",
    dataIndex: "isClient",
    scopedSlots: { customRender: "isClient" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: "18%",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  name: "user",
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize);
        },
      },
      columns,
      addOrUpdateModel: false,
      data: [],
      previewVisible: false,
      previewImage: null,
      seeInfoType: 1,
      seeInfoData: {},
      seeInfoModel: false,
      form: {
        userName: null,
      },
      search: {
        name: null,
        phone: null,
        idCard: null,
        isAuth: null,
        type: null,
      },
    };
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize);
  },
  methods: {
    async getData(pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/info/user/get/" + pageNum + "/" + pageSize,
        {
          params: {
            name: this.search.name,
            phone: this.search.phone,
            idCard: this.search.idCard,
            isAuth: this.search.isAuth,
            type: this.search.type,
          },
        }
      );
      this.data = data.data.list;
      this.pagination.current = data.data.pageNum;
      this.pagination.pageSize = data.data.pageSize;
      this.pagination.total = data.data.total;
    },
    onChangeCheckBox(e) {
      if (e.target.checked) {
        this.search.isAuth = true;
      } else {
        this.search.isAuth = null;
      }
      this.getData(1, this.pagination.pageSize);
    },
    // 选择
    selectType(value) {
      if (value === "0") {
        this.search.type = null;
      } else {
        this.search.type = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    // 查看信息
    seeInfo(record, seeInfoType) {
      this.seeInfoModel = true;
      this.seeInfoData = record;
      this.seeInfoType = seeInfoType;
    },
    // 修改信息
    updateInfo(record) {
      this.addOrUpdateModel = true;
      this.form = record;
    },
    async sublimeOk() {
      const { data } = await this.$axios.post(
        "/api/v1/admin/info/user/update",
        this.form
      );
      if (data.code !== 200) return;
      this.getData(this.pagination.current, this.pagination.pageSize);
      this.$message.success(data.message);
      this.addOrUpdateModel = false;
    },
    // 设置员工或合作方
    async setUserAuth(id, type) {
      this.seeInfoModel = false;
      const { data } = await this.$axios.post(
        "/api/v1/admin/info/user/set/sales/cooper",
        {
          id: id,
          type: type,
        }
      );
      if (data.code !== 200) return;
      this.getData(this.pagination.current, this.pagination.pageSize);
      this.$message.success("设置成功！");
    },
    // 审核
    async review(type) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/info/user/update/review/status",
        {
          id: this.seeInfoData.id,
          reviewStatus: type,
        }
      );
      if (data.code !== 200) return;
      this.seeInfoModel = false;
      this.getData(this.pagination.current, this.pagination.pageSize);
      this.$message.success("操作成功！");
    },
    // 删除
    async confirmDelete(id) {},
    // 点击整行显示详情
    handleRowClick(record) {
      this.seeInfo(record, 1);
    },
  },
};
</script>

<style scoped></style>
