<template>
	<a-config-provider :locale="zh_CN">
		<div id="app">
			<router-view v-if="$route.path === '/login'"></router-view>
			<a-layout id="components-layout-demo-fixed-sider" v-else>
				<a-layout-sider
					v-model="collapsed"
					:style="{
						overflow: 'auto',
						height: '100vh',
						position: 'fixed',
						left: 0
					}"
				>
					<div class="logo" style="margin-top: 16px"></div>
					<a-menu
						theme="dark"
						mode="inline"
						:default-selected-keys="[$route.path]"
						@click="menuChoose"
					>
						<template v-for="i in routerList">
							<a-menu-item :key="i.path" v-if="i.child.length === 0">
								<a-icon type="database" />
								{{ i.authorityName }}
							</a-menu-item>
							<a-sub-menu :key="i.path" v-else>
								<span slot="title"
									><a-icon type="database" />{{ i.authorityName }}</span
								>
								<template v-for="j in i.child">
									<a-menu-item :key="j.path">
										{{ j.authorityName }}
									</a-menu-item>
								</template>
							</a-sub-menu>
						</template>
					</a-menu>
				</a-layout-sider>
				<a-layout
					:style="{ height: '100vh', marginLeft: collapsed ? '80px' : '200px' }"
				>
					<a-layout-header
						:style="{
							background: '#fff'
						}"
					>
						<div
							style="display: flex; justify-content: space-between; align-items: center;"
						>
							<a-icon
								:style="{ fontSize: '18px' }"
								class="trigger"
								:type="collapsed ? 'menu-unfold' : 'menu-fold'"
								@click="() => (collapsed = !collapsed)"
							/>
							<div>
								<a-popover
									v-model="msgPopoverShow"
									style="margin-right: 60px;"
									title="消息"
									trigger="click"
								>
									<a-badge :dot="hasNoReadMsg">
										<a-icon style="font-size: 20px;" type="bell" />
									</a-badge>
									<div style="width: 300px;" slot="content">
										<div
											class="msg-line"
											v-for="item in msgList"
											:key="item.id"
											@click="msgLineClick(item)"
										>
											<div class="msg-title-wrap">
												<div class="msg-title">{{ item.title || '-' }}</div>
												<div
													class="msg-read"
													v-if="item.readStatus === 0"
												></div>
											</div>
											<div class="msg-date">{{ item.createTime || '-' }}</div>
										</div>
										<div class="msg-pagination">
											<a-pagination
												v-model="msgCurrent"
												:total="msgTotal"
												:size="msgSize"
												size="small"
												show-less-items
												@change="msgPageChange"
											/>
										</div>
										<div style="margin-top: 10px;">
											<a-button size="small" :loading="readLoading" @click="allReadClick">全部已读</a-button>
										</div>
									</div>
								</a-popover>
								<a-dropdown>
									<a class="ant-dropdown-link">
										管理员
										<a-icon type="down" />
									</a>
									<a-menu slot="overlay" @click="choose">
										<a-menu-item key="update">
											<a href="javascript:;">修改密码</a>
										</a-menu-item>
										<a-menu-item key="logout">
											<a href="javascript:;">退出登录</a>
										</a-menu-item>
									</a-menu>
								</a-dropdown>
							</div>
						</div>
					</a-layout-header>
					<a-layout-content
						:style="{
							margin: collapsed ? '24px 16px 0 100px' : '24px 16px 0',
							overflow: 'inherit'
						}"
					>
						<div
							:style="{
								minHeight: '100%',
								padding: '24px',
								background: '#fff'
							}"
						>
							<router-view></router-view>
						</div>
					</a-layout-content>
				</a-layout>
			</a-layout>
			<a-back-top />
			<a-modal v-model="updatePassModel" title="修改密码" :maskClosable="false">
				<template slot="footer">
					<a-button type="primary" @click="updatePassword" style="width: 100%;">
						确定
					</a-button>
				</template>
				<a-form-model
					ref="form"
					:model="form"
					:rules="rule"
					:labelCol="{ span: 4 }"
					:wrapperCol="{ span: 18 }"
				>
					<a-form-model-item
						ref="oldPassword"
						label="旧密码"
						prop="oldPassword"
					>
						<a-input v-model="form.oldPassword" />
					</a-form-model-item>
					<a-form-model-item ref="password" label="新密码" prop="password">
						<a-input-password v-model="form.password" />
					</a-form-model-item>
					<a-form-model-item ref="password2" label="再次输入" prop="password2">
						<a-input-password v-model="form.password2" />
					</a-form-model-item>
				</a-form-model>
			</a-modal>

			<a-modal
				v-model="msgModelVisible"
				:title="msgTitle"
				:zIndex="3000"
				@ok="msgModelVisible = false"
			>
				<div>
					<div style="font-size: 16px;">{{ msgContent }}</div>
					<div style="font-size: 12px; color: gray;">{{ msgDate }}</div>
				</div>
			</a-modal>
		</div>
	</a-config-provider>
</template>

<script>
	import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
	import moment from 'moment';
	import 'moment/locale/zh-cn';

	moment.locale('zh-cn');
	export default {
		data() {
			return {
				zh_CN,
				selectedKeys: ['1'],
				collapsed: false,
				routerList: [],
				updatePassModel: false,
				form: {
					oldPassword: '',
					password: '',
					password2: ''
				},
				rule: {
					oldPassword: [
						{ required: true, message: '旧密码不能为空！', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '密码不能为空', trigger: 'blur' },
						{ min: 6, message: '密码不能小于6位', trigger: 'blur' }
					],
					password2: [
						{ required: true, message: '密码不能为空', trigger: 'blur' },
						{ min: 6, message: '密码不能小于6位', trigger: 'blur' }
					]
				},
				msgPopoverShow: false,
				msgCurrent: 1,
				msgTotal: 0,
				msgSize: 10,
				msgList: [],
				msgModelVisible: false,
				msgTitle: '',
				msgContent: '',
				msgDate: '',
				msgTimer: null,
				hasNoReadMsg: false,
				readLoading: false
			};
		},
		mounted() {
			this.getAuth();
		},
		beforeDestroyed() {
			clearInterval(this.msgTimer);
		},
		methods: {
			async getAuth() {
				const { data } = await this.$axios.get(
					'/api/v1/admin/info/admin/get/auth'
				);
				this.routerList = data.data;
				this.handleMsg();
				this.handleNoReadMsg();
				this.msgTimer = setInterval(() => {
					this.handleNoReadMsg();
				}, 40000);
			},
			// 修改密码
			async updatePassword() {
				if (this.form.password !== this.form.password2) {
					this.$message.error('两次密码输入不一致！');
					return false;
				}
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						const { data } = await this.$axios.put(
							'/api/v1/admin/info/admin/update/password',
							{
								oldPassword: this.form.oldPassword,
								password: this.form.password,
								type: true
							}
						);
						if (data.code === 200) {
							this.$message.success('修改成功！');
							this.updatePassModel = false;
						}
					}
				});
			},
			choose({ key }) {
				if (key === 'logout') {
					localStorage.removeItem(this.$store.state.tokenName);
					this.$router.push('/login');
				} else {
					this.updatePassModel = true;
				}
			},
			menuChoose({ key }) {
				if (this.$route.path !== key) {
					this.$router.push(key);
				}
			},
			//获取消息
			async handleMsg() {
				const { data } = await this.$axios.get('/api/v1/admin/message/page', {
					params: {
						pageNum: this.msgCurrent,
						pageSize: this.msgSize
					}
				});
				this.msgList = data.data.list;
				this.msgTotal = data.data.total;
			},
			//获取未读消息
			async handleNoReadMsg() {
				const res = await this.$axios.get(
					'/api/v1/admin/message/getIsHaveUnreadMessage'
				);
				console.log(res);
				if (res.data.data) {
					this.hasNoReadMsg = true;
					this.handleMsg();
				}
			},
			msgPageChange(val) {
				this.msgCurrent = val;
				this.handleMsg();
			},
			async msgLineClick(item) {
				console.log(item);
				this.msgTitle = item.title || '-';
				this.msgContent = item.msgContent || '-';
				this.msgDate = item.createTime || '-';
				this.msgModelVisible = true;
				await this.$axios.get('/api/v1/admin/message/read-message', {
					params: {
						ids: item.id
					}
				});
				this.handleMsg();
			},
			async allReadClick() {
				try {
					this.readLoading = true
					await this.$axios.get('/api/v1/admin/message/readAllMessage');
					this.handleMsg()
				} catch (error) {
					console.log(error);
				} finally {
					this.readLoading = false
				}
			}
		}
	};
</script>

<style lang="less">
	.ant-menu-submenu-title,
	.ant-menu-item-selected,
	.ant-menu-item {
		padding-left: 10px !important;
	}

	.ant-menu-submenu .ant-menu-item {
		padding-left: 50px !important;
	}

	.ant-modal-header,
	.ant-modal-footer {
		border: none !important;
	}

	.ant-modal-body {
		padding-bottom: 0 !important;
	}

	.ant-btn {
		margin: 0 2px 2px 2px !important;
	}

	::-webkit-scrollbar {
		width: 6px;
	}

	/* 滚动槽 */
	::-webkit-scrollbar-track {
		border-radius: 10px;
	}

	/* 滚动条滑块 */
	::-webkit-scrollbar-thumb {
		border-radius: inherit;
		background-color: #dedcdc;
		-webkit-transition: 0.3s background-color;
		transition: 0.3s background-color;
	}

	.msg-line {
		border-bottom: 1px solid rgb(230, 230, 230);
		margin-bottom: 10px;
		&:hover {
			cursor: pointer;
		}
		.msg-title-wrap {
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.msg-title {
				width: 250px;
				flex-shrink: 1;
			}
			.msg-read {
				display: block;
				height: 6px;
				width: 6px;
				background-color: red;
				border-radius: 50%;
				margin-right: 2px;
			}
		}
		.msg-date {
			font-size: 12px;
			color: gray;
		}
	}
	.msg-pagination {
		display: flex;
		justify-content: flex-end;
		margin-top: 10px;
	}
</style>
