<template>
    <div id="advisory">
        <a-row style="margin-bottom: 20px" :gutter="16">
            <a-col :span="3">
                <a-button type="primary" @click="addOrUpdate('添加')">添加咨询人</a-button>
            </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" bordered :pagination="pagination" rowKey="id">
            <template slot="advisoryQr" slot-scope="text,record,index">
                <a href="javascript:void(0)" @click="previewVisible = true;previewImage = text">
                    <img alt="example" style="width: 20%" :src="text"/>
                </a>
            </template>
            <template slot="operation" slot-scope="text,record,index">
                <a-button size="small" type="primary" @click="addOrUpdate('修改',record)">修改</a-button>
                <a-popconfirm
                        title="确定删除该咨询人吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="deleteConfirm(record.id)"
                >
                    <a-button size="small" type="danger">删除</a-button>
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal :width="800" :visible="addOrUpdateModel" @cancel="addOrUpdateModel = false" :okText="addOrUpdateOption"
                 @ok="sublimeOk">
            <a-form-model :model="form" ref="form" :rules="formRole" :labelAlign="'left'" :label-col="{span: 4}"
                          :wrapper-col="{span:18}">
                <a-form-model-item label="咨询人二维码">
                    <a-upload
                            action="/api/v1/common/upload/file?type=15&uploadTypeId=15"
                            :headers="{'Authorization':'Bearer '+$store.state.tokenValue}"
                            list-type="picture-card"
                            :file-list="fileList"
                            @preview="handlePreview"
                            @change="handleChange"
                    >
                        <div v-if="fileList.length < 1">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">
                                尺寸
                            </div>
                        </div>
                    </a-upload>
                </a-form-model-item>
                <a-form-model-item label="咨询人姓名" ref="name" prop="name">
                    <a-input v-model="form.name"/>

                </a-form-model-item>
                <a-form-model-item label="咨询人手机号" ref="phone" prop="phone">
                    <a-input v-model="form.phone"/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <a-modal :visible="previewVisible" @cancel="previewVisible=false" :footer="null" :width="800" :z-index="9999">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
    </div>
</template>

<script>
    const columns = [
        {
            title: '咨询人二维码',
            dataIndex: 'advisoryQr',
            width: '25%',
            scopedSlots: {customRender: 'advisoryQr'},
        },
        {
            title: '咨询人姓名',
            dataIndex: 'name',
            scopedSlots: {customRender: 'name'},
        },
        {
            title: '咨询人手机号',
            dataIndex: 'phone',
            scopedSlots: {customRender: 'phone'},
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '15%',
            scopedSlots: {customRender: 'operation'}
        }
    ]
    export default {
        name: "advisory",
        data() {
            return {
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 100,
                    onChange: (page, pageSize) => {
                        this.getData(page, pageSize)
                    }
                },
                userName: null,
                phone: null,
                idCard: null,
                columns,
                data: [],
                form: {
                    phone: null,
                    name: null,

                },
                addOrUpdateModel: false,
                addOrUpdateOption: '添加',
                formRole: {
                    name: [
                        {required: true, message: '咨询人姓名不能为空', trigger: 'blur'},
                    ],
                    phone: [
                        {required: true, message: '手机号不能为空', trigger: 'blur'},
                    ],
                },
                previewVisible: false,
                previewImage: null,
                fileList: [],
            }
        },
        mounted() {
            this.getData(this.pagination.current, this.pagination.pageSize)
        },
        methods: {
            async getData(pageNum, pageSize) {
                const {data} = await this.$axios.get('/api/v1/admin/info/advisory/get/' + pageNum + '/' + pageSize,)
                this.data = data.data.list
                this.pagination.current = data.data.pageNum
                this.pagination.pageSize = data.data.pageSize
                this.pagination.total = data.data.total
            },
            // 添加或修改
            addOrUpdate(op, record) {
                this.addOrUpdateModel = true

                this.form = {
                    clientNature: null,
                    phone: null,
                }
                this.fileList = []
                this.addOrUpdateOption = op;
                if (op === '修改') {
                    // xxx
                    this.form = record
                    this.fileList = [
                        {
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            response: {
                                data: record.advisoryQr
                            },
                            url: record.advisoryQr,
                        },
                    ]
                }
            },
            sublimeOk() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        this.form.advisoryQr = this.fileList[0].response.data
                        if (this.addOrUpdateOption === '添加') {
                            var {data} = await this.$axios.post('/api/v1/admin/info/advisory/add', this.form)
                        } else {
                            var {data} = await this.$axios.post('/api/v1/admin/info/advisory/update', this.form)
                        }
                        this.$message.success(data.message)
                        this.addOrUpdateModel = false
                        this.getData(1, this.pagination.pageSize)
                    }
                })
            },
            // 暂停或启用员工
            deleteConfirm(id) {
                this.$message.success("删除成功！")
                this.getData(1, this.pagination.pageSize)
            },
            async handlePreview(file) {
                this.previewImage = file.response.data
                this.previewVisible = true;
            },
            handleChange({fileList}) {
                this.fileList = fileList;
            },
        }
    }
</script>

<style scoped>

</style>
