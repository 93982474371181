import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import login from "../views/login";
import base from "@/views/web/base";
import ad from "@/views/web/ad";
import notice from "@/views/web/notice";
import news from "@/views/web/news"
import product from "@/views/web/product";
import patent from "@/views/web/patent";
import admin from "@/views/user/admin";
import user from "@/views/user/user";
import sales from "@/views/user/sales";
import cooper from "@/views/user/cooper";
import client from "@/views/user/client";
import advisory from "@/views/user/advisory";
import business from "@/views/patent/business";
import proxy from "@/views/patent/proxy";
import operation from "@/views/patent/operation";
import under from "@/views/patent/under";
import transaction from "@/views/patent/transaction";
import trademark from "@/views/other/trademark";
import copyright from "@/views/other/copyright"
import sellPatent from "@/views/report/sellPatent";
import buyPatent from "@/views/report/buyPatent";
import businessPatent from "@/views/report/businessPatent";
import patentPatent from "@/views/patent/patent";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: index,
        icon: 'database'
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        icon: 'database'
    },
    {
        path: '/web/base',
        name: 'base',
        component: base,
        icon: 'database'
    },
    {
        path: '/web/ad',
        name: 'ad',
        component: ad,
        icon: 'database'
    },
    {
        path: '/web/notice',
        name: 'notice',
        component: notice,
        icon: 'database'
    },
    {
        path: '/web/news',
        name: 'news',
        component: news,
        icon: 'database'
    },
    {
        path: '/web/product',
        name: 'product',
        component: product,
        icon: 'database'
    },
    {
        path: '/web/patent',
        name: 'patent',
        component: patent,
        icon: 'database'
    },
    {
        path: '/user/admin',
        name: 'admin',
        component: admin,
        icon: 'database'
    },
    {
        path: '/user/user',
        name: 'user',
        component: user,
        icon: 'database'
    },
    {
        path: '/user/sales',
        name: 'sales',
        component: sales,
        icon: 'database'
    },
    {
        path: '/user/cooper',
        name: 'cooper',
        component: cooper,
        icon: 'database'
    },
    {
        path: '/user/client',
        name: 'client',
        component: client,
        icon: 'database'
    },
    {
        path: '/user/advisory',
        name: 'advisory',
        component: advisory,
        icon: 'database'
    },
    {
        path: '/patent/business',
        name: 'business',
        component: business,
        icon: 'database'
    },
    {
        path: '/patent/proxy',
        name: 'proxy',
        component: proxy,
        icon: 'database'
    },
    {
        path: '/patent/operation',
        name: 'operation',
        component: operation,
        icon: 'database'
    },
    {
        path: '/patent/under',
        name: 'under',
        component: under,
        icon: 'database'
    },
    {
        path: '/patent/transaction',
        name: 'transaction',
        component: transaction,
        icon: 'database'
    },
    {
        path: '/trademark',
        name: 'trademark',
        component: trademark,
        icon: 'database'
    },
    {
        path: '/copyright',
        name: 'copyright',
        component: copyright,
        icon: 'database'
    },
    {
        path: '/report/sell/patent',
        name: 'sellPatent',
        component: sellPatent,
        icon: 'database'
    },
    {
        path: '/report/buy/patent',
        name: 'buyPatent',
        component: buyPatent,
        icon: 'database'
    },
    {
        path: '/report/business/patent',
        name: 'businessPatent',
        component: businessPatent,
        icon: 'database'
    },
    {
        path: '/patent/patent',
        name: 'patentPatent',
        component: patentPatent,
        icon: 'database'
    },
]

const router = new VueRouter({
    routes
})

export default router
