<template>
    <div id="admin">
        <a-row style="margin-bottom: 20px" :gutter="16">
            <a-col :span="2">
                <a-button type="primary" @click="addOrUpdate('添加')">添加管理员</a-button>
            </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" bordered :pagination="pagination" rowKey="id">
            <template slot="operation" slot-scope="text,record,index">
                <a-button size="small" type="primary" @click="addOrUpdate('修改权限',record)">修改权限</a-button>
                <a-button size="small" type="primary" @click="addOrUpdate('修改密码',record)">修改密码</a-button>
                <a-popconfirm
                        title="确定要删除吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="confirmDelete(record.id)"
                >
                    <a-button size="small" type="danger">删除</a-button>
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal :width="800" :visible="addOrUpdateModel" @cancel="addOrUpdateModel = false" :okText="addOrUpdateOption"
                 @ok="sublimeOk" :destroyOnClose="true">
            <a-form-model :model="form" ref="form" :rules="formRole" :labelAlign="'left'" :label-col="{span: 3}"
                          :wrapper-col="{span:20}">
                <a-form-model-item label="账号名" ref="userName" prop="userName" v-if="this.addOrUpdateOption === '添加'">
                    <a-input v-model="form.userName"/>
                </a-form-model-item>
                <a-form-model-item label="角色名称" ref="roleName" prop="roleName" v-if="this.addOrUpdateOption === '添加'">
                    <a-input v-model="form.roleName"/>
                </a-form-model-item>
                <a-form-model-item label="密码" ref="password" prop="password"
                                   v-if="this.addOrUpdateOption === '修改密码' || this.addOrUpdateOption === '添加'">
                    <a-input-password v-model="form.password"/>
                </a-form-model-item>
                <a-form-model-item label="再次输入密码" ref="againPassword" prop="againPassword"
                                   v-if="this.addOrUpdateOption === '修改密码'">
                    <a-input-password v-model="form.againPassword"/>
                </a-form-model-item>
                <a-form-model-item label="权限选择" ref="auth" prop="auth"
                                   v-if="this.addOrUpdateOption === '修改权限' || this.addOrUpdateOption === '添加'">
                    <a-checkbox-group v-model="form.auth" :default-value="form.chooseAuth">
                        <template v-for="i in allAuth">
                            <template v-if="i.child.length !== 0" style="margin: 0">
                                <template v-for="j in i.child">
                                    <a-checkbox :value="j.id" :key="j.id" name="auth">
                                        {{j.authorityName}}
                                    </a-checkbox>
                                </template>
                            </template>
                            <a-checkbox v-if="i.child.length === 0" :value="i.id" :key="i.id" name="auth">
                                {{i.authorityName}}
                            </a-checkbox>
                        </template>
                    </a-checkbox-group>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    const columns = [
        {
            title: '账号名',
            dataIndex: 'userName',
            scopedSlots: {customRender: 'userName'},
        },
        {
            title: '角色名称',
            dataIndex: 'roleName',
            scopedSlots: {customRender: 'roleName'},
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '25%',
            scopedSlots: {customRender: 'operation'}
        }
    ]
    export default {
        name: "admin",
        data() {
            return {
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 100,
                    onChange: (page, pageSize) => {
                        this.getData(page, pageSize)
                    }
                },
                columns,
                data: [],
                allAuth: [],
                addOrUpdateModel: false,
                addOrUpdateOption: '添加',
                form: {
                    userName: null,
                    roleName: null,
                    password: null,
                    againPassword: null,
                    auth: [],
                    chooseAuth: [],
                },
                formRole: {
                    userName: [
                        {required: true, message: '账号不能为空', trigger: 'blur'},
                    ],
                    roleName: [
                        {required: true, message: '角色不能为空', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '密码不能为空', trigger: 'blur'},
                    ],
                    againPassword: [
                        {required: true, message: '再次输入密码不能为空', trigger: 'blur'},
                    ],
                },
            }
        },
        mounted() {
            this.getData(this.pagination.current, this.pagination.pageSize)
            this.getAllAuth()
        },
        methods: {
            async getData(pageNum, pageSize) {
                const {data} = await this.$axios.get('/api/v1/admin/info/admin/get/' + pageNum + '/' + pageSize)
                this.data = data.data.list
                this.pagination.current = data.data.pageNum
                this.pagination.pageSize = data.data.pageSize
                this.pagination.total = data.data.total
            },
            async getAllAuth() {
                const {data} = await this.$axios.get('/api/v1/admin/info/admin/get/all/auth')
                this.allAuth = data.data
            },
            // 添加或修改
            async addOrUpdate(op, record) {
                this.fileList = []
                this.form = {
                    userName: null,
                    roleName: null,
                    linkPhone: null,
                    clientType: null,
                    auth: []
                }
                this.addOrUpdateOption = op;
                if (op.indexOf('修改') !== -1) {
                    delete record.auth
                    this.form = record
                    this.form.chooseAuth = []
                }
                if (op === '修改权限') {
                    const {data} = await this.$axios.get('/api/v1/admin/info/admin/auth/' + record.id)
                    for (let i = 0; i < data.data.length; i++) {
                        let datum = data.data[i];
                        let child = datum.child;
                        if (child.length > 0) {
                            for (let j = 0; j < child.length; j++) {
                                this.form.chooseAuth.push(child[j].id)
                            }
                        } else {
                            this.form.chooseAuth.push(datum.id)
                        }
                    }
                }
                this.addOrUpdateModel = true
            },
            sublimeOk() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        if (this.addOrUpdateOption === '添加') {
                            var {data} = await this.$axios.post('/api/v1/admin/info/admin/add', this.form)
                        } else if (this.addOrUpdateOption === '修改权限') {
                            if (this.form.auth === undefined) {
                                this.form.auth = this.form.chooseAuth
                            }
                            var {data} = await this.$axios.put('/api/v1/admin/info/admin/update/auth', this.form)
                        }else if (this.addOrUpdateOption === '修改密码') {
                            this.form.type = false
                            var {data} = await this.$axios.put('/api/v1/admin/info/admin/update/password', this.form)
                        }
                        this.$message.success(data.message)
                        this.addOrUpdateModel = false
                        this.getData(1, this.pagination.pageSize)
                    }
                })
            },
            // 删除
            async confirmDelete(id) {
                const {data} = await this.$axios.delete('/api/v1/admin/info/admin/delete/'+id)
                this.$message.success(data.message)
                this.getData(1, this.pagination.pageSize)
            },
        }
    }
</script>

<style scoped>
    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0 !important;
    }
</style>
