export default {
    // 获取时间
    timeFuc: function (time) {
        let data = time + '';
        return data.substring(0, 4) + '-' + data.substring(4, 6) + '-' + data.substring(6, 8) + ' ' + data.substring(8, 10) + ':' + data.substring(10, 12)
    },

    formatStrTime: function (time) {
        return time.replaceAll(" ", "").replaceAll("-", "").replaceAll(":", "")
    },
    getBase64: function (img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    },
    getModelBase64: function (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    },
    exportExcel: function (that, url, fileName) {
        var f = fileName
        that.$axios({
            url: url,
            method: 'get',
            responseType: 'blob'
        }).then(res => {
            let blob = new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"});
            const fileName = f + '.xlsx';//下载文件名称
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
        })
    },
    removeFile(that, file) {
        that.$axios.get('/api/v1/common/delete/file?url=' + file.response.data)
    },
    //  删除文件
    async deleteFile(that,url) {
        const {data} = await that.$axios.get('/api/v1/common/delete/file?url=' + url);
        that.$message.success(data.data)
    },
}
