<template>
	<div id="patent-patent">
		<a-form layout="inline">
			<a-form-item label="专利类型">
				<a-select
					style="width: 150px"
					default-value=""
					v-model="searchData.patentType"
				>
					<a-select-option value="">
						全部
					</a-select-option>
					<a-select-option :value="1">
						发明
					</a-select-option>
					<a-select-option :value="2">
						实用
					</a-select-option>
					<a-select-option :value="3">
						外观
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item label="专利状态">
				<a-select
					style="width: 150px"
					default-value=""
					v-model="searchData.patentState"
				>
					<a-select-option value="">
						全部
					</a-select-option>
					<a-select-option :value="1">
						未下证
					</a-select-option>
					<a-select-option :value="2">
						下证
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item label="专利领域">
				<a-input
					allowClear
					v-model="searchData.patentField"
					placeholder="专利领域"
				/>
			</a-form-item>
			<a-form-item label="专利号">
				<a-input
					allowClear
					v-model="searchData.patentSn"
					placeholder="专利号"
				/>
			</a-form-item>
			<a-form-item label="专利名称">
				<a-input
					allowClear
					v-model="searchData.patentName"
					placeholder="专利名称"
				/>
			</a-form-item>
			<a-form-item label="案件状态">
				<a-input
					allowClear
					v-model="searchData.patentStatus"
					placeholder="案件状态"
				/>
			</a-form-item>
			<a-form-item label="截止日期">
				<a-range-picker
					allowClear
					show-time
					format="YYYY-MM-DD"
					v-model="searchData.stopTime"
				/>
			</a-form-item>
			<a-form-item label="预定人">
				<a-input allowClear v-model="searchData.booker" placeholder="预定人" />
			</a-form-item>
			<a-form-item label="预定时间">
				<a-range-picker
					allowClear
					show-time
					format="YYYY-MM-DD"
					v-model="searchData.bookerTime"
				/>
			</a-form-item>
			<a-form-item label="申请人">
				<a-input
					allowClear
					v-model="searchData.patentApplyUser"
					placeholder="申请人"
				/>
			</a-form-item>
			<a-form-item label="出售状态">
				<a-select
					style="width: 150px"
					default-value=""
					v-model="searchData.saleState"
				>
					<a-select-option value="">
						全部
					</a-select-option>
					<a-select-option :value="1">
						预留
					</a-select-option>
					<a-select-option :value="2">
						在售中
					</a-select-option>
					<a-select-option :value="3">
						已出售
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item>
				<a-button type="primary" @click="searchClick">搜索</a-button>
			</a-form-item>
		</a-form>
		<div style="margin: 20px 0;">
			<a-button type="primary" @click="addClick">新建专利</a-button>
		</div>
		<div>
			<a-table
				:columns="columns"
				:data-source="tableData"
				bordered
				:pagination="pageData"
				rowKey="id"
				@rowClick="handleRowClick"
			>
				<template slot="patentType" slot-scope="text, record">
					{{ handlePatentType(text) }}
				</template>
				<template slot="patentState" slot-scope="text, record">
					{{ text === 1 ? '未下证' : text === 2 ? '下证' : '-' }}
				</template>
				<template slot="saleState" slot-scope="text, record">
					<div @click.stop>
						<!-- {{ handleSalesStatus(text) }} -->
						<a-select
							style="width: 150px"
							v-model="record.saleState"
							@change="(val) => tableSaleStateChange(val, record)"
						>
							<a-select-option :value="1">
								预留
							</a-select-option>
							<a-select-option :value="2">
								在售中
							</a-select-option>
							<a-select-option :value="3">
								已出售
							</a-select-option>
						</a-select>
					</div>
				</template>
				<template slot="operation" slot-scope="text, record">
					<a-button size="small" type="primary" @click.stop="checkClick(record)"
						>查看</a-button
					>
					<a-button size="small" @click.stop="editClick(record)">修改</a-button>
					<a-button size="small" type="danger" @click.stop="delClick(record)"
						>删除</a-button
					>
				</template>
			</a-table>
		</div>

		<a-modal
			title="专利"
			:visible="modalVisible"
			:confirm-loading="confirmLoading"
			@ok="handleSubmit"
			@cancel="modalVisible = false"
		>
			<a-form-model
				ref="formData"
				:rules="formRule"
				:model="formData"
				:label-col="{ span: 4 }"
				:wrapper-col="{ span: 18 }"
			>
				<a-form-model-item label="合作方">
					<a-select
						show-search
						default-value=""
						v-model="formData.cooperId"
						:disabled="isCheck"
						@change="cooperChange"
					>
						<a-select-option
							:value="item.id"
							v-for="item in cooperData"
							:key="item.id"
						>
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="专利类型">
					<a-select
						style="width: 150px"
						default-value=""
						v-model="formData.patentType"
						:disabled="isCheck"
					>
						<a-select-option :value="1">
							发明
						</a-select-option>
						<a-select-option :value="2">
							实用
						</a-select-option>
						<a-select-option :value="3">
							外观
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="专利状态">
					<a-select
						style="width: 150px"
						default-value=""
						v-model="formData.patentState"
						:disabled="isCheck"
					>
						<a-select-option :value="1">
							未下证
						</a-select-option>
						<a-select-option :value="2">
							下证
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="专利领域">
					<a-input v-model="formData.patentField" :disabled="isCheck" />
				</a-form-model-item>
				<a-form-model-item label="专利号">
					<a-input v-model="formData.patentSn" :disabled="isCheck" />
				</a-form-model-item>
				<a-form-model-item label="专利名称" prop="patentName">
					<a-input v-model="formData.patentName" :disabled="isCheck" />
				</a-form-model-item>
				<a-form-model-item label="案件状态">
					<a-input v-model="formData.patentStatus" :disabled="isCheck" />
				</a-form-model-item>
				<a-form-model-item label="截止日期">
					<a-date-picker
						v-model="formData.stopTime"
						show-time
						format="YYYY-MM-DD"
						:disabled="isCheck"
					/>
				</a-form-model-item>
				<a-form-model-item label="预定人">
					<a-input v-model="formData.booker" :disabled="isCheck" />
				</a-form-model-item>
				<a-form-model-item label="预定时间">
					<a-date-picker
						v-model="formData.bookerTime"
						show-time
						format="YYYY-MM-DD"
						:disabled="isCheck"
					/>
				</a-form-model-item>
				<a-form-model-item label="申请人">
					<a-input v-model="formData.patentApplyUser" :disabled="isCheck" />
				</a-form-model-item>
				<a-form-model-item label="出售状态">
					<a-select
						style="width: 150px"
						default-value=""
						v-model="formData.saleState"
						:disabled="isCheck"
					>
						<a-select-option :value="1">
							预留
						</a-select-option>
						<a-select-option :value="2">
							在售中
						</a-select-option>
						<a-select-option :value="3">
							已出售
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	const columns = [
		{
			title: '专利类型',
			dataIndex: 'patentType',
			scopedSlots: { customRender: 'patentType' }
		},
		{
			title: '专利状态',
			dataIndex: 'patentState',
			scopedSlots: { customRender: 'patentState' }
		},
		{
			title: '专利领域',
			dataIndex: 'patentField'
		},
		{
			title: '专利号',
			dataIndex: 'patentSn'
		},
		{
			title: '专利名称',
			dataIndex: 'patentName'
		},
		{
			title: '案件状态',
			dataIndex: 'patentStatus'
		},
		{
			title: '截止时间',
			dataIndex: 'stopTime'
		},
		{
			title: '专利金额',
			dataIndex: 'patentMoney'
		},
		{
			title: '专利活动',
			dataIndex: 'patentActivity'
		},
		{
			title: '预定人',
			dataIndex: 'booker'
		},
		{
			title: '预定时间',
			dataIndex: 'bookerTime'
		},
		{
			title: '来源',
			dataIndex: 'patentSource'
		},
		{
			title: '申请人',
			dataIndex: 'patentApplyUser'
		},
		{
			title: '备注',
			dataIndex: 'remark'
		},
		{
			title: '出售状态',
			dataIndex: 'saleState',
			scopedSlots: { customRender: 'saleState' }
		},
		{
			title: '操作',
			dataIndex: 'operation',
			scopedSlots: { customRender: 'operation' }
		}
	];
	export default {
		name: '',
		data() {
			return {
				searchData: {
					patentType: '',
					patentField: '',
					patentSn: '',
					patentName: '',
					patentStatus: '',
					stopTime: [],
					stopTimeStart: '',
					stopTimeEnd: '',
					booker: '',
					bookerTime: [],
					bookerTimeStart: '',
					bookerTimeEnd: '',
					patentApplyUser: '',
					patentState: '',
					saleState: ''
				},
				pageData: {
					current: 1,
					pageSize: 10,
					onChange: (current) => {
						this.pageData.current = current;
						this.getData();
					}
				},
				columns,
				tableData: [],
				isEdit: false,
				isCheck: false,
				modalVisible: false,
				confirmLoading: false,
				formData: {
					patentType: '',
					patentField: '',
					patentSn: '',
					patentName: '',
					patentStatus: '',
					stopTime: '',
					booker: '',
					bookerTime: '',
					patentApplyUser: '',
					patentState: '',
					saleState: '',
					cooperId: '',
					cooperName: '',
					cooperPhone: ''
				},
				formRule: {
					patentName: [
						{ required: true, message: '专利名称不能为空', trigger: 'blur' }
					]
				},
				selectId: '',
				cooperData: []
			};
		},
		mounted() {
			this.getDataCooper();
			this.getData();
		},
		methods: {
			//获取数据
			async getData() {
				const { data } = await this.$axios.get(
					'/api/v1/admin/sale/patent/page',
					{
						params: {
							pageNum: this.pageData.current,
							pageSize: this.pageData.pageSize,
							patentType: this.searchData.patentType,
							patentField: this.searchData.patentField,
							patentSn: this.searchData.patentSn,
							patentName: this.searchData.patentName,
							patentStatus: this.searchData.patentStatus,
							stopTimeStart: this.searchData.stopTime.length
								? moment(this.searchData.stopTime[0]).format('YYYY-MM-DD')
								: '',
							stopTimeEnd: this.searchData.stopTime.length
								? moment(this.searchData.stopTime[1]).format('YYYY-MM-DD')
								: '',
							booker: this.searchData.booker,
							bookerTimeStart: this.searchData.bookerTime.length
								? moment(this.searchData.bookerTime[0]).format('YYYY-MM-DD')
								: '',
							bookerTimeEnd: this.searchData.bookerTime.length
								? moment(this.searchData.bookerTime[1]).format('YYYY-MM-DD')
								: '',
							patentApplyUser: this.searchData.patentApplyUser,
							patentState: this.searchData.patentState,
							saleState: this.searchData.saleState
						}
					}
				);
				console.log(data);
				this.tableData = data.data.records || [];
			},
			// 获取合作方
			async getDataCooper() {
				const { data } = await this.$axios.get(
					'/api/v1/admin/operation/cooper/get/1/100000'
				);
				this.cooperData = data.data.list;
			},
			// 选择合作方
			cooperChange(val) {
				console.log(val);
				for (const item of this.cooperData) {
					if (item.id === val) {
						this.cooperName = item.cooperName;
						this.cooperPhone = item.cooperPhone;
						break;
					}
				}
			},
			//点击搜索
			searchClick() {
				this.pageData.current = 1;
				this.getData();
			},
			handleRowClick(record) {
				this.checkClick(record);
			},
			addClick() {
				this.isEdit = false;
				this.isCheck = false;
				this.selectId = '';
				this.formData.patentType = '';
				this.formData.patentField = '';
				this.formData.patentSn = '';
				this.formData.patentName = '';
				this.formData.patentStatus = '';
				this.formData.stopTime = '';
				this.formData.booker = '';
				this.formData.bookerTime = '';
				this.formData.patentApplyUser = '';
				this.formData.patentState = '';
				this.formData.saleState = '';
				this.formData.cooperId = ''
				this.formData.cooperName = ''
				this.formData.cooperPhone = ''
				if (this.$refs.formData) this.$refs.formData.clearValidate();
				this.modalVisible = true;
			},
			checkClick(record) {
				this.isEdit = false;
				this.isCheck = true;
				this.selectId = record.id;
				this.formData = { ...record };
				this.modalVisible = true;
			},
			editClick(record) {
				this.isEdit = true;
				this.isCheck = false;
				this.selectId = record.id;
				this.formData = { ...record };
				this.modalVisible = true;
			},
			delClick(record) {
				this.selectId = record.id;
				this.$confirm({
					title: '提示',
					content: '确定要删除该专利吗？',
					onOk: async () => {
						try {
							await this.$axios.post('/api/v1/admin/sale/patent/delete', {
								id: this.selectId
							});
							this.$message.success('成功！');
							this.getData();
						} catch (error) {
							console.log(error);
							this.$message.success('失败！');
						}
					}
				});
			},
			handleSubmit() {
				if (this.isCheck) {
					this.modalVisible = false;
					return;
				}
				this.$refs.formData.validate(async (valid) => {
					if (valid) {
						console.log(this.formData);
						try {
							if (!this.selectId)
								await this.$axios.post('/api/v1/admin/sale/patent/save', {
									...this.formData
								});
							else
								await this.$axios.post('/api/v1/admin/sale/patent/update', {
									...this.formData,
									id: this.selectId
								});
							this.$message.success('成功！');
							this.modalVisible = false;
							this.getData();
						} catch (error) {
							console.log(error);
							this.$message.error('请求错误！');
						}
					}
				});
			},
			handlePatentType(text) {
				switch (text) {
					case 1:
						return '发明';
					case 2:
						return '实用';
					case 3:
						return '外观';
					default:
						return '-';
				}
			},
			handleSalesStatus(text) {
				switch (text) {
					case 1:
						return '预留';
					case 2:
						return '在售中';
					case 3:
						return '已出售';
					default:
						return '-';
				}
			},
			async tableSaleStateChange(val, record) {
				console.log(val);
				try {
					await this.$axios.post('/api/v1/admin/sale/patent/update', {
						saleState: record.saleState,
						id: record.id
					});
					this.$message.success('成功！');
					this.getData();
				} catch (error) {
					console.log(error);
					this.$message.error('失败！');
				}
			}
		}
	};
</script>

<style></style>
