<template>
	<div class="h_login">
		<div class="h-login-box">
			<a-input
				ref="userNameInput"
				v-model="userName"
				placeholder="请输入用户名"
				style="margin: 0 0 20px 0"
			>
				<a-icon slot="prefix" type="user" />
				<a-tooltip slot="suffix" title="请输入用户名">
					<a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
				</a-tooltip>
			</a-input>
			<a-input
				ref="userNameInput"
				v-model="passWord"
				type="password"
				placeholder="请输入密码"
				style="margin-bottom: 20px"
			>
				<a-icon slot="prefix" type="login" />
				<a-tooltip slot="suffix" title="请输入密码">
					<a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
				</a-tooltip>
			</a-input>
			<div>
				<a-input
					ref="userNameInput"
					v-model="code"
					placeholder="请输入验证码"
					style="width: 62%;margin-right: 10px"
				/>
				<img
					:src="'/api/v1/admin/outer/loginCode?' + imgUrl"
					@click="getCode"
				/>
			</div>
			<a-button type="primary" @click="getLogin">登录</a-button>
		</div>
	</div>
</template>
<script>
	export default {
		components: {},
		name: 'login',
		data() {
			return {
				userName: null,
				passWord: null,
				imgUrl: '',
				code: null
			};
		},
		methods: {
			// 成功或错误提示
			openNotification(type, message) {
				this.$notification[type]({
					message: message,
					duration: 2
				});
			},
			getCode() {
				this.imgUrl = Math.random();
			},
			async getLogin() {
				if (this.userName == null) {
					this.openNotification('error', '用户名输入错误！');
					return;
				}
				if (this.passWord == null) {
					this.openNotification('error', '密码输入错误！');
					return;
				}
				if (this.code == null) {
					this.openNotification('error', '验证码输入错误！');
					this.getCode();
					return;
				}
				const { data } = await this.$axios.post('/api/v1/admin/outer/login', {
					userName: this.userName,
					password: this.passWord,
					code: this.code
				});
				if (data.code === 200) {
					localStorage.setItem(
						this.$store.state.tokenName,
						'Bearer ' + data.data.token
					);
					localStorage.setItem(this.$store.state.userName, data.data.userName);
					window.location.href = '/';
				} else {
					this.getCode();
				}
			}
		}
	};
</script>
<style scoped>
	.h_login {
		width: 100vw;
		height: 100vh;
		background-color: rgba(155, 155, 155, 0.5);
		position: relative;
	}

	.h-login-box {
		width: 350px;
		padding: 30px;
		background-color: white;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border-radius: 20px;
	}

	.h-login-box1 p {
		font-size: 20px;
		height: 20px;
		line-height: 20px;
		width: 80px;
		margin: 0;
	}

	.h-login-box button {
		width: 120px;
		border-radius: 5px;
		border: 1px solid silver;
		height: 30px;
		background-color: #1890ff;
		color: white;
		margin-left: 70px;
	}
</style>
