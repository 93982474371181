<template>
  <div id="business">
    <a-row :gutter="16" style="margin-bottom: 10px">
      <a-col :span="3">
        <a-button type="primary" @click="addOrUpdate('添加')"
          >创建业务案件</a-button
        >
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入订单号" v-model="search.orderNumber" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入客户名称" v-model="search.clientName" />
      </a-col>
      <a-col :span="2">
        <a-button type="primary" @click="getData(1, pagination.pageSize)"
          >搜索</a-button
        >
      </a-col>
      <a-col :span="1" :offset="7">
        <a-button @click="importInfo.model = true">导入</a-button>
      </a-col>
      <a-col :span="1">
        <a-button @click="exportInfo.exportModel = true">导出</a-button>
      </a-col>
    </a-row>
    <a-row style="margin-bottom: 20px">
      <a-col :span="7" :offset="2">
        <span style="font-size: 16px;">创建时间：</span>
        <a-date-picker
          v-model="search.startTimeVal"
          :disabled-date="disabledStartDate"
          format="YYYY-MM-DD 00:00:00"
          placeholder="开始时间"
          style="margin-right: 10px"
          @change="onChangeStartTime"
          @openChange="handleStartOpenChange"
        />
        <a-date-picker
          v-model="search.endTimeVal"
          :disabled-date="disabledEndDate"
          format="YYYY-MM-DD 23:59:59"
          placeholder="结束时间"
          :open="search.endOpen"
          @change="onChangeEndTime"
          @openChange="handleEndOpenChange"
        />
      </a-col>
      <a-col :span="4">
        <span style="font-size: 16px;">业务类型：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectBusinessType"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option value="1">
            商标注册
          </a-select-option>
          <a-select-option value="2">
            专利申请
          </a-select-option>
          <a-select-option value="3">
            版权登记
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4">
        <span style="font-size: 16px;">付款状态：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectPatentType"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option value="1">
            款项已付
          </a-select-option>
          <a-select-option value="2">
            款项未付
          </a-select-option>
          <a-select-option value="3">
            款项未付清
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="pagination"
      rowKey="id"
      @rowClick="handleRowClick"
    >
      <template slot="businessType" slot-scope="text, record, index">
        <span v-if="text == 1">
          商标注册
        </span>
        <span v-if="text == 2">
          专利申请
        </span>
        <span v-if="text == 3">
          版权登记
        </span>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <a-button size="small" type="primary" @click.stop="seeInfo(record)"
          >查看</a-button
        >
        <a-button size="small" @click.stop="addOrUpdate('修改', record)"
          >修改</a-button
        >
        <a-button
          size="small"
          @click.stop="seeLog(record.id, 1, pagination.pageSize)"
          >记录</a-button
        >
        <a-button
          size="small"
          @click.stop="
            upload.model = true;
            upload.fileList = [];
            seeInfoData.id = record.id;
          "
          >上传资料</a-button
        >
        <a-popconfirm
          title="确定要删除吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteFunc(record.id)"
        >
          <a-button size="small" type="danger" @click.stop>删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <!--  添加或修改  -->
    <a-modal
      :width="700"
      :visible="addOrUpdateModel"
      @cancel="addOrUpdateModel = false"
      :okText="addOrUpdateOption"
      @ok="sublimeOk"
    >
      <a-form-model
        :model="form"
        ref="form"
        :rules="formRole"
        :labelAlign="'left'"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item
          label="业务类型"
          ref="businessType"
          prop="businessType"
        >
          <a-select
            style="width: 150px"
            v-model="form.businessType"
            @change="businessTypeChange"
          >
            <a-select-option :value="1">
              商标注册
            </a-select-option>
            <a-select-option :value="2">
              专利申请
            </a-select-option>
            <a-select-option :value="3">
              版权登记
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="选择客户"
          ref="clientPhone"
          prop="clientPhone"
        >
          <a-select
            style="width: 200px"
            v-model="form.clientPhone"
            @change="chooseClient"
            show-search
            :filterOption="filterOption"
            @search="getClienInfo"
          >
            <a-select-option
              v-for="i in clientInfo"
              :key="i.clientPhone"
              :value="i.clientPhone"
            >
              {{ i.clientName }}-{{ i.clientPhone }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="员工" ref="salesName" prop="salesName">
          <a-input v-model="form.salesName" disabled />
        </a-form-model-item>
        <a-form-model-item label="业务明细" ref="patentName" prop="patentName">
          <a-input v-model="form.patentName" />
        </a-form-model-item>
        <a-form-model-item
          label="业务金额"
          ref="patentMoney"
          prop="patentMoney"
        >
          <a-input type="number" v-model="form.patentMoney" />
        </a-form-model-item>
        <a-form-model-item label="付款方式" ref="payWay" prop="payWay">
          <a-input v-model="form.payWay" />
        </a-form-model-item>
        <a-form-model-item label="付款状态" ref="payStatus" prop="payStatus">
          <a-select
            default-value="0"
            style="width: 150px"
            v-model="form.payStatus"
          >
            <a-select-option :value="1">
              款项已付
            </a-select-option>
            <a-select-option :value="2">
              款项未付
            </a-select-option>
            <a-select-option :value="3">
              款项未付清
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="付款时间">
          <a-date-picker
            show-time
            v-model="form.payTime"
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="选择时间"
            style="margin-right: 10px"
            @change="onChangePayTime"
          />
        </a-form-model-item>
        <a-form-model-item label="备注" ref="remark" prop="remark">
          <a-input v-model="form.remark" />
        </a-form-model-item>
        <a-form-model-item label="业务合同" v-if="addOrUpdateOption === '添加'">
          <a-upload
            :file-list="upload.fileList"
            :remove="uploadHandleRemove"
            :before-upload="uploadBeforeUpload"
            :multiple="true"
          >
            <a-button>
              <a-icon type="upload" />
              点击上传
            </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--  查看  -->
    <a-modal
      :visible="seeInfoModel"
      @cancel="seeInfoModel = false"
      :footer="null"
      :width="600"
    >
      <a-descriptions title="业务案件订单" :column="2">
        <a-descriptions-item label="系统订单号">
          {{ seeInfoData.orderNumber }}
        </a-descriptions-item>
        <a-descriptions-item label="客户名称">
          {{ seeInfoData.clientName }}
        </a-descriptions-item>
        <a-descriptions-item label="员工">
          {{ seeInfoData.salesName }}
        </a-descriptions-item>
        <a-descriptions-item label="业务类型">
          {{ seeInfoData.businessTypeDesc }}
        </a-descriptions-item>
        <a-descriptions-item label="业务明细">
          {{ seeInfoData.patentName }}
        </a-descriptions-item>
        <a-descriptions-item label="业务金额">
          {{ seeInfoData.patentMoney }}
        </a-descriptions-item>
        <a-descriptions-item label="付款方式">
          {{ seeInfoData.payWay }}
        </a-descriptions-item>
        <a-descriptions-item label="付款时间">
          {{ seeInfoData.payTime }}
        </a-descriptions-item>
        <a-descriptions-item label="付款状态">
          {{ seeInfoData.payStatusDesc }}
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          {{ seeInfoData.remark }}
        </a-descriptions-item>
        <a-descriptions-item label="下载资料">
          <a-button
            type="primary"
            @click="seeDowData(seeInfoData.id, 1, download.pagination.pageSize)"
          >
            查看资料
          </a-button>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <!--  查看图片  -->
    <a-modal
      :visible="previewVisible"
      @cancel="previewVisible = false"
      :footer="null"
      :width="800"
      :z-index="9999"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <!--  上传资料模态框  -->
    <a-modal
      :width="700"
      :visible="upload.model"
      @cancel="upload.model = false"
      okText="确定"
      @ok="uploadHandleUpload"
      :ok-button-props="{ props: { disabled: upload.fileList.length === 0 } }"
    >
      <a-upload
        :file-list="upload.fileList"
        :remove="uploadHandleRemove"
        :before-upload="uploadBeforeUpload"
        :multiple="true"
      >
        <a-button>
          <a-icon type="upload" />
          点击上传
        </a-button>
      </a-upload>
    </a-modal>
    <!--  导入资料模态框  -->
    <a-modal
      :width="700"
      :visible="importInfo.model"
      @cancel="importInfo.model = false"
      okText="确定"
      @ok="importHandleUpload"
      :ok-button-props="{
        props: { disabled: importInfo.fileList.length === 0 },
      }"
    >
      <a href="/业务案件上传模板.xlsx" download>下载模板</a>
      <a-upload
        :file-list="importInfo.fileList"
        :remove="importHandleRemove"
        :before-upload="importBeforeUpload"
      >
        <a-button :disabled="importInfo.fileList.length >= 1">
          <a-icon type="upload" />
          点击上传
        </a-button>
      </a-upload>
    </a-modal>
    <a-modal
      v-model="exportInfo.exportModel"
      @ok="exportOk"
      :destroyOnClose="true"
    >
      <p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">
        请选择导出的时间：
      </p>
      <a-date-picker
        v-model="exportInfo.exportStartValue"
        :disabled-date="disabledExStartDate"
        format="YYYY-MM-DD 00:00:00"
        placeholder="开始时间"
        style="margin-right: 10px"
        @change="onChangeExStartTime"
        @openChange="handleExStartOpenChange"
      />
      <a-date-picker
        v-model="exportInfo.exportEndValue"
        :disabled-date="disabledExEndDate"
        format="YYYY-MM-DD 23:59:59"
        placeholder="结束时间"
        :open="exportInfo.endExOpen"
        @change="onChangeExEndTime"
        @openChange="handleExEndOpenChange"
      />
    </a-modal>
    <!--  查看记录  -->
    <a-modal
      :width="1400"
      :visible="log.model"
      @cancel="log.model = false"
      :footer="null"
    >
      <a-table
        :columns="log.columns"
        :data-source="log.data"
        bordered
        :pagination="log.pagination"
        rowKey="id"
      >
        <template slot="operation" slot-scope="text, record, index">
          <a-button
            type="primary"
            @click="
              seeDowData(
                record.businessCasePatentId,
                1,
                download.pagination.pageSize
              )
            "
            >查看资料
          </a-button>
        </template>
      </a-table>
    </a-modal>
    <!--  查看资料模态框  -->
    <a-modal
      :width="700"
      :visible="download.seeDowModel"
      @cancel="download.seeDowModel = false"
      okText="确定"
      @ok="download.seeDowModel = false"
    >
      <a-table
        :columns="download.columns"
        :data-source="download.data"
        bordered
        :pagination="download.pagination"
        rowKey="id"
      >
        <template slot="fileName" slot-scope="text, record, index">
          {{ text }}
          <a
            href="javascript:void(0)"
            v-if="record.isImg"
            @click="
              previewVisible = true;
              previewImage = record.url;
            "
          >
            <img alt="example" style="width: 20%" :src="record.url" />
          </a>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <a
            :download="record.fileNmame"
            :href="record.url"
            v-if="!record.isImg"
            >下载</a
          >
          <a-popconfirm
            title="确定要删除吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteFile(record.url)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "系统订单号",
    dataIndex: "orderNumber",
    scopedSlots: { customRender: "orderNumber" },
  },
  {
    title: "日期",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "客户名称",
    dataIndex: "clientName",
    scopedSlots: { customRender: "clientName" },
  },
  {
    title: "客户手机号",
    dataIndex: "clientPhone",
    scopedSlots: { customRender: "clientPhone" },
  },
  {
    title: "员工",
    dataIndex: "salesName",
    scopedSlots: { customRender: "salesName" },
  },
  {
    title: "员工手机号",
    dataIndex: "salesPhone",
    scopedSlots: { customRender: "salesPhone" },
  },
  {
    title: "业务类型",
    dataIndex: "businessType",
    scopedSlots: { customRender: "businessType" },
  },
  {
    title: "业务明细",
    dataIndex: "patentName",
    scopedSlots: { customRender: "patentName" },
  },
  {
    title: "业务金额",
    dataIndex: "patentMoney",
    scopedSlots: { customRender: "patentMoney" },
  },
  {
    title: "付款方式",
    dataIndex: "payWay",
    scopedSlots: { customRender: "payWay" },
  },
  {
    title: "付款时间",
    dataIndex: "payTime",
    scopedSlots: { customRender: "payTime" },
  },
  {
    title: "付款状态",
    dataIndex: "payStatusDesc",
    scopedSlots: { customRender: "payStatusDesc" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: "15%",
    scopedSlots: { customRender: "operation" },
  },
];
const logColumns = [
  {
    title: "系统订单号",
    dataIndex: "orderNumber",
    scopedSlots: { customRender: "orderNumber" },
  },
  {
    title: "日期",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "客户名称",
    dataIndex: "clientName",
    scopedSlots: { customRender: "clientName" },
  },
  {
    title: "客户手机号",
    dataIndex: "clientPhone",
    scopedSlots: { customRender: "clientPhone" },
  },
  {
    title: "员工",
    dataIndex: "salesName",
    scopedSlots: { customRender: "salesName" },
  },
  {
    title: "员工手机号",
    dataIndex: "salesPhone",
    scopedSlots: { customRender: "salesPhone" },
  },
  {
    title: "业务类型",
    dataIndex: "businessTypeDesc",
    scopedSlots: { customRender: "businessTypeDesc" },
  },
  {
    title: "业务明细",
    dataIndex: "patentName",
    scopedSlots: { customRender: "patentName" },
  },
  {
    title: "业务金额",
    dataIndex: "patentMoney",
    scopedSlots: { customRender: "patentMoney" },
  },
  {
    title: "付款方式",
    dataIndex: "payWay",
    scopedSlots: { customRender: "payWay" },
  },
  {
    title: "付款时间",
    dataIndex: "payTime",
    scopedSlots: { customRender: "payTime" },
  },
  {
    title: "付款状态",
    dataIndex: "payStatusDesc",
    scopedSlots: { customRender: "payStatusDesc" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作人",
    dataIndex: "optionUser",
    scopedSlots: { customRender: "optionUser" },
  },
  {
    title: "操作人手机号",
    dataIndex: "optionUserPhone",
    scopedSlots: { customRender: "optionUserPhone" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  name: "business",
  data() {
    return {
      download: {
        seeDowModel: false,
        columns: [
          {
            title: "资料名称",
            dataIndex: "fileName",
            scopedSlots: { customRender: "fileName" },
          },
          {
            title: "操作",
            dataIndex: "operation",
            key: "operation",
            width: "25%",
            scopedSlots: { customRender: "operation" },
          },
        ],
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 100,
          onChange: (page, pageSize) => {
            this.getData(page, pageSize);
          },
        },
      },
      log: {
        model: false,
        columns: logColumns,
        data: [
          {
            id: "adadas",
          },
        ],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 100,
          onChange: (page, pageSize) => {
            this.getData(page, pageSize);
          },
        },
      },
      upload: {
        model: false,
        fileList: [],
      },
      importInfo: {
        model: false,
        fileList: [],
      },
      exportInfo: {
        exportModel: false,
        exportEndValue: null,
        endExOpen: false,
        exportStartValue: null,
        exportStartTime: null,
        exportEndTime: null,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize);
        },
      },
      search: {
        orderNumber: null,
        clientName: null,
        businessType: null,
        payStatus: null,
        endTime: null,
        startTime: null,
        endTimeVal: null,
        startTimeVal: null,
        endOpen: false,
      },
      columns,
      data: [],
      clientInfo: [],
      form: {
        businessType: null,
        businessTypeLabel: null,
        clientName: null,
        clientPhone: null,
        salesName: null,
        salesPhone: null,
        patentName: null,
        patentMoney: null,
        remark: null,
        payWay: null,
        payStatus: null,
        payTime: null,
        payTimeVal: null,
      },
      addOrUpdateModel: false,
      seeInfoData: {},
      seeInfoModel: false,
      addOrUpdateOption: "添加",
      formRole: {
        businessType: [
          { required: true, message: "业务类型不能为空", trigger: "blur" },
        ],
        patentName: [
          { required: true, message: "业务明细不能为空", trigger: "blur" },
        ],
        patentMoney: [
          { required: true, message: "业务金额不能为空", trigger: "blur" },
        ],
        payWay: [
          { required: true, message: "付款方式不能为空", trigger: "blur" },
        ],
        againPassword: [
          { required: true, message: "客户类型不能为空", trigger: "blur" },
        ],
        sales: [{ required: true, message: "员工不能为空", trigger: "blur" }],
        payStatus: [
          { required: true, message: "员工不能为空", trigger: "blur" },
        ],
      },
      previewVisible: false,
      previewImage: null,
    };
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize);
    this.getClienInfo();
  },
  methods: {
    async getData(pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/proxy/business/case/get/" + pageNum + "/" + pageSize,
        {
          params: {
            orderNumber: this.search.orderNumber,
            clientName: this.search.clientName,
            businessType: this.search.businessType,
            payStatus: this.search.payStatus,
            startTime: this.search.startTime,
            endTime: this.search.endTime,
          },
        }
      );
      this.data = data.data.list;
      this.pagination.current = data.data.pageNum;
      this.pagination.pageSize = data.data.pageSize;
      this.pagination.total = data.data.total;
    },
    // 查询客户信息
    async getClienInfo(v) {
      const { data } = await this.$axios.get(
        "/api/v1/common/search/3/1/100000",
        {
          params: {
            search: v,
            clientType: this.form.businessTypeLabel,
          },
        }
      );
      this.clientInfo = data.data.list;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    selectBusinessType(value) {
      if (value === "0") {
        this.search.businessType = null;
      } else {
        this.search.businessType = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    selectPatentType(value) {
      if (value === "0") {
        this.search.payStatus = null;
      } else {
        this.search.payStatus = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    // 查看信息
    seeInfo(record) {
      this.seeInfoModel = true;
      this.seeInfoData = record;
    },
    // 查看记录
    async seeLog(id, pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/proxy/business/case/log/get/" +
          id +
          "/" +
          pageNum +
          "/" +
          pageSize
      );
      this.log.data = data.data.list;
      this.log.pagination.current = data.data.pageNum;
      this.log.pagination.pageSize = data.data.pageSize;
      this.log.pagination.total = data.data.total;
      this.log.model = true;
    },
    async deleteFunc(id) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/proxy/business/case/delete",
        {
          id: id,
        }
      );
      this.$message.success("删除成功！");
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    // 添加或修改
    addOrUpdate(op, record) {
      this.addOrUpdateModel = true;
      this.form = {
        businessType: null,
        businessTypeLabel: null,
        clientName: null,
        cooperPhone: null,
        recommendUserName: null,
        recommendUserPhone: null,
        patentNumber: null,
        patentMoney: null,
        money: null,
        payWay: null,
        status: null,
        declareTime: null,
        payTimeVal: null,
      };
      this.upload.fileList = [];
      this.addOrUpdateOption = op;
      if (op === "修改") {
        this.form = record;
        switch (this.form.businessType) {
          case 1:
            this.form.businessTypeLabel = "商标";
            break;
          case 2:
            this.form.businessTypeLabel = "专利";
            break;
          case 3:
            this.form.businessTypeLabel = "版权";
            break;
        }
      }
      this.getClienInfo();
    },
    // 暂停或启用员工
    setUserAuth(type) {
      this.$message.success("设置成功！");
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    sublimeOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.form.payTime = this.form.payTimeVal;
          if (this.addOrUpdateOption === "添加") {
            var { data } = await this.$axios.post(
              "/api/v1/admin/proxy/business/case/add",
              this.form
            );
            // 上传资料
            for (let i = 0; i < this.upload.fileList.length; i++) {
              let file = this.upload.fileList[i];
              let formData = new FormData();
              formData.append("file", file);
              await this.$axios.post(
                "/api/v1/common/upload/file?type=2&uploadTypeId=" + data.data,
                formData
              );
            }
            this.upload.fileList = [];
          } else if (this.addOrUpdateOption === "修改") {
            var { data } = await this.$axios.post(
              "/api/v1/admin/proxy/business/case/update",
              this.form
            );
          }
          this.addOrUpdateModel = false;
          this.$message.success("操作成功！");
          this.getData(1, this.pagination.pageSize);
        }
      });
    },
    // 查看下载资料
    async seeDowData(id, pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/common/get/file/" + id + "/2/" + pageNum + "/" + pageSize
      );
      this.download.data = data.data.list;
      this.download.pagination.current = data.data.pageNum;
      this.download.pagination.pageSize = data.data.pageSize;
      this.download.pagination.total = data.data.total;
      this.download.seeDowModel = true;
    },
    // 选择客户后
    chooseClient(v) {
      for (let i = 0; i < this.clientInfo.length; i++) {
        let clientInfoElement = this.clientInfo[i];
        if (clientInfoElement.clientPhone === v) {
          this.form.salesName = clientInfoElement.salesName;
          this.form.salesPhone = clientInfoElement.salesPhone;
          this.form.clientName = clientInfoElement.clientName;
        }
      }
    },
    // 下载全部资料
    downloadDataAll() {},
    // 上传资料
    uploadDataAll() {},
    //  删除文件
    async deleteFile(url) {
      this.$commJs.deleteFile(this, url);
    },
    // 上传资料
    uploadHandleRemove(file) {
      const index = this.upload.fileList.indexOf(file);
      const newFileList = this.upload.fileList.slice();
      newFileList.splice(index, 1);
      this.upload.fileList = newFileList;
    },
    uploadBeforeUpload(file) {
      this.upload.fileList = [...this.upload.fileList, file];
      return false;
    },
    async uploadHandleUpload() {
      // 上传代码
      for (let i = 0; i < this.upload.fileList.length; i++) {
        let file = this.upload.fileList[i];
        let formData = new FormData();
        formData.append("file", file);
        await this.$axios.post(
          "/api/v1/common/upload/file?type=2&uploadTypeId=" +
            this.seeInfoData.id,
          formData
        );
      }
      // 上传代码
      this.upload.fileList = [];
      this.upload.model = false;
      this.$message.success("上传成功！");
    },
    // 格式化时间
    disabledStartDate(startValue) {
      const endValue = this.search.endTimeVal;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.search.startTimeVal;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    onChangeStartTime(value, dateString) {
      this.search.startTime = this.$commJs.formatStrTime(dateString);
      this.getData(1, this.pagination.pageSize);
    },
    onChangeEndTime(value, dateString) {
      this.search.endTime = this.$commJs.formatStrTime(dateString);
      this.getData(1, this.pagination.pageSize);
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.search.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.search.endOpen = open;
    },
    // 导入资料
    importHandleRemove(file) {
      const index = this.importInfo.fileList.indexOf(file);
      const newFileList = this.importInfo.fileList.slice();
      newFileList.splice(index, 1);
      this.importInfo.fileList = newFileList;
    },
    importBeforeUpload(file) {
      this.importInfo.fileList = [...this.importInfo.fileList, file];
      return false;
    },
    async importHandleUpload() {
      // 上传代码
      for (let i = 0; i < this.importInfo.fileList.length; i++) {
        let file = this.importInfo.fileList[i];
        let formData = new FormData();
        formData.append("file", file);
        await this.$axios.post(
          "/api/v1/admin/proxy/business/case/upload",
          formData
        );
      }
      this.importInfo.fileList = [];
      this.importInfo.model = false;
      this.$message.success("上传成功！");
      this.getData(1, this.pagination.pageSize);
    },
    //导出
    disabledExStartDate(startValue) {
      const endValue = this.exportInfo.exportEndValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledExEndDate(endValue) {
      const startValue = this.exportInfo.exportStartValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    onChangePayTime(value, dateString) {
      this.form.payTimeVal = this.$commJs.formatStrTime(dateString);
    },
    onChangeExStartTime(value, dateString) {
      this.exportInfo.exportStartTime = this.$commJs.formatStrTime(dateString);
    },
    onChangeExEndTime(value, dateString) {
      this.exportInfo.exportEndTime = this.$commJs.formatStrTime(dateString);
    },
    handleExStartOpenChange(open) {
      if (!open) {
        this.exportInfo.endExOpen = true;
      }
    },
    handleExEndOpenChange(open) {
      this.exportInfo.endExOpen = open;
    },
    // 导出
    async exportOk() {
      this.$commJs.exportExcel(
        this,
        "/api/v1/admin/export/business/case/" +
          this.exportInfo.exportStartTime +
          "/" +
          this.exportInfo.exportEndTime,
        "业务案件-" +
          this.exportInfo.exportStartTime +
          "~" +
          this.exportInfo.exportEndTime
      );
      this.exportInfo.exportModel = false;
    },
    businessTypeChange(value, item) {
      switch (value) {
        case 1:
          this.form.businessTypeLabel = "商标";
          break;
        case 2:
          this.form.businessTypeLabel = "专利";
          break;
        case 3:
          this.form.businessTypeLabel = "版权";
          break;
      }
      this.getClienInfo();
    },
    // 点击整行显示详情
    handleRowClick(record) {
      this.seeInfo(record);
    },
  },
};
</script>

<style scoped></style>
