<template>
  <div id="client">
    <a-row :gutter="16" style="margin-bottom: 10px">
      <a-col :span="5">
        <a-input placeholder="输入用户姓名" v-model="search.userName" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入用户手机号" v-model="search.phone" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入客户名称" v-model="search.clientName" />
      </a-col>
      <a-col :span="3">
        <a-select default-value="0" style="width: 150px" @change="selectType">
          <a-select-option value="0">
            时间排序
          </a-select-option>
          <a-select-option value="1">
            关联用户排序
          </a-select-option>
          <a-select-option value="2">
            员工排序
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="3">
        <span>业务类型</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="clientTypeSearch"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option value="专利代理">
            专利代理
          </a-select-option>
          <a-select-option value="商标代理">
            商标代理
          </a-select-option>
          <a-select-option value="版权代理">
            版权代理
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="1" :offset="1">
        <a-button @click="importInfo.model = true">导入</a-button>
      </a-col>
    </a-row>
    <a-row :gutter="16" style="margin-bottom: 10px">
      <a-col :span="5">
        <a-input placeholder="输入证件号" v-model="search.idCard" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入联系人" v-model="search.linkUser" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入联系人手机号" v-model="search.linkPhone" />
      </a-col>
      <a-col :span="2">
        <a-button type="primary" @click="getData(1, pagination.pageSize)"
          >搜索</a-button
        >
      </a-col>
      <a-col :span="1" :offset="5">
        <a-button @click="exportInfo.exportModel = true">导出</a-button>
      </a-col>
    </a-row>
    <a-row style="margin-bottom: 20px">
      <a-col :span="3">
        <a-button type="primary" @click="addOrUpdate('添加')"
          >添加客户</a-button
        >
      </a-col>
      <a-col :span="10">
        <span style="font-size: 16px;font-weight: 600">时间选择：</span>
        <a-date-picker
          v-model="search.startTime"
          :disabled-date="disabledStartDate"
          format="YYYY-MM-DD"
          placeholder="开始时间"
          style="margin-right: 10px"
          @change="onChangeStartTime"
          @openChange="handleStartOpenChange"
        />
        <a-date-picker
          v-model="search.endTime"
          :disabled-date="disabledEndDate"
          format="YYYY-MM-DD"
          placeholder="结束时间"
          :open="search.endOpen"
          @change="onChangeEndTime"
          @openChange="handleEndOpenChange"
        />
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="pagination"
      rowKey="id"
      @rowClick="handleRowClick"
    >
      <template slot="isAuth" slot-scope="text, record, index">
        <template v-if="record.phone !== null">
          是
        </template>
        <template v-else>
          否
        </template>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <a-button size="small" type="primary" @click.stop="seeInfo(record)"
          >查看</a-button
        >
        <a-button size="small" @click.stop="addOrUpdate('修改', record)"
          >修改</a-button
        >
        <a-button
          size="small"
          @click.stop="
            upload.model = true;
            seeInfoData = record;
          "
          >上传资料</a-button
        >
        <a-popconfirm
          title="确定要删除吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteFunc(record.id)"
        >
          <a-button size="small" type="danger" @click.stop>删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      :width="700"
      :visible="addOrUpdateModel"
      @cancel="addOrUpdateModel = false"
      :okText="addOrUpdateOption"
      @ok="sublimeOk"
    >
      <a-form-model
        :model="form"
        ref="form"
        :rules="formRole"
        :labelAlign="'left'"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item
          label="客户性质"
          ref="clientNature"
          prop="clientNature"
        >
          <a-radio-group v-model="form.clientNature" :default-value="0">
            <a-radio :value="1">
              个人
            </a-radio>
            <a-radio :value="2">
              企业
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="客户名称" ref="clientName" prop="clientName">
          <a-input v-model="form.clientName" />
        </a-form-model-item>
        <a-form-model-item
          :label="form.clientNature === 2 ? '信用代码' : '身份证号'"
          prop="idCard"
        >
          <a-input v-model="form.idCard" />
        </a-form-model-item>
        <a-form-model-item label="联系人" ref="linkUser" prop="linkUser">
          <a-input v-model="form.linkUser" />
        </a-form-model-item>
        <a-form-model-item label="联系电话" ref="linkPhone" prop="linkPhone">
          <a-input v-model="form.linkPhone" />
        </a-form-model-item>
        <a-form-model-item label="业务类型" ref="clientType" prop="clientType">
          <a-select
            v-model="form.clientType"
            mode="multiple"
            placeholder="请选择业务类型"
          >
            <a-select-option value="专利代理">
              专利代理
            </a-select-option>
            <a-select-option value="商标代理">
              商标代理
            </a-select-option>
            <a-select-option value="版权代理">
              版权代理
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="备注" ref="remark" prop="remark">
          <a-input v-model="form.remark" />
        </a-form-model-item>
        <a-form-model-item label="员工" ref="salesId" prop="salesId">
          <a-select v-model="form.salesId" placeholder="请选择员工">
            <a-select-option v-for="i in allSales" :key="i.id" :value="i.id">
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上传资料">
          <a-upload
            :file-list="upload.fileList"
            :remove="uploadHandleRemove"
            :before-upload="uploadBeforeUpload"
            :multiple="true"
          >
            <a-button>
              <a-icon type="upload" />
              点击上传
            </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="seeInfoModel"
      @cancel="seeInfoModel = false"
      :footer="null"
      :width="600"
    >
      <a-descriptions title="用户信息" :column="2">
        <a-descriptions-item label="用户姓名">
          {{ seeInfoData.userName }}
        </a-descriptions-item>
        <a-descriptions-item label="用户手机号">
          {{ seeInfoData.phone }}
        </a-descriptions-item>
        <a-descriptions-item label="是否关联用户">
          {{ seeInfoData.phone == null ? "否" : "是" }}
        </a-descriptions-item>
        <a-descriptions-item label="录入时间">
          {{ seeInfoData.createTime }}
        </a-descriptions-item>
        <a-descriptions-item label="客户名称">
          {{ seeInfoData.clientName }}
        </a-descriptions-item>
        <a-descriptions-item label="证件号">
          {{ seeInfoData.idCard }}
        </a-descriptions-item>
        <a-descriptions-item label="联系人">
          {{ seeInfoData.linkUser }}
        </a-descriptions-item>
        <a-descriptions-item label="手机号">
          {{ seeInfoData.linkPhone }}
        </a-descriptions-item>
        <a-descriptions-item label="业务类型">
          {{ seeInfoData.clientType }}
        </a-descriptions-item>
        <a-descriptions-item label="员工">
          {{ seeInfoData.salesName }}
        </a-descriptions-item>
        <a-descriptions-item label="备注">
          {{ seeInfoData.remark }}
        </a-descriptions-item>
        <template>
          <a-descriptions-item label="身份证正面">
            <template v-if="seeInfoData.idCardPositive !== null">
              <a
                href="javascript:void(0)"
                @click="
                  previewVisible = true;
                  previewImage = seeInfoData.idCardPositive;
                "
              >
                <img
                  alt="example"
                  style="width: 40%"
                  :src="seeInfoData.idCardPositive"
                />
              </a>
            </template>
            <template v-if="seeInfoData.idCardPositive === null">暂无</template>
          </a-descriptions-item>
          <a-descriptions-item label="身份证反面">
            <template v-if="seeInfoData.idCardNegative !== null">
              <a
                href="javascript:void(0)"
                @click="
                  previewVisible = true;
                  previewImage = seeInfoData.idCardNegative;
                "
              >
                <img
                  alt="example"
                  style="width: 40%"
                  :src="seeInfoData.idCardNegative"
                />
              </a>
            </template>
            <template v-if="seeInfoData.idCardNegative === null">暂无</template>
          </a-descriptions-item>
        </template>
        <template>
          <a-descriptions-item label="营业执照">
            <template v-if="seeInfoData.businessLicense !== null">
              <a
                href="javascript:void(0)"
                @click="
                  previewVisible = true;
                  previewImage = seeInfoData.businessLicense;
                "
              >
                <img
                  alt="example"
                  style="width: 40%"
                  :src="seeInfoData.businessLicense"
                />
              </a>
            </template>
            <template v-if="seeInfoData.businessLicense === null"
              >暂无</template
            >
          </a-descriptions-item>
        </template>
        <a-descriptions-item label="下载资料">
          <a-button
            type="primary"
            @click="seeDowData(1, download.pagination.pageSize)"
            >查看资料</a-button
          >
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <a-modal
      :visible="previewVisible"
      @cancel="previewVisible = false"
      :footer="null"
      :width="800"
      :z-index="9999"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <!--  查看资料模态框  -->
    <a-modal
      :width="700"
      :visible="download.seeDowModel"
      @cancel="download.seeDowModel = false"
      okText="确定"
      @ok="download.seeDowModel = false"
    >
      <a-table
        :columns="download.columns"
        :data-source="download.data"
        bordered
        :pagination="download.pagination"
        rowKey="id"
      >
        <template slot="fileName" slot-scope="text, record, index">
          {{ text }}
          <a
            href="javascript:void(0)"
            v-if="record.isImg"
            @click="
              previewVisible = true;
              previewImage = record.url;
            "
          >
            <img alt="example" style="width: 20%" :src="record.url" />
          </a>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <a
            :download="record.fileNmame"
            :href="record.url"
            v-if="!record.isImg"
            >下载</a
          >
          <a-popconfirm
            title="确定要删除吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteFile(record.url)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </a-modal>

    <!--  上传资料模态框  -->
    <a-modal
      :width="700"
      :visible="upload.model"
      @cancel="upload.model = false"
      okText="确定"
      @ok="uploadHandleUpload"
      :ok-button-props="{ props: { disabled: upload.fileList.length === 0 } }"
    >
      <a-upload
        :file-list="upload.fileList"
        :remove="uploadHandleRemove"
        :before-upload="uploadBeforeUpload"
        :multiple="true"
      >
        <a-button>
          <a-icon type="upload" />
          点击上传
        </a-button>
      </a-upload>
    </a-modal>

    <!--  导入资料模态框  -->
    <a-modal
      :width="700"
      :visible="importInfo.model"
      @cancel="importInfo.model = false"
      okText="确定"
      @ok="importHandleUpload"
      :ok-button-props="{
        props: { disabled: importInfo.fileList.length === 0 },
      }"
    >
      <a href="/客户资料上传模板.xlsx" download>下载模板</a>
      <a-upload
        :file-list="importInfo.fileList"
        :remove="importHandleRemove"
        :before-upload="importBeforeUpload"
      >
        <a-button :disabled="importInfo.fileList.length >= 1">
          <a-icon type="upload" />
          点击上传
        </a-button>
      </a-upload>
    </a-modal>

    <a-modal
      v-model="exportInfo.exportModel"
      @ok="exportOk"
      :destroyOnClose="true"
    >
      <p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">
        请选择导出的时间：
      </p>
      <a-date-picker
        v-model="exportInfo.exportStartValue"
        :disabled-date="disabledExStartDate"
        format="YYYY-MM-DD 00:00:00"
        placeholder="开始时间"
        style="margin-right: 10px"
        @change="onChangeExStartTime"
        @openChange="handleExStartOpenChange"
      />
      <a-date-picker
        v-model="exportInfo.exportEndValue"
        :disabled-date="disabledExEndDate"
        format="YYYY-MM-DD 23:59:59"
        placeholder="结束时间"
        :open="exportInfo.endExOpen"
        @change="onChangeExEndTime"
        @openChange="handleExEndOpenChange"
      />
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "系统ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
  },
  {
    title: "用户手机号",
    dataIndex: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "用户姓名",
    dataIndex: "userName",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "是否关联用户",
    dataIndex: "isAuth",
    scopedSlots: { customRender: "isAuth" },
  },
  {
    title: "录入时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "客户名称",
    dataIndex: "clientName",
    scopedSlots: { customRender: "clientName" },
  },
  {
    title: "证件号码",
    dataIndex: "idCard",
    scopedSlots: { customRender: "idCard" },
  },
  {
    title: "联系人",
    dataIndex: "linkUser",
    scopedSlots: { customRender: "linkUser" },
  },
  {
    title: "联系电话",
    dataIndex: "linkPhone",
    scopedSlots: { customRender: "linkPhone" },
  },
  {
    title: "业务类型",
    dataIndex: "clientType",
    scopedSlots: { customRender: "clientType" },
  },
  {
    title: "员工",
    dataIndex: "salesName",
    scopedSlots: { customRender: "salesName" },
  },
  {
    title: "备注",
    dataIndex: "remark",
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: "15%",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  name: "client",
  data() {
    return {
      download: {
        seeDowModel: false,
        columns: [
          {
            title: "资料名称",
            dataIndex: "fileName",
            scopedSlots: { customRender: "fileName" },
          },
          {
            title: "操作",
            dataIndex: "operation",
            key: "operation",
            width: "25%",
            scopedSlots: { customRender: "operation" },
          },
        ],
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 100,
          onChange: (page, pageSize) => {
            this.getData(page, pageSize);
          },
        },
      },
      upload: {
        model: false,
        fileList: [],
      },
      importInfo: {
        model: false,
        fileList: [],
      },
      exportInfo: {
        exportModel: false,
        exportEndValue: null,
        endExOpen: false,
        exportStartValue: null,
        exportStartTime: null,
        exportEndTime: null,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize);
        },
      },
      search: {
        endTime: null,
        startTime: null,
        endOpen: false,
        userName: null,
        userPhone: null,
        clientName: null,
        idCard: null,
        linkUser: null,
        linkPhone: null,
        sortType: null,
        clientType: null,
      },
      columns,
      data: [],
      allSales: [],
      form: {
        clientNature: null,
        idCard: null,
        clientName: null,
        linkUser: null,
        linkPhone: null,
        clientType: [],
        remark: null,
        salesId: null,
      },
      addOrUpdateModel: false,
      seeInfoData: {},
      seeInfoModel: false,
      addOrUpdateOption: "添加",
      formRole: {
        clientNature: [
          { required: true, message: "客户性质不能为空", trigger: "blur" },
        ],
        clientName: [
          { required: true, message: "客户名称不能为空", trigger: "blur" },
        ],
        linkUser: [
          { required: true, message: "联系人不能为空", trigger: "blur" },
        ],
        linkPhone: [
          { required: true, message: "联系人手机号不能为空", trigger: "blur" },
        ],
        clientType: [
          { required: true, message: "业务类型不能为空", trigger: "blur" },
        ],
        salesId: [{ required: true, message: "员工不能为空", trigger: "blur" }],
      },
      previewVisible: false,
      previewImage: null,
    };
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize);
    this.getAllSales();
  },
  methods: {
    async getData(pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/proxy/client/get/" + pageNum + "/" + pageSize,
        {
          params: {
            endTime: this.search.endTime,
            startTime: this.search.startTime,
            userName: this.search.userName,
            userPhone: this.search.userPhone,
            clientName: this.search.clientName,
            idCard: this.search.idCard,
            linkUser: this.search.linkUser,
            linkPhone: this.search.linkPhone,
            sortType: this.search.sortType,
            clientType: this.search.clientType,
          },
        }
      );
      this.data = data.data.list;
      this.pagination.current = data.data.pageNum;
      this.pagination.pageSize = data.data.pageSize;
      this.pagination.total = data.data.total;
    },
    async getAllSales() {
      const { data } = await this.$axios.get(
        "/api/v1/admin/info/sales/get/1/9999999"
      );
      this.allSales = data.data.list;
    },
    clientTypeSearch(value) {
      this.search.clientType = value;
      if (value === "0") {
        this.search.clientType = null;
      }
      this.getData(1, this.pagination.pageSize);
    },
    selectType(value) {
      this.search.sortType = value;
      this.getData(1, this.pagination.pageSize);
    },
    // 查看信息
    seeInfo(record) {
      this.seeInfoModel = true;
      this.seeInfoData = record;
    },
    // 添加或修改
    addOrUpdate(op, record) {
      this.addOrUpdateModel = true;
      this.form = {
        clientNature: null,
        idCard: null,
        clientName: null,
        linkPhone: null,
        clientType: [],
        applyUser: null,
        salesId: null,
      };
      this.addOrUpdateOption = op;
      if (op === "修改") {
        // xxx
        this.form = { ...record };
        if (this.form.clientType)
          this.form.clientType = this.form.clientType.split(",");
        this.seeInfoData.id = record.id;
      }
    },
    sublimeOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.clientType.length)
            this.form.clientType = this.form.clientType.join(",");
          if (this.addOrUpdateOption === "添加") {
            const { data } = await this.$axios.post(
              "/api/v1/admin/proxy/client/add",
              this.form
            );
            this.seeInfoData.id = data.data;
          } else if (this.addOrUpdateOption === "修改") {
            await this.$axios.post(
              "/api/v1/admin/proxy/client/update",
              this.form
            );
          }

          // 上传资料
          this.uploadHandleUpload();
          this.addOrUpdateModel = false;
          this.getData(1, this.pagination.pageSize);
        }
      });
    },
    async deleteFunc(id) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/proxy/client/delete",
        {
          id: id,
        }
      );
      this.$message.success("删除成功！");
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    // 查看下载资料
    async seeDowData(pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/common/get/file/" +
          this.seeInfoData.id +
          "/1/" +
          pageNum +
          "/" +
          pageSize
      );
      this.download.data = data.data.list;
      this.download.pagination.current = data.data.pageNum;
      this.download.pagination.pageSize = data.data.pageSize;
      this.download.pagination.total = data.data.total;
      this.download.seeDowModel = true;
    },
    // 下载全部资料
    downloadDataAll() {},
    // 上传资料
    uploadHandleRemove(file) {
      const index = this.upload.fileList.indexOf(file);
      const newFileList = this.upload.fileList.slice();
      newFileList.splice(index, 1);
      this.upload.fileList = newFileList;
    },
    uploadBeforeUpload(file) {
      this.upload.fileList = [...this.upload.fileList, file];
      return false;
    },
    async uploadHandleUpload() {
      // 上传代码
      for (let i = 0; i < this.upload.fileList.length; i++) {
        let file = this.upload.fileList[i];
        let formData = new FormData();
        formData.append("file", file);
        await this.$axios.post(
          "/api/v1/common/upload/file?type=1&uploadTypeId=" +
            this.seeInfoData.id,
          formData
        );
      }
      this.upload.fileList = [];
      this.upload.model = false;
      this.$message.success("上传成功！");
    },
    // 格式化时间
    disabledStartDate(startValue) {
      const endValue = this.search.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.search.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    onChangeStartTime(value, dateString) {
      this.startTime = this.$commJs.formatStrTime(dateString);
      this.getData(1, this.pagination.pageSize);
    },
    onChangeEndTime(value, dateString) {
      this.endTime = this.$commJs.formatStrTime(dateString);
      this.getData(1, this.pagination.pageSize);
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.search.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.search.endOpen = open;
    },
    // 导入资料
    importHandleRemove(file) {
      const index = this.importInfo.fileList.indexOf(file);
      const newFileList = this.importInfo.fileList.slice();
      newFileList.splice(index, 1);
      this.importInfo.fileList = newFileList;
    },
    importBeforeUpload(file) {
      this.importInfo.fileList = [...this.importInfo.fileList, file];
      return false;
    },
    async importHandleUpload() {
      // 上传代码
      for (let i = 0; i < this.importInfo.fileList.length; i++) {
        let file = this.importInfo.fileList[i];
        let formData = new FormData();
        formData.append("file", file);
        await this.$axios.post(
          "/api/v1/admin/proxy/client/add/upload",
          formData
        );
      }
      // 上传代码
      this.importInfo.fileList = [];
      this.importInfo.model = false;
      this.$message.success("上传成功！");
      this.getData(1, this.pagination.pageSize);
    },
    //  删除文件
    async deleteFile(url) {
      this.$commJs.deleteFile(this, url);
      this.seeDowData(1, this.download.pagination.pageSize);
    },
    //导出
    disabledExStartDate(startValue) {
      const endValue = this.exportInfo.exportEndValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledExEndDate(endValue) {
      const startValue = this.exportInfo.exportStartValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    onChangeExStartTime(value, dateString) {
      this.exportInfo.exportStartTime = this.$commJs.formatStrTime(dateString);
    },
    onChangeExEndTime(value, dateString) {
      this.exportInfo.exportEndTime = this.$commJs.formatStrTime(dateString);
    },
    handleExStartOpenChange(open) {
      if (!open) {
        this.exportInfo.endExOpen = true;
      }
    },
    handleExEndOpenChange(open) {
      this.exportInfo.endExOpen = open;
    },
    // 导出
    async exportOk() {
      this.$commJs.exportExcel(
        this,
        "/api/v1/admin/export/client/" +
          this.exportInfo.exportStartTime +
          "/" +
          this.exportInfo.exportEndTime,
        "客户资料-" +
          this.exportInfo.exportStartTime +
          "~" +
          this.exportInfo.exportEndTime
      );
      this.exportInfo.exportModel = false;
    },
    // 点击整行显示详情
    handleRowClick(record) {
      this.seeInfo(record)
    }
  },
};
</script>

<style scoped></style>
