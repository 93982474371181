<template>
    <div id="notice">
        <a-row style="margin-bottom: 20px" :gutter="16">
            <a-col :span="2">
                <a-button type="primary" @click="addOrUpdate('添加')">添加公告</a-button>
            </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" bordered :pagination="pagination" rowKey="id">
            <template slot="operation" slot-scope="text,record,index">
                <a-popconfirm
                        v-if="!record.isTop"
                        title="确定要置顶吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="confirmUpdateIsTop(record.id,1)"
                >
                    <a-button size="small" type="primary">置顶</a-button>
                </a-popconfirm>
                <a-popconfirm
                        v-if="record.isTop"
                        title="确定要置顶吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="confirmUpdateIsTop(record.id,0)"
                >
                    <a-button size="small">取消置顶</a-button>
                </a-popconfirm>
                <a-button size="small" type="primary" @click="addOrUpdate('修改',record)">修改</a-button>
                <a-popconfirm
                        title="确定要删除吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="confirmDelete(record.id)"
                >
                    <a-button size="small" type="danger">删除</a-button>
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal :width="800" :visible="addOrUpdateModel" @cancel="addOrUpdateModel = false" :okText="addOrUpdateOption"
                 @ok="sublimeOk">
            <a-form-model :model="form" ref="form" :rules="formRole" :labelAlign="'left'" :label-col="{span: 3}"
                          :wrapper-col="{span:20}">
                <a-form-model-item label="公告内容" ref="content" prop="content">
                    <a-textarea v-model="form.content" :auto-size="{ minRows: 10, maxRows: 15 }"/>
                </a-form-model-item>
                <a-form-model-item label="是否置顶" ref="isTop" prop="isTop">
                    <a-radio-group v-model="form.isTop" :default-value="0">
                        <a-radio :value="1">
                            置顶
                        </a-radio>
                        <a-radio :value="0">
                            不置顶
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    const columns = [
        {
            title: '时间',
            dataIndex: 'createTime',
            scopedSlots: {customRender: 'createTime'},
        },
        {
            title: '公告内容',
            dataIndex: 'content',
            width: '50%',

            scopedSlots: {customRender: 'content'},
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '20%',
            scopedSlots: {customRender: 'operation'}
        }
    ]
    export default {
        name: "notice",
        data() {
            return {
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 100,
                    onChange: (page, pageSize) => {
                        this.getData(page, pageSize)
                    }
                },
                columns,
                data: [],
                addOrUpdateModel: false,
                addOrUpdateOption: '添加',
                form: {
                    id: null,
                    content: null,
                    isTop: 0,
                },
                formRole: {
                    content: [
                        {required: true, message: '公告内容不能为空', trigger: 'blur'},
                    ],
                    status: [
                        {required: true, message: '公告状态不能为空', trigger: 'blur'},
                    ],
                },
            }
        },
        mounted() {
            this.getData(this.pagination.current, this.pagination.pageSize)
        },
        methods: {
            async getData(pageNum, pageSize) {
                const {data} = await this.$axios.get('/api/v1/admin/base/notice/get/' + pageNum + '/' + pageSize)
                this.data = data.data.list
                this.pagination.current = data.data.pageNum
                this.pagination.pageSize = data.data.pageSize
                this.pagination.total = data.data.total
            },
            // 添加或修改
            addOrUpdate(op, record) {
                this.addOrUpdateModel = true
                this.fileList = []
                this.form = {
                    url: '',
                    imgType: undefined,
                    type: undefined,
                }
                this.addOrUpdateOption = op;

                if (op === '修改') {
                    // xxx
                    this.form = record
                    this.form.isTop = record.isTop ? 1 : 0
                }
            },
            sublimeOk() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        if (this.addOrUpdateOption === '添加') {
                            var {data} = await this.$axios.post('/api/v1/admin/base/notice/add', this.form)
                            this.$message.success(data.message)
                            this.addOrUpdateModel = false
                            this.getData(1, this.pagination.pageSize)
                        }else{
                            this.update()
                        }

                    }
                })
            },
            // 删除
            async confirmDelete(id) {
                const {data} = await this.$axios.post('/api/v1/admin/base/notice/delete/'+id)
                this.$message.success(data.message)
                this.getData(1, this.pagination.pageSize)
            },
            // 修改
            async update() {
                const {data} = await this.$axios.post('/api/v1/admin/base/notice/update', this.form)
                this.$message.success(data.message)
                this.addOrUpdateModel = false
                this.getData(1, this.pagination.pageSize)
            },
            // 修改置顶
            async confirmUpdateIsTop(id, isTop) {
                this.form.id = id
                this.form.isTop = isTop
                this.update()
            }
        }
    }
</script>

<style scoped>

</style>
