<template>
  <div id="buyPatent">
    <a-row style="margin-bottom: 20px" :gutter="16">
      <a-col :span="5">
        <span style="font-size: 16px;">报表类型：</span>
        <a-select default-value="1" style="width: 150px" @change="selectBusinessType">
          <a-select-option value="1">
            日报表
          </a-select-option>
          <a-select-option value="2">
            月报表
          </a-select-option>
          <a-select-option value="3">
            年报表
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="2">
        <a-button @click="exportInfo.exportModel = true">导出</a-button>
      </a-col>
    </a-row>
    <a-table :columns="columns" :data-source="data" bordered :pagination="pagination" rowKey="id">
    </a-table>
    <a-modal v-model="exportInfo.exportModel" @ok="exportOk" :destroyOnClose="true">
      <p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">请选择导出的报表类型：</p>
      <a-select default-value="1" style="width: 150px" @change="selectBusinessType">
        <a-select-option value="1">
          日报表
        </a-select-option>
        <a-select-option value="2">
          月报表
        </a-select-option>
        <a-select-option value="3">
          年报表
        </a-select-option>
      </a-select>
      <p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">请选择导出的时间：</p>
      <a-date-picker
              v-model="exportInfo.exportStartValue"
              :disabled-date="disabledExStartDate"
              format="YYYY-MM-DD 00:00:00"
              placeholder="开始时间"
              style="margin-right: 10px"
              @change="onChangeExStartTime"
              @openChange="handleExStartOpenChange"
      />
      <a-date-picker
              v-model="exportInfo.exportEndValue"
              :disabled-date="disabledExEndDate"
              format="YYYY-MM-DD 23:59:59"
              placeholder="结束时间"
              :open="exportInfo.endExOpen"
              @change="onChangeExEndTime"
              @openChange="handleExEndOpenChange"
      />
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: '时间',
    dataIndex: 'time',
    scopedSlots: {customRender: 'time'},
  },
  {
    title: '出售总专利数量',
    dataIndex: 'sellTotalAmount',
    scopedSlots: {customRender: 'sellTotalAmount'},
  },
  {
    title: '成交金额',
    dataIndex: 'money',
    scopedSlots: {customRender: 'money'},
  },
  {
    title: '佣金',
    dataIndex: 'commission',
    scopedSlots: {customRender: 'commission'},
  },
  {
    title: '结算价',
    dataIndex: 'settlement',
    scopedSlots: {customRender: 'settlement'},
  },
  {
    title: '收购专利数量',
    dataIndex: 'buyPatentAmount',
    scopedSlots: {customRender: 'buyPatentAmount'},
  },
  {
    title: '收购价格',
    dataIndex: 'buyMoney',
    scopedSlots: {customRender: 'buyMoney'},
  },
]
export default {
  name: "buyPatent",
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize)
        }
      },
      exportInfo: {
        exportModel: false,
        exportEndValue: null,
        endExOpen: false,
        exportStartValue: null,
        exportStartTime: null,
        exportEndTime: null,
      },
      columns,
      data: [],
      type: 1,
    }
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize)
  },
  methods: {
    async getData(pageNum, pageSize) {
      const {data} = await this.$axios.get('/api/v1/admin/report/buy/sell/patent/get/' + this.type + '/' + pageNum + '/' + pageSize)
      this.data = data.data.list
      this.pagination.current = data.data.pageNum
      this.pagination.pageSize = data.data.pageSize
      this.pagination.total = data.data.total
    },
    sublimeOk() {
    },
    // 删除
    async confirmDelete(id) {

    },
    selectBusinessType(value) {
      if (value === '0') {
        this.type = null
      } else {
        this.type = value
      }
      this.getData(1, this.pagination.pageSize)
    },
    //导出
    disabledExStartDate(startValue) {
      const endValue = this.exportInfo.exportEndValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledExEndDate(endValue) {
      const startValue = this.exportInfo.exportStartValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    onChangePayTime(value, dateString) {
      this.form.payTime = this.$commJs.formatStrTime(dateString)
    },
    onChangeExStartTime(value, dateString) {
      this.exportInfo.exportStartTime = this.$commJs.formatStrTime(dateString)
    },
    onChangeExEndTime(value, dateString) {
      this.exportInfo.exportEndTime = this.$commJs.formatStrTime(dateString)
    },
    handleExStartOpenChange(open) {
      if (!open) {
        this.exportInfo.endExOpen = true;
      }
    },
    handleExEndOpenChange(open) {
      this.exportInfo.endExOpen = open;
    },
    // 导出
    async exportOk() {
      this.$commJs.exportExcel(this, '/api/v1/admin/export/buy/sell/patent/' + this.type + '/' + this.exportInfo.exportStartTime + '/' + this.exportInfo.exportEndTime,
              "买卖专利报表-" + this.exportInfo.exportStartTime + "~" + this.exportInfo.exportEndTime)
      this.exportInfo.exportModel = false
    },
  }
}
</script>

<style scoped>

</style>
