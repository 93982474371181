<template>
	<div id="copyright">
		<a-row :gutter="16" style="margin-bottom: 10px">
			<a-col :span="3">
				<a-button type="primary" @click="addOrUpdate('添加')">
					版权录入
				</a-button>
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入订单号" v-model="search.orderNumber" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入用户姓名" v-model="search.userName" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入用户手机号" v-model="search.userPhone" />
			</a-col>
			<a-col :span="2">
				<a-button type="primary" @click="getData(1, pagination.pageSize)"
					>搜索</a-button
				>
			</a-col>
		</a-row>
		<a-row style="margin-bottom: 20px">
			<a-col :span="5" :offset="3">
				<a-input placeholder="输入客户名称" v-model="search.clientName" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入商标名称" v-model="search.trademarkName" />
			</a-col>
			<a-col :span="4" :offset="1">
				<span style="font-size: 16px;">状态：</span>
				<a-select
					default-value="0"
					style="width: 150px"
					@change="selectBusinessType"
				>
					<a-select-option value="0">
						全部
					</a-select-option>
					<a-select-option value="1">
						申请中
					</a-select-option>
					<a-select-option value="2">
						已通过
					</a-select-option>
					<a-select-option value="3">
						已驳回
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="4">
				<span style="font-size: 16px;">员工：</span>
				<a-select
					default-value="0"
					style="width: 150px"
					@change="selectPatentType"
				>
					<a-select-option value="0">
						全部
					</a-select-option>
					<a-select-option v-for="i in allSales" :key="i" :value="i">
						{{ i }}
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="1">
				<a-button @click="exportInfo.exportModel = true">导出</a-button>
			</a-col>
		</a-row>
		<a-table
			:columns="columns"
			:data-source="data"
			bordered
			:pagination="pagination"
			rowKey="id"
			@rowClick="handleRowClick"
		>
			<template slot="logo" slot-scope="text, record, index">
				<a
					href="javascript:void(0)"
					@click.stop="
						previewVisible = true;
						previewImage = text;
					"
				>
					<img alt="example" style="width: 20%" :src="text" />
				</a>
			</template>
			<template slot="status" slot-scope="text, record, index">
				<template v-if="text === 1">
					申请中
				</template>
				<template v-if="text === 2">
					已通过
				</template>
				<template v-if="text === 3">
					已驳回
				</template>
			</template>
			<template slot="operation" slot-scope="text, record, index">
				<a-button
					size="small"
					type="primary"
					@click.stop="seeDowData(record.id, 1, download.pagination.pageSize)"
				>
					查看资料
				</a-button>
				<a-button size="small" @click.stop="addOrUpdate('修改', record)"
					>修改资料</a-button
				>
				<a-popconfirm
					title="确定要删除吗?"
					ok-text="确定"
					cancel-text="取消"
					@confirm="deleteFunc(record.id)"
				>
					<a-button size="small" type="danger" @click.stop>删除</a-button>
				</a-popconfirm>
			</template>
		</a-table>
		<!--  添加或修改  -->
		<a-modal
			:width="700"
			:visible="addOrUpdateModel"
			@cancel="addOrUpdateModel = false"
			:okText="addOrUpdateOption"
			@ok="sublimeOk"
		>
			<a-form-model
				:model="form"
				ref="form"
				:rules="formRole"
				labelAlign="right"
				:label-col="{ span: 4 }"
				:wrapper-col="{ span: 19 }"
			>
				<a-form-model-item
					label="选择客户"
					ref="clientPhone"
					prop="clientPhone"
				>
					<a-select
						style="width: 200px"
						v-model="form.clientPhone"
						@change="chooseClient"
						show-search
						:filterOption="filterOption"
						@search="getClienInfo"
					>
						<a-select-option
							v-for="i in clientInfo"
							:key="i.clientPhone"
							:value="i.clientPhone"
						>
							{{ i.clientName }}-{{ i.clientPhone }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="关联用户" ref="userName" prop="userName">
					<a-input v-model="form.userName" disabled />
				</a-form-model-item>
				<a-form-model-item label="版权名称" ref="name" prop="name">
					<a-input v-model="form.name" />
				</a-form-model-item>
				<a-form-model-item label="申请日期">
					<a-date-picker
						v-model="form.declareTime"
						format="YYYY-MM-DD"
						placeholder="选择时间"
						style="margin-right: 10px"
					/>
				</a-form-model-item>
				<a-form-model-item label="申请状态" ref="payStatus" prop="payStatus">
					<a-select
						default-value="0"
						style="width: 150px"
						v-model="form.status"
					>
						<a-select-option :value="1">
							申请中
						</a-select-option>
						<a-select-option :value="2">
							已通过
						</a-select-option>
						<a-select-option :value="3">
							已驳回
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="官费金额" ref="money" prop="money">
					<a-input v-model="form.money" type="number" />
				</a-form-model-item>
				<a-form-model-item label="缴费日期">
					<a-date-picker
						v-model="form.payTime"
						format="YYYY-MM-DD"
						placeholder="选择时间"
						style="margin-right: 10px"
						@change="onChangePayTime"
					/>
				</a-form-model-item>
				<a-form-model-item label="员工">
					<a-select
						style="width: 150px"
						v-model="form.salesName"
						@change="selectSalesChange"
					>
						<a-select-option
							v-for="i in totalSales"
							:key="i.id"
							:value="i.name"
						>
							{{ i.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="上传资料">
					<a-upload
						action="/api/v1/common/upload/file?type=5&uploadTypeId=8"
						:headers="{ Authorization: 'Bearer ' + $store.state.tokenValue }"
						:file-list="fileList"
						@change="handleChangeFileInfo"
						:multiple="true"
					>
						<a-button>
							<a-icon type="upload" />
							点击上传
						</a-button>
					</a-upload>
				</a-form-model-item>
				<a-form-model-item label="备注" prop="remark">
					<a-input v-model="form.remark" type="textarea" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!--  查看图片  -->
		<a-modal
			:visible="previewVisible"
			@cancel="previewVisible = false"
			:footer="null"
			:width="800"
			:z-index="9999"
		>
			<img alt="example" style="width: 100%" :src="previewImage" />
		</a-modal>
		<!--  查看资料模态框  -->
		<a-modal
			:width="700"
			:visible="download.seeDowModel"
			@cancel="download.seeDowModel = false"
			okText="确定"
			@ok="download.seeDowModel = false"
		>
			<a-table
				:columns="download.columns"
				:data-source="download.data"
				bordered
				:pagination="download.pagination"
				rowKey="id"
			>
				<template slot="fileName" slot-scope="text, record, index">
					{{ text }}
					<a
						href="javascript:void(0)"
						v-if="record.isImg"
						@click="
							previewVisible = true;
							previewImage = record.url;
						"
					>
						<img alt="example" style="width: 20%" :src="record.url" />
					</a>
				</template>
				<template slot="operation" slot-scope="text, record, index">
					<a
						:download="record.fileNmame"
						:href="record.url"
						v-if="!record.isImg"
						>下载</a
					>
					<a-popconfirm
						style="z-index: 99999"
						title="确定要删除吗?"
						ok-text="确定"
						cancel-text="取消"
						@confirm="deleteFile(record.url)"
					>
						<a-button type="link">删除</a-button>
					</a-popconfirm>
				</template>
			</a-table>
		</a-modal>
		<!-- 导出 -->
		<a-modal
			v-model="exportInfo.exportModel"
			@ok="exportOk"
			:destroyOnClose="true"
		>
			<p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">
				请选择导出的时间：
			</p>
			<a-date-picker
				v-model="exportInfo.exportStartValue"
				:disabled-date="disabledExStartDate"
				format="YYYY-MM-DD 00:00:00"
				placeholder="开始时间"
				style="margin-right: 10px"
				@change="onChangeExStartTime"
				@openChange="handleExStartOpenChange"
			/>
			<a-date-picker
				v-model="exportInfo.exportEndValue"
				:disabled-date="disabledExEndDate"
				format="YYYY-MM-DD 23:59:59"
				placeholder="结束时间"
				:open="exportInfo.endExOpen"
				@change="onChangeExEndTime"
				@openChange="handleExEndOpenChange"
			/>
		</a-modal>
	</div>
</template>

<script>
	const columns = [
		{
			title: '系统编号',
			dataIndex: 'orderNumber',
			scopedSlots: { customRender: 'orderNumber' }
		},
		{
			title: '用户姓名',
			dataIndex: 'userName',
			scopedSlots: { customRender: 'userName' }
		},
		{
			title: '用户手机号',
			dataIndex: 'userPhone',
			scopedSlots: { customRender: 'userPhone' }
		},
		{
			title: '客户名称',
			dataIndex: 'clientName',
			scopedSlots: { customRender: 'clientName' }
		},
		{
			title: '客户手机号',
			dataIndex: 'clientPhone',
			scopedSlots: { customRender: 'clientPhone' }
		},
		{
			title: '版权名称',
			dataIndex: 'name',
			scopedSlots: { customRender: 'name' }
		},
		{
			title: '申请日期',
			dataIndex: 'declareTime',
			scopedSlots: { customRender: 'declareTime' }
		},
		{
			title: '状态',
			dataIndex: 'status',
			scopedSlots: { customRender: 'status' }
		},
		{
			title: '官费',
			dataIndex: 'money',
			scopedSlots: { customRender: 'money' }
		},
		{
			title: '缴费日期',
			dataIndex: 'payTime',
			scopedSlots: { customRender: 'payTime' }
		},
		{
			title: '员工',
			dataIndex: 'salesName',
			scopedSlots: { customRender: 'salesName' }
		},
		{
			title: '员工手机号',
			dataIndex: 'salesPhone',
			scopedSlots: { customRender: 'salesPhone' }
		},
		{
			title: '操作',
			dataIndex: 'operation',
			key: 'operation',
			width: '15%',
			scopedSlots: { customRender: 'operation' }
		}
	];

	export default {
		name: 'copyright',
		data() {
			return {
				download: {
					seeDowModel: false,
					columns: [
						{
							title: '资料名称',
							dataIndex: 'fileName',
							scopedSlots: { customRender: 'fileName' }
						},
						{
							title: '操作',
							dataIndex: 'operation',
							key: 'operation',
							width: '25%',
							scopedSlots: { customRender: 'operation' }
						}
					],
					data: [
						{
							id: 'adadas'
						}
					],
					pagination: {
						current: 1,
						pageSize: 10,
						total: 100,
						onChange: (page, pageSize) => {
							this.getData(page, pageSize);
						}
					}
				},
				exportInfo: {
					exportModel: false,
					exportEndValue: null,
					endExOpen: false,
					exportStartValue: null,
					exportStartTime: null,
					exportEndTime: null
				},
				pagination: {
					current: 1,
					pageSize: 10,
					total: 100,
					onChange: (page, pageSize) => {
						this.getData(page, pageSize);
					}
				},
				search: {
					orderNumber: null,
					clientName: null,
					userName: null,
					userPhone: null,
					trademarkName: null,
					status: null,
					salesName: null,
					endTime: null,
					startTime: null,
					endOpen: false
				},
				allSales: [],
				totalSales: [],
				clientInfo: [],
				columns,
				data: [],
				form: {
					clientName: null,
					clientPhone: null,
					salesName: null,
					salesPhone: null,
					userName: null,
					userPhone: null,
					payTime: null,
					declareType: null,
					againPassword: null,
					money: null,
					name: null,
					status: null,
					declareTime: null,
					fileInfo: null,
					remark: null
				},
				addOrUpdateModel: false,
				seeInfoData: {},
				seeInfoModel: false,
				addOrUpdateOption: '添加',
				formRole: {
					clientName: [
						{ required: true, message: '客户不能为空', trigger: 'blur' }
					],
					name: [
						{ required: true, message: '商标名称不能为空', trigger: 'blur' }
					],
					declareType: [
						{ required: true, message: '申报类目不能为空', trigger: 'blur' }
					],
					money: [
						{ required: true, message: '官费金额不能为空', trigger: 'blur' }
					],
					againPassword: [
						{ required: true, message: '客户类型不能为空', trigger: 'blur' }
					],
					sales: [{ required: true, message: '员工不能为空', trigger: 'blur' }],
					status: [
						{ required: true, message: '申请状态不能为空', trigger: 'blur' }
					]
				},
				previewVisible: false,
				previewImage: null,
				fileList: []
			};
		},
		mounted() {
			this.getData(this.pagination.current, this.pagination.pageSize);
			this.getAllSales();
			this.getClienInfo();
			this.getTotalSales();
		},
		methods: {
			async getData(pageNum, pageSize) {
				const { data } = await this.$axios.get(
					'/api/v1/admin/proxy/copyright/get/' + pageNum + '/' + pageSize,
					{
						params: {
							orderNumber: this.search.orderNumber,
							clientName: this.search.clientName,
							userName: this.search.userName,
							userPhone: this.search.userPhone,
							copyrightName: this.search.copyrightName,
							status: this.search.status,
							salesName: this.search.salesName,
							startTime: this.search.startTime,
							endTime: this.search.endTime
						}
					}
				);
				this.data = data.data.list;
				this.pagination.current = data.data.pageNum;
				this.pagination.pageSize = data.data.pageSize;
				this.pagination.total = data.data.total;
			},
			// 查询员工
			async getAllSales() {
				const { data } = await this.$axios.get(
					'/api/v1/admin/proxy/copyright/get/salesName'
				);
				this.allSales = data.data;
			},
			async getTotalSales() {
				const { data } = await this.$axios.get(
					'/api/v1/admin/info/sales/get/1/100000'
				);
				this.totalSales = data.data.list;
			},
			// 查询客户信息
			async getClienInfo(v) {
				if (v != undefined) {
					var { data } = await this.$axios.get(
						'/api/v1/common/search/3/1/100000',
						{
							params: {
								search: v
							}
						}
					);
				} else {
					var { data } = await this.$axios.get(
						'/api/v1/common/search/3/1/100000'
					);
				}
				this.clientInfo = data.data.list;
			},
			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				);
			},
			selectBusinessType(value) {
				if (value === '0') {
					this.search.status = null;
				} else {
					this.search.status = value;
				}
				this.getData(1, this.pagination.pageSize);
			},
			selectPatentType(value) {
				if (value === '0') {
					this.search.salesName = null;
				} else {
					this.search.salesName = value;
				}
				this.getData(1, this.pagination.pageSize);
			},
			// 查看信息
			seeInfo(record) {
				this.seeInfoModel = true;
				this.seeInfoData = record;
			},
			// 查看记录
			seeLog(id) {
				this.log.model = true;
			},
			// 添加或修改
			addOrUpdate(op, record) {
				console.log(record);
				this.addOrUpdateModel = true;
				this.form = {
					clientNature: null,
					phone: null,
					linkPhone: null,
					clientType: null,
					remark: null
				};
				this.fileList = [];
				this.addOrUpdateOption = op;
				if (op === '修改') {
					this.form = { ...record };
					if (this.form.fileInfo) {
						this.fileList = record.fileInfo.split(',').map((item) => {
							return {
								uid: item.split('/').at(-1) + Math.random(),
								name: item.split('/').at(-1),
								status: 'done',
								response: {
									data: item
								},
								url: item
							};
						});
					}
				}
			},
			async deleteFunc(id) {
				const { data } = await this.$axios.post(
					'/api/v1/admin/proxy/copyright/delete',
					{
						id: id
					}
				);
				this.$message.success('删除成功！');
				this.getData(this.pagination.current, this.pagination.pageSize);
			},
			// 选择客户后
			chooseClient(v) {
				for (let i = 0; i < this.clientInfo.length; i++) {
					let clientInfoElement = this.clientInfo[i];
					if (clientInfoElement.clientPhone === v) {
						this.form.userPhone = clientInfoElement.phone;
						this.form.userName = clientInfoElement.userName;
						this.form.clientName = clientInfoElement.clientName;
					}
				}
			},
			sublimeOk() {
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						this.form.fileInfo = this.fileList
							.map((item) => item.response.data)
							.join(',');
						this.form.urls = this.fileList
							.map((item) => item.response.data)
							.join(',');
						if (this.addOrUpdateOption === '添加') {
							var { data } = await this.$axios.post(
								'/api/v1/admin/proxy/copyright/add',
								this.form
							);
						} else {
							var { data } = await this.$axios.post(
								'/api/v1/admin/proxy/copyright/update',
								this.form
							);
						}
						this.$message.success(data.message);
						this.addOrUpdateModel = false;
						this.getData(1, this.pagination.pageSize);
					}
				});
			},
			// 查看下载资料
			async seeDowData(id, pageNum, pageSize) {
				const { data } = await this.$axios.get(
					'/api/v1/common/get/file/' + id + '/5/' + pageNum + '/' + pageSize
				);
				this.download.data = data.data.list;
				this.download.pagination.current = data.data.pageNum;
				this.download.pagination.pageSize = data.data.pageSize;
				this.download.pagination.total = data.data.total;
				this.download.seeDowModel = true;
			},
			selectSalesChange(v) {
				for (let i = 0; i < this.totalSales.length; i++) {
					let totalSale = this.totalSales[i];
					if (totalSale.name === v) {
						this.form.salesName = v;
						this.form.salesPhone = totalSale.phone;
					}
				}
			},
			//导出
			disabledExStartDate(startValue) {
				const endValue = this.exportInfo.exportEndValue;
				if (!startValue || !endValue) {
					return false;
				}
				return startValue.valueOf() > endValue.valueOf();
			},
			disabledExEndDate(endValue) {
				const startValue = this.exportInfo.exportStartValue;
				if (!endValue || !startValue) {
					return false;
				}
				return startValue.valueOf() >= endValue.valueOf();
			},
			onChangePayTime(value, dateString) {
				this.form.payTime = this.$commJs.formatStrTime(dateString);
			},
			onChangeExStartTime(value, dateString) {
				this.exportInfo.exportStartTime = this.$commJs.formatStrTime(
					dateString
				);
			},
			onChangeExEndTime(value, dateString) {
				this.exportInfo.exportEndTime = this.$commJs.formatStrTime(dateString);
			},
			handleExStartOpenChange(open) {
				if (!open) {
					this.exportInfo.endExOpen = true;
				}
			},
			handleExEndOpenChange(open) {
				this.exportInfo.endExOpen = open;
			},
			// 导出
			async exportOk() {
				this.$commJs.exportExcel(
					this,
					'/api/v1/admin/export/copyright/' +
						this.exportInfo.exportStartTime +
						'/' +
						this.exportInfo.exportEndTime,
					'版权信息-' +
						this.exportInfo.exportStartTime +
						'~' +
						this.exportInfo.exportEndTime
				);
				this.exportInfo.exportModel = false;
			},
			//  删除文件
			async deleteFile(url) {
				this.$commJs.deleteFile(this, url);
			},
			// 点击整行显示详情
			handleRowClick(record) {
				this.seeDowData(record.id, 1, this.download.pagination.pageSize);
			},
			// 上传资料
			handleChangeFileInfo({ fileList }) {
				this.fileList = fileList;
			}
		}
	};
</script>

<style scoped></style>
