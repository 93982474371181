<template>
  <div id="news">
    <a-row style="margin-bottom: 20px" :gutter="16">
      <a-col :span="2">
        <a-button type="primary" @click="addOrUpdate('添加')"
          >添加资讯</a-button
        >
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="pagination"
      rowKey="id"
    >
      <template slot="icon" slot-scope="text, record, index">
        <a
          href="javascript:void(0)"
          @click="
            previewVisible = true;
            previewImage = text;
          "
        >
          <img alt="example" style="width: 20%" :src="text" />
        </a>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <a-button
          size="small"
          type="primary"
          @click.stop="addOrUpdate('修改', record)"
          >查看/修改</a-button
        >
        <a-popconfirm
          title="确定要删除吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirmDelete(record.id)"
        >
          <a-button size="small" type="danger">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      :width="1000"
      :visible="addOrUpdateModel"
      @cancel="addOrUpdateModel = false"
      :okText="addOrUpdateOption"
      @ok="sublimeOk"
    >
      <a-form-model
        :model="form"
        ref="form"
        :rules="formRole"
        :labelAlign="'left'"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 20 }"
      >
        <a-form-model-item label="资讯图标">
          <a-upload
            action="/api/v1/common/upload/file?type=12&uploadTypeId=12"
            :headers="{ Authorization: 'Bearer ' + $store.state.tokenValue }"
            list-type="picture-card"
            :file-list="icon"
            @preview="handlePreview"
            @change="handleChange"
            :remove="removeFile"
          >
            <div v-if="icon.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="资讯主题" ref="title" prop="title">
          <a-textarea
            v-model="form.title"
            :auto-size="{ minRows: 5, maxRows: 15 }"
          />
        </a-form-model-item>
        <a-form-model-item label="资讯内容" ref="content" prop="content">
          <quill-editor
            style="width: 800px;margin: 0 auto"
            v-model="form.content"
            ref="myQuillEditor"
            :options="editorOption"
          >
          </quill-editor>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="previewVisible"
      @cancel="previewVisible = false"
      :footer="null"
      :width="800"
      :z-index="9999"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "图标预览",
    dataIndex: "icon",
    width: "25%",
    scopedSlots: { customRender: "icon" },
  },
  {
    title: "主题",
    dataIndex: "title",
    width: "30%",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: "20%",
    scopedSlots: { customRender: "operation" },
  },
];
import { quillEditor } from "vue-quill-editor";
import { quillRedefine } from "vue-quill-editor-upload";

const toolbarTips = [
  { Choice: ".ql-bold", title: "加粗" },
  { Choice: ".ql-italic", title: "倾斜" },
  { Choice: ".ql-underline", title: "下划线" },
  { Choice: ".ql-header", title: "段落格式" },
  { Choice: ".ql-strike", title: "删除线" },
  { Choice: ".ql-blockquote", title: "块引用" },
  { Choice: ".ql-code-block", title: "插入代码段" },
  { Choice: ".ql-size", title: "字体大小" },
  { Choice: '.ql-list[value="ordered"]', title: "编号列表" },
  { Choice: '.ql-list[value="bullet"]', title: "项目列表" },
  { Choice: '.ql-header[value="1"]', title: "h1" },
  { Choice: '.ql-header[value="2"]', title: "h2" },
  { Choice: ".ql-align", title: "对齐方式" },
  { Choice: ".ql-color", title: "字体颜色" },
  { Choice: ".ql-background", title: "背景颜色" },
  { Choice: ".ql-image", title: "图像" },
  { Choice: ".ql-video", title: "视频" },
  { Choice: ".ql-link", title: "添加链接" },
  { Choice: ".ql-formula", title: "插入公式" },
  { Choice: ".ql-clean", title: "清除格式" },
  { Choice: '.ql-indent[value="-1"]', title: "向左缩进" },
  { Choice: '.ql-indent[value="+1"]', title: "向右缩进" },
  { Choice: ".ql-header .ql-picker-label", title: "标题大小" },
  { Choice: '.ql-header .ql-picker-item[data-value="1"]', title: "标题一" },
  { Choice: '.ql-header .ql-picker-item[data-value="2"]', title: "标题二" },
  { Choice: '.ql-header .ql-picker-item[data-value="3"]', title: "标题三" },
  { Choice: '.ql-header .ql-picker-item[data-value="4"]', title: "标题四" },
  { Choice: '.ql-header .ql-picker-item[data-value="5"]', title: "标题五" },
  { Choice: '.ql-header .ql-picker-item[data-value="6"]', title: "标题六" },
  { Choice: ".ql-header .ql-picker-item:last-child", title: "标准" },
  { Choice: '.ql-size .ql-picker-item[data-value="small"]', title: "小号" },
  { Choice: '.ql-size .ql-picker-item[data-value="large"]', title: "大号" },
  { Choice: '.ql-size .ql-picker-item[data-value="huge"]', title: "超大号" },
  { Choice: ".ql-size .ql-picker-item:nth-child(2)", title: "标准" },
  { Choice: ".ql-align .ql-picker-item:first-child", title: "居左对齐" },
  {
    Choice: '.ql-align .ql-picker-item[data-value="center"]',
    title: "居中对齐",
  },
  {
    Choice: '.ql-align .ql-picker-item[data-value="right"]',
    title: "居右对齐",
  },
  {
    Choice: '.ql-align .ql-picker-item[data-value="justify"]',
    title: "两端对齐",
  },
];
export default {
  name: "news",
  components: { quillEditor, quillRedefine },
  data() {
    return {
      toolbarTips,
      editorOption: {
        theme: "snow",
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize);
        },
      },
      columns,
      data: [],
      previewVisible: false,
      previewImage: null,
      addOrUpdateModel: false,
      addOrUpdateOption: "添加",
      icon: [],
      form: {
        title: null,
        content: null,
        icon: null,
      },
      formRole: {
        content: [
          { required: true, message: "资讯内容不能为空", trigger: "blur" },
        ],
        title: [
          { required: true, message: "资讯主题不能为空", trigger: "blur" },
        ],
        info: [{ required: true, message: "资讯不能为空", trigger: "blur" }],
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  created() {
    this.editorOption = quillRedefine({
      // 图片上传的设置
      uploadConfig: {
        action: "http://mbadmin.59tv.cn/img/upload", // 必填参数 图片上传地址
        // 必选参数  res是一个函数，函数接收的response为上传成功时服务器返回的数据
        // 你必须把返回的数据中所包含的图片地址 return 回去
        res: (respnse) => {
          return respnse.data.url;
        },
        name: "file", // 图片上传参数名
      },
    });
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize);
  },
  methods: {
    async getData(pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/base/news/get/" + pageNum + "/" + pageSize
      );
      this.data = data.data.list;
      this.pagination.current = data.data.pageNum;
      this.pagination.pageSize = data.data.pageSize;
      this.pagination.total = data.data.total;
    },
    // 添加或修改
    async addOrUpdate(op, record) {
      this.addOrUpdateModel = true;
      setTimeout(() => {
        document.getElementsByClassName("ql-editor")[0].dataset.placeholder =
          "";
        for (let item of this.toolbarTips) {
          let tip = document.querySelector(".quill-editor " + item.Choice);
          if (!tip) continue;
          tip.setAttribute("title", item.title);
        }
      }, 1000);
      this.icon = [];
      this.form = {
        createTime: null,
        content: null,
        info: "",
      };
      var { data } = await this.$axios.get(
        "/api/v1/admin/base/news/get/detail/" + record.id
      );
      this.addOrUpdateOption = op;
      if (op === "修改") {
        // xxx
        this.form = data.data;
        this.icon = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            response: {
              data: record.icon,
            },
            url: record.icon,
          },
        ];
      }
    },
    sublimeOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.form.icon = this.icon[0].response.data;
          if (this.addOrUpdateOption === "添加") {
            var { data } = await this.$axios.post(
              "/api/v1/admin/base/news/add",
              this.form
            );
          } else {
            var { data } = await this.$axios.post(
              "/api/v1/admin/base/news/update",
              this.form
            );
          }
          this.$message.success(data.message);
          this.addOrUpdateModel = false;
          this.getData(1, this.pagination.pageSize);
        }
      });
    },
    // 删除
    async confirmDelete(id) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/base/news/delete/" + id
      );
      this.$message.success(data.message);
      this.getData(1, this.pagination.pageSize);
    },
    async handlePreview(file) {
      this.previewImage = file.response.data;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.icon = fileList;
    },
    removeFile(file) {
      this.$commJs.removeFile(this, file);
    },
    // 点击整行显示详情
    handleRowClick(record) {
      this.addOrUpdate('修改', record)
    }
  },
};
</script>

<style scoped></style>
