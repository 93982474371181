<template>
    <div id="base">
        <a-form-model :model="form" ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :labelAlign="'left'">
            <a-form-model-item label="公司名称">
                <a-input v-model="form.name"/>
            </a-form-model-item>
            <a-form-model-item label="手机号">
                <a-input v-model="form.phone"/>
            </a-form-model-item>
            <a-form-model-item label="邮箱">
                <a-input v-model="form.email"/>
            </a-form-model-item>
            <a-form-model-item label="首页二维码">
                <a-upload
                        action="/api/v1/common/upload/file?type=9&uploadTypeId=9"
                        :headers="{'Authorization':$store.state.tokenValue}"
                        list-type="picture-card"
                        :file-list="qrcode"
                        @preview="qrHandlePreview"
                        @change="qrHandleChange"
                        :remove="removeFile"
                >
                    <div v-if="qrcode.length < 1">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                            上传
                        </div>
                    </div>
                </a-upload>
            </a-form-model-item>
            <a-form-model-item label="网站logo">
                <a-upload
                        action="/api/v1/common/upload/file?type=10&uploadTypeId=10"
                        :headers="{'Authorization':'Bearer '+$store.state.tokenValue}"
                        list-type="picture-card"
                        :file-list="logo"
                        @preview="logoHandlePreview"
                        @change="logoHandleChange"
                        :remove="removeFile"
                >
                    <div v-if="logo.length < 1">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                            上传
                        </div>
                    </div>
                </a-upload>
            </a-form-model-item>
            <a-form-model-item label="网站备案信息">
                <a-input v-model="form.record"/>
            </a-form-model-item>
            <a-form-model-item label="网站备案链接">
                <a-input v-model="form.recordLink"/>
            </a-form-model-item>
            <a-form-model-item label="关于我们">
                <a-input v-model="form.aboutUs"/>
            </a-form-model-item>
            <a-form-model-item label="公司地址">
                <a-textarea v-model="form.address"/>
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" style="text-align:left;">
                <a-button type="primary" @click="onSubmit">
                    保存
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <a-modal :visible="previewVisible" @cancel="previewVisible=false" :footer="null" :width="800" :z-index="9999">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
    </div>
</template>

<script>
    export default {
        name: "base",
        data() {
            return {
                labelCol: {span: 2},
                wrapperCol: {span: 10},
                qrcode: [],
                logo: [],
                previewVisible: false,
                previewImage: null,
                form: {
                    name: null,
                    phone: null,
                    email: null,
                    record: null,
                    recordLink: null,
                    aboutUs: null,
                    address: null,
                    indexCode: null,
                    logo: null
                },
            };
        },
        mounted() {
            this.getAllData()
        },
        methods: {
            async getAllData() {
                const {data} = await this.$axios.get('/api/v1/admin/base/base/get/info')
                let info = data.data;
                for (let i = 0; i < info.length; i++) {
                    let content = info[i].content;
                    if (info[i].type === 1) {
                        this.form.name = content
                    } else if (info[i].type === 2) {
                        this.form.phone = content
                    } else if (info[i].type === 3) {
                        this.form.email = content
                    } else if (info[i].type === 4) {
                        this.qrcode = [
                            {
                                uid: '-1',
                                name: 'image.png',
                                status: 'done',
                                response: {
                                    data: content
                                },
                                url: content,
                            },
                        ]
                    } else if (info[i].type === 5) {
                        this.logo = [
                            {
                                uid: '-1',
                                name: 'image.png',
                                status: 'done',
                                response: {
                                    data: content
                                },
                                url: content,
                            },
                        ]
                    } else if (info[i].type === 6) {
                        this.form.record = content
                    } else if (info[i].type === 7) {
                        this.form.recordLink = content
                    } else if (info[i].type === 10) {
                        this.form.aboutUs = content
                    } else if (info[i].type === 11) {
                        this.form.address = content
                    }
                }
            },
            async onSubmit() {
                this.form.indexCode = this.qrcode[0].response.data
                this.form.logo = this.logo[0].response.data
                const {data} = await this.$axios.post('/api/v1/admin/base/base/update', this.form)
                this.$message.success(data.message)
                this.getAllData()
            },
            async qrHandlePreview(file) {
                this.previewImage = file.response.data
                this.previewVisible = true;
            },
            qrHandleChange({fileList}) {
                this.qrcode = fileList;
            },
            async logoHandlePreview(file) {
                this.previewImage = file.response.data
                this.previewVisible = true;
            },
            logoHandleChange({fileList}) {
                this.logo = fileList;
            },
            removeFile(file) {
                this.$commJs.removeFile(this, file)
            }

        },
    }
</script>

<style scoped>

</style>
