<template>
    <div id="ad">
        <a-row style="margin-bottom: 20px" :gutter="16">
            <a-col :span="4">
                <a-button type="primary" @click="addOrUpdate('添加')">添加广告图</a-button>
            </a-col>
            <a-col :span="4">
                <a-select default-value="0" style="width: 150px" @change="selectType">
                    <a-select-option value="0">
                        全部
                    </a-select-option>
                    <a-select-option value="9">
                        手机广告图
                    </a-select-option>
                    <a-select-option value="8">
                        PC广告图
                    </a-select-option>
                </a-select>
            </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" bordered :pagination="pagination" rowKey="id">
            <template slot="content" slot-scope="text,record,index">
                <a href="javascript:void(0)" @click="previewVisible = true;previewImage = text">
                    <img alt="example" style="width: 10%" :src="text"/>
                </a>
            </template>
            <template slot="linkUrl" slot-scope="text,record,index">
                <a :href="text" target="_blank">
                    {{ text }}
                </a>
            </template>
            <template slot="bannerType" slot-scope="text,record,index">
                <template v-if="text === 1 || text===null">
                    首页
                </template>
                <template v-else-if="text === 2">
                    专利服务
                </template>
                <template v-else-if="text === 3">
                    商标服务
                </template>
                <template v-else-if="text === 4">
                    版权服务
                </template>
                <template v-else-if="text === 5">
                    关于我们
                </template>
                <template v-else-if="text === 6">
                    专利大厅
                </template>
            </template>
            <template slot="type" slot-scope="text,record,index">
                <template v-if="text === 8">
                    PC广告图
                </template>
                <template v-else-if="text === 9">
                    手机广告图
                </template>
            </template>
            <template slot="operation" slot-scope="text,record,index">
                <a-button size="small" type="primary" @click="addOrUpdate('修改',record)">修改</a-button>
                <a-popconfirm
                        title="确定要删除吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="confirmDelete(record.id)"
                >
                    <a-button size="small" type="danger">删除</a-button>
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal :visible="previewVisible" @cancel="previewVisible=false" :footer="null" :width="800" :z-index="9999">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
        <a-modal :visible="addOrUpdateModel" @cancel="addOrUpdateModel = false" :okText="addOrUpdateOption"
                 @ok="sublimeOk">
            <a-form-model :model="form" ref="form" :rules="formRole"
                          :labelAlign="'left'" :label-col="{span: 5}" :wrapper-col="{span:14}">
                <a-form-model-item label="图片类型" ref="type" prop="type">
                    <a-select v-model="form.type" placeholder="请选择图片类别">
                        <a-select-option :value="9">
                            手机广告图
                        </a-select-option>
                        <a-select-option :value="8">
                            PC广告图
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="图片类目" ref="bannerType" prop="bannerType" v-if="form.type === 8">
                    <a-select v-model="form.bannerType" placeholder="请选择图片类目">
                        <a-select-option :value="1">
                            首页
                        </a-select-option>
                        <a-select-option :value="2">
                            专利服务
                        </a-select-option>
                        <a-select-option :value="3">
                            商标服务
                        </a-select-option>
                        <a-select-option :value="4">
                            版权服务
                        </a-select-option>
                        <a-select-option :value="5">
                            关于我们
                        </a-select-option>
                        <a-select-option :value="6">
                            专利大厅
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="图片链接" ref="linkUrl" prop="linkUrl">
                    <a-input v-model="form.linkUrl"/>
                </a-form-model-item>
                <a-form-model-item label="点击上传图片">
                    <a-upload
                            action="/api/v1/common/upload/file?type=11&uploadTypeId=11"
                            :headers="{'Authorization':'Bearer '+$store.state.tokenValue}"
                            list-type="picture-card"
                            :file-list="fileList"
                            @preview="handlePreview"
                            @change="handleChange"
                            :remove="removeFile"
                    >
                        <div v-if="fileList.length < 1">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">
                                <template v-if="form.imgType === 1">
                                    手机图片尺寸
                                </template>
                                <template v-else-if="form.imgType === 2">
                                    PC图片尺寸
                                </template>
                                <template v-else>
                                    请选择广告图位置
                                </template>
                            </div>
                        </div>
                    </a-upload>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    const columns = [
        {
            title: '图片位置',
            dataIndex: 'bannerType',
            scopedSlots: {customRender: 'bannerType'},
        },
        {
            title: '图片',
            dataIndex: 'content',
            width: '20%',

            scopedSlots: {customRender: 'content'},
        },
        {
            title: '图片链接',
            dataIndex: 'linkUrl',
            width: '15%',
            scopedSlots: {customRender: 'linkUrl'},
        },
        {
            title: '图片类目',
            dataIndex: 'type',
            scopedSlots: {customRender: 'type'},
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '20%',
            scopedSlots: {customRender: 'operation'}
        }
    ]
    export default {
        name: "ad",
        data() {
            return {
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 100,
                    onChange: (page, pageSize) => {
                        this.getData(page, pageSize)
                    }
                },
                previewVisible: false,
                previewImage: null,
                type: null,
                columns,
                data: [],
                addOrUpdateModel: false,
                addOrUpdateOption: '添加',
                form: {
                    bannerType: null,
                    content: null,
                    linkUrl: null,
                },
                formRole: {
                    content: [
                        {required: true, message: '图片不能为空', trigger: 'blur'},
                    ],
                    type: [
                        {required: true, message: '图片类目不能为空', trigger: 'blur'},
                    ],
                },
                fileList: [],
            }
        },
        mounted() {
            this.getData(this.pagination.current, this.pagination.pageSize)
        },
        methods: {
            async getData(pageNum, pageSize) {
                const {data} = await this.$axios.get('/api/v1/admin/base/banner/get/' + pageNum + '/' + pageSize,
                    {
                        params: {
                            type: this.type,
                        }
                    }
                )
                this.data = data.data.list
                this.pagination.current = data.data.pageNum
                this.pagination.pageSize = data.data.pageSize
                this.pagination.total = data.data.total
            },
            // 添加或修改
            addOrUpdate(op, record) {
                this.addOrUpdateModel = true
                this.fileList = []
                this.form = {
                    url: '',
                    imgType: undefined,
                    type: undefined,
                }
                this.addOrUpdateOption = op;

                if (op === '修改') {
                    // xxx
                    this.form = record
                    this.fileList = [
                        {
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            response: {
                                data: record.content
                            },
                            url: record.content,
                        },
                    ]
                }
            },
            sublimeOk() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        this.form.content = this.fileList[0].response.data
                        if (this.addOrUpdateOption === '添加') {
                            var {data} = await this.$axios.post('/api/v1/admin/base/banner/add', this.form)
                        }else{
                            var {data} = await this.$axios.post('/api/v1/admin/base/banner/update', this.form)
                        }
                        this.$message.success(data.message)
                        this.addOrUpdateModel = false
                        this.getData(1, this.pagination.pageSize)
                    }
                })
            },
            // 删除
            async confirmDelete(id) {
                const {data} = await this.$axios.post('/api/v1/admin/base/banner/delete/' + id)
                this.$message.success(data.message)
                this.getData(1, this.pagination.pageSize)
            },
            selectType(value) {
                if (value === '0') {
                    this.type = null
                } else {
                    this.type = value
                }
                this.getData(1, this.pagination.pageSize)
            },
            async handlePreview(file) {
                this.previewImage = file.response.data
                this.previewVisible = true;
            },
            handleChange({fileList}) {
                this.fileList = fileList;
            },
            removeFile(file) {
                this.$commJs.removeFile(this, file)
            }
        }
    }
</script>

<style scoped>

</style>
