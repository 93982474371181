<template>
    <div class="index">
        <a-row style="color: #282828;padding: 1% 2% 1% 1%" :gutter="60">
            <a-col :span="18">
                <a-row style="margin-bottom: 40px;padding: 4% 0 4% 0;text-align: center; box-shadow:0 2px 4px rgba(0, 0, 0, .3);">
                    <a-col :span="5">
                        <p style="font-weight: bold;font-size: 48px;">{{info.user}}</p>
                        <p style="font-weight: 500;font-size: 18px">用户数量(人)</p>
                    </a-col>
                    <a-col :span="1">
                        <div
                                style="
                margin-top: 10px;
                padding: 100%;
                line-height: 10px;
                border-left: 2px solid #DADADA;
                text-align: center;"
                        ></div>
                    </a-col>
                    <a-col :span="5">
                        <p style="font-weight: bold;font-size: 48px;">{{info.sales}}</p>
                        <p style="font-weight: 500;font-size: 18px">员工数量(人)</p>
                    </a-col>
                    <a-col :span="1">
                        <div
                                style="
                margin-top: 10px;
                padding: 100%;
                line-height: 10px;
                border-left: 2px solid #DADADA;
                text-align: center;"
                        ></div>
                    </a-col>
                    <a-col :span="5">
                        <p style="font-weight: bold;font-size: 48px;">{{info.cooper}}</p>
                        <p style="font-weight: 500;font-size: 18px">合作方数量(人)</p>
                    </a-col>
                    <a-col :span="1">
                        <div
                                style="
                margin-top: 10px;
                padding: 100%;
                line-height: 10px;
                border-left: 2px solid #DADADA;
                text-align: center;"
                        ></div>
                    </a-col>
                    <a-col :span="5">
                        <p style="font-weight: bold;font-size: 48px;">{{info.client}}</p>
                        <p style="font-weight: 500;font-size: 18px">客户数量(人)</p>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="15">
                        <a-row :gutter="20"
                               style="padding: 8%;text-align: left; box-shadow:0 2px 4px rgba(0, 0, 0, .3);">
                            <a-col :span="12">
                                <p style="font-weight: 500;font-size: 28px;margin-bottom: 2%">已下证专利数量</p>
                                <p style="font-weight: bold;font-size: 38px;margin-left: 25%;margin-bottom: 12%">{{info.underPatent}}
                                    <span style="font-size: 16px;color: #A5A5A5">件</span></p>
                            </a-col>
                            <a-col :span="10" :offset=2>
                                <p style="font-weight: 500;font-size: 28px;margin-bottom: 2%">已出售专利数量</p>
                                <p style="font-weight: bold;font-size: 38px;margin-left: 40%;margin-bottom: 12%">{{info.sellPatent}}
                                    <span style="font-size: 16px;color: #A5A5A5">件</span></p>
                            </a-col>
                        </a-row>
                        <a-row :gutter="20"
                               style="margin-top: 2%;padding: 8%;text-align: left; box-shadow:0 2px 4px rgba(0, 0, 0, .3);">
                            <a-col :span="12">
                                <p style="font-weight: 500;font-size: 28px;margin-bottom: 2%">业务订单数</p>
                                <p style="font-weight: bold;font-size: 38px;margin-left: 25%;margin-bottom: 12%">{{info.businessCasePatent}}
                                    <span style="font-size: 16px;color: #A5A5A5">件</span></p>
                            </a-col>
                            <a-col :span="10" :offset=2>
                                <p style="font-weight: 500;font-size: 28px;margin-bottom: 2%">代理专利数量</p>
                                <p style="font-weight: bold;font-size: 38px;margin-left: 30%;margin-bottom: 0">
                                  <span style="font-size: 16px;color: #A5A5A5">发明</span>
                                  {{info.proxyPatent1!=null?info.proxyPatent1:0}}
                                    <span style="font-size: 16px;color: #A5A5A5">件</span>
                                </p>
                              <p style="font-weight: bold;font-size: 38px;margin-left: 30%;margin-bottom: 0">
                                <span style="font-size: 16px;color: #A5A5A5">实用</span>
                                {{info.proxyPatent2!=null?info.proxyPatent2:0}}
                                <span style="font-size: 16px;color: #A5A5A5">件</span>
                              </p>
                              <p style="font-weight: bold;font-size: 38px;margin-left: 30%;margin-bottom: 0">
                                <span style="font-size: 16px;color: #A5A5A5">外观</span>
                                {{info.proxyPatent3!=null?info.proxyPatent3:0}}
                                <span style="font-size: 16px;color: #A5A5A5">件</span>
                              </p>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8" :offset="1"
                           style="padding: 2% 3%;text-align: left; box-shadow:0 2px 4px rgba(0, 0, 0, .3);">
                        <a-row>
                            <a-col :span="24">
                                <p style="font-weight: 500;font-size: 28px;margin-bottom: 2%">商标数量</p>
                                <p style="font-weight: bold;font-size: 48px;margin-left: 40%;margin-bottom: 12%">{{info.trademark}}
                                    <span style="font-size: 16px;color: #A5A5A5">件</span></p>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="24">
                                <p style="font-weight: 500;font-size: 28px;margin-bottom: 2%">版权数量</p>
                                <p style="font-weight: bold;font-size: 48px;margin-left: 40%;margin-bottom: 12%">{{info.copyright}}
                                    <span style="font-size: 16px;color: #A5A5A5">件</span></p>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="24">
                                <p style="font-weight: 500;font-size: 28px;margin-bottom: 2%">运营专利数量</p>
                                <p style="font-weight: bold;font-size: 48px;margin-left: 40%;margin-bottom: 0">
                                  <span style="font-size: 16px;color: #A5A5A5">发明</span>
                                  {{info.operationPatent1!=null?info.operationPatent1:0}}
                                    <span style="font-size: 16px;color: #A5A5A5">件</span>
                                </p>
                              <p style="font-weight: bold;font-size: 48px;margin-left: 40%;margin-bottom: 0">
                                <span style="font-size: 16px;color: #A5A5A5">实用</span>
                                {{info.operationPatent2!=null?info.operationPatent2:0}}
                                <span style="font-size: 16px;color: #A5A5A5">件</span>
                              </p>
                              <p style="font-weight: bold;font-size: 48px;margin-left: 40%;margin-bottom: 0">
                                <span style="font-size: 16px;color: #A5A5A5">外观</span>
                                {{info.operationPatent3!=null?info.operationPatent3:0}}
                                <span style="font-size: 16px;color: #A5A5A5">件</span>
                              </p>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </a-col>
            <a-col :span="6" style="padding: 4% 3%;text-align: center; box-shadow:0 2px 4px rgba(0, 0, 0, .3);">
                <a-row>
                    <a-col :span="24">
                        <p style="font-weight: bold;font-size: 48px;margin-bottom: 2%">{{info.totalBusinessCasePatent}}<span
                                style="font-size: 16px;color: #A5A5A5">元</span></p>
                        <p style="font-weight: 500;font-size: 22px;margin: 0">累计业务案件金额</p>
                    </a-col>
                </a-row>
                <a-row style="margin:30% 0">
                    <a-col :span="24">
                        <p style="font-weight: bold;font-size: 48px;margin-bottom: 2%">{{info.totalDealMoney}}<span
                                style="font-size: 16px;color: #A5A5A5">元</span></p>
                        <p style="font-weight: 500;font-size: 22px;margin: 0">累计成交金额</p>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <p style="font-weight: bold;font-size: 48px;margin-bottom: 2%">{{info.totalBuyMoney}}<span
                                style="font-size: 16px;color: #A5A5A5">元</span></p>
                        <p style="font-weight: 500;font-size: 22px;margin: 0">累计收购金额</p>
                    </a-col>
                </a-row>
            </a-col>
        </a-row>
    </div>
</template>

<script>
    export default {
        name: 'index',
        data() {
            return {
                info: {}
            }
        },
        mounted() {
            this.getAllInfo()
        },
        methods: {
            async getAllInfo() {
                const {data} = await this.$axios.get('/api/v1/admin/index')
                this.info = data.data
            },
        }
    }
</script>
<style>
    p {
        margin: 0;
        padding: 0;
    }
</style>
