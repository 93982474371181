<template>
    <div id="under">
        <a-row :gutter="16" style="margin-bottom: 10px">
            <a-col :span="2">
                <a-button type="primary" @click="addOrUpdate('添加')">录入下证专利</a-button>
            </a-col>
            <a-col :span="5">
                <a-input placeholder="输入系统编号" v-model="search.orderNumber"/>
            </a-col>
            <a-col :span="5">
                <a-input placeholder="输入所属用户" v-model="search.userName"/>
            </a-col>
            <a-col :span="5">
                <a-input placeholder="输入专利号" v-model="search.patentNumber"/>
            </a-col>
            <a-col :span="5">
                <a-input placeholder="输入专利名称" v-model="search.patentName"/>
            </a-col>
        </a-row>
        <a-row style="margin-bottom: 20px">
            <a-col :span="4">
                <span style="font-size: 16px;">排序：</span>
                <a-select default-value="1" style="width: 150px" @change="selectBusinessType">
                    <a-select-option value="1">
                        时间排序
                    </a-select-option>
                    <a-select-option value="2">
                        销售状态排序
                    </a-select-option>
                    <a-select-option value="3">
                        年费截止日期排序
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col :span="4">
                <span style="font-size: 16px;">年费缴费状态：</span>
                <a-select default-value="0" style="width: 150px" @change="selectPatentType">
                    <a-select-option value="0">
                        全部
                    </a-select-option>
                    <a-select-option value="2">
                        未缴费
                    </a-select-option>
                    <a-select-option value="1">
                        已缴费
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col :span="2" :offset="4">
                <a-button type="primary" @click="getData(1,pagination.pageSize)">搜索</a-button>
            </a-col>
            <a-col :span="1" :offset="6">
                <a-button @click="importInfo.model=true">导入</a-button>
            </a-col>
            <a-col :span="1">
                <a-button @click="exportInfo.exportModel = true">导出</a-button>
            </a-col>
        </a-row>
        <a-table :columns="columns" :data-source="data" bordered :pagination="pagination" rowKey="id">
            <template slot="reviewPayStatus" slot-scope="text,record,index">
                <template v-if="text">
                    已缴费
                </template>
                <template v-else>
                    未缴费
                </template>
            </template>
            <template slot="sellStatus" slot-scope="text,record,index">
                <template v-if="text">
                    已售
                </template>
                <template v-else>
                    未售
                </template>
            </template>
            <template slot="operation" slot-scope="text,record,index">
                <a-button size="small" @click="addOrUpdate('修改',record)">修改</a-button>
                <a-popconfirm
                        v-if="!record.isTransferTransaction"
                        title="确定要转入交易大厅吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="zrJYDT(record.id)"
                >
                    <a-button size="small">转入交易大厅</a-button>
                </a-popconfirm>
                <a-button size="small" type="primary" @click="seeDowData(1,download.pagination.pageSize,record.id)">
                    下载资料
                </a-button>
                <a-button size="small" @click="upload.model=true;upload.fileList = [];seeInfoData=record;upload">上传资料
                </a-button>
              <a-popconfirm
                  title="确定要删除吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="deleteFunc(record.id)"
              >
                <a-button size="small" type="danger">删除</a-button>
              </a-popconfirm>
            </template>
        </a-table>
        <!--  添加或修改  -->
        <a-modal :width="700" :visible="addOrUpdateModel" @cancel="addOrUpdateModel = false" :okText="addOrUpdateOption"
                 @ok="sublimeOk">
            <a-form-model :model="form" ref="form" :rules="formRole" :labelAlign="'left'" :label-col="{span: 4}"
                          :wrapper-col="{span:20}">
                <a-form-model-item label="所属用户" ref="userName" prop="userName">
                    <a-select
                            show-search
                            :value="form.userName"
                            placeholder="输入用户姓名或手机号"
                            :default-active-first-option="false"
                            :show-arrow="false"
                            :filter-option="false"
                            :not-found-content="null"
                            @search="handleSearch"
                            @change="handleChange"
                    >
                        <a-select-option v-for="d in search.userInfo" :key="d.userName">
                            {{ d.userName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="所属手机号" ref="userPhone" prop="userPhone">
                    <a-input v-model="form.userPhone"/>
                </a-form-model-item>
                <a-form-model-item label="专利名称" ref="patentName" prop="patentName">
                    <a-input v-model="form.patentName"/>
                </a-form-model-item>
                <a-form-model-item label="专利号" ref="patentNumber" prop="patentNumber">
                    <a-input v-model="form.patentNumber"/>
                </a-form-model-item>
                <a-form-model-item label="专利类型" ref="clientType" prop="clientType">
                    <a-select default-value="0" style="width: 150px" v-model="form.clientType">
                        <a-select-option value="1">
                            发明
                        </a-select-option>
                        <a-select-option value="2">
                            实用
                        </a-select-option>
                        <a-select-option value="3">
                            外观
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="申请人" ref="applyPeople" prop="applyPeople">
                    <a-input v-model="form.applyPeople"/>
                </a-form-model-item>
                <a-form-model-item label="地区" ref="area" prop="area">
                    <a-input v-model="form.area"/>
                </a-form-model-item>
                <a-form-model-item label="登记情况" ref="registerInfo" prop="registerInfo">
                    <a-input v-model="form.registerInfo"/>
                </a-form-model-item>
                <a-form-model-item label="变更记录" ref="changeInfo" prop="changeInfo">
                    <a-input v-model="form.changeInfo"/>
                </a-form-model-item>
                <a-form-model-item label="年费" ref="yearMoney" prop="yearMoney">
                    <a-input v-model="form.yearMoney" type="number"/>
                </a-form-model-item>
                <a-form-model-item label="年费截止时间" ref="reviewEndTime" prop="reviewEndTime">
                    <a-date-picker
                            v-model="form.reviewEndTime"
                            format="YYYY-MM-DD"
                            placeholder="选择时间"
                            style="margin-right: 10px"
                            @change="onChangePayTime"
                    />
                </a-form-model-item>
                <a-form-model-item label="年费缴费状态" ref="reviewPayStatus" prop="reviewPayStatus">
                    <a-select :default-value="true" style="width: 150px" v-model="form.reviewPayStatus">
                        <a-select-option :value="true">
                            已缴费
                        </a-select-option>
                        <a-select-option :value="false">
                            未缴费
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <!--  查看图片  -->
        <a-modal :visible="previewVisible" @cancel="previewVisible=false" :footer="null" :width="800" :z-index="9999">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
        <!--  查看资料模态框  -->
        <a-modal :width="700" :visible="download.seeDowModel" @cancel="download.seeDowModel = false"
                 okText="确定"
                 @ok="download.seeDowModel = false">
            <a-table :columns="download.columns" :data-source="download.data" bordered :pagination="download.pagination"
                     rowKey="id">
                <template slot="fileName" slot-scope="text,record,index">
                    {{text}}
                    <a href="javascript:void(0)" v-if="record.isImg"
                       @click="previewVisible = true;previewImage = record.url">
                        <img alt="example" style="width: 20%"
                             :src="record.url"/>
                    </a>
                </template>
                <template slot="operation" slot-scope="text,record,index">
                    <a :download="record.fileNmame" :href="record.url" v-if="!record.isImg">下载</a>
                    <a-popconfirm
                            title="确定要删除吗?"
                            ok-text="确定"
                            cancel-text="取消"
                            @confirm="deleteFile(record.url)"
                    >
                        <a-button type="link">删除</a-button>
                    </a-popconfirm>
                </template>
            </a-table>
        </a-modal>
        <!--  上传资料模态框  -->
        <a-modal :width="700" :visible="upload.model" @cancel="upload.model = false" okText="确定"
                 @ok="uploadHandleUpload" :ok-button-props="{ props: { disabled: upload.fileList.length === 0 } }">
            <a-upload :file-list="upload.fileList" :remove="uploadHandleRemove" :before-upload="uploadBeforeUpload"
                      :multiple="true">
                <a-button>
                    <a-icon type="upload"/>
                    点击上传
                </a-button>
            </a-upload>
        </a-modal>
        <!--  导入资料模态框  -->
        <a-modal :width="700" :visible="importInfo.model" @cancel="importInfo.model = false" okText="确定"
                 @ok="importHandleUpload" :ok-button-props="{ props: { disabled: importInfo.fileList.length === 0 } }">
            <a href="/下证专利上传模板.xlsx" download>下载模板</a>
            <a-upload :file-list="importInfo.fileList" :remove="importHandleRemove" :before-upload="importBeforeUpload">
                <a-button :disabled="importInfo.fileList.length >= 1">
                    <a-icon type="upload"/>
                    点击上传
                </a-button>
            </a-upload>
        </a-modal>
        <a-modal v-model="exportInfo.exportModel" @ok="exportOk" :destroyOnClose="true">
            <p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">请选择导出的时间：</p>
            <a-date-picker
                    v-model="exportInfo.exportStartValue"
                    :disabled-date="disabledExStartDate"
                    format="YYYY-MM-DD 00:00:00"
                    placeholder="开始时间"
                    style="margin-right: 10px"
                    @change="onChangeExStartTime"
                    @openChange="handleExStartOpenChange"
            />
            <a-date-picker
                    v-model="exportInfo.exportEndValue"
                    :disabled-date="disabledExEndDate"
                    format="YYYY-MM-DD 23:59:59"
                    placeholder="结束时间"
                    :open="exportInfo.endExOpen"
                    @change="onChangeExEndTime"
                    @openChange="handleExEndOpenChange"
            />
        </a-modal>
    </div>
</template>

<script>
    const columns = [
        {
            title: '系统编号',
            dataIndex: 'orderNumber',
            scopedSlots: {customRender: 'orderNumber'},
        },
        {
            title: '所属用户',
            dataIndex: 'userName',
            scopedSlots: {customRender: 'userName'},
        },
        {
            title: '所属用户手机号',
            dataIndex: 'userPhone',
            scopedSlots: {customRender: 'userPhone'},
        },
        {
            title: '专利名称',
            dataIndex: 'patentName',
            scopedSlots: {customRender: 'patentName'},
        },
        {
            title: '专利号',
            dataIndex: 'patentNumber',
            scopedSlots: {customRender: 'patentNumber'},
        },
        {
            title: '专利类型',
            dataIndex: 'clientTypeDesc',
            scopedSlots: {customRender: 'clientTypeDesc'},
        },
        {
            title: '申请人',
            dataIndex: 'applyPeople',
            scopedSlots: {customRender: 'applyPeople'},
        },
        {
            title: '地区',
            dataIndex: 'area',
            scopedSlots: {customRender: 'area'},
        },
        {
            title: '登记信息',
            dataIndex: 'registerInfo',
            scopedSlots: {customRender: 'registerInfo'},
        },
        {
            title: '变更记录',
            dataIndex: 'changeInfo',
            scopedSlots: {customRender: 'changeInfo'},
        },
        {
            title: '年费',
            dataIndex: 'yearMoney',
            scopedSlots: {customRender: 'yearMoney'},
        },
        {
            title: '年费截止日期',
            dataIndex: 'reviewEndTime',
            scopedSlots: {customRender: 'reviewEndTime'},
        },
        {
            title: '年费缴费状态',
            dataIndex: 'reviewPayStatus',
            scopedSlots: {customRender: 'reviewPayStatus'},
        },
        {
            title: '销售状态',
            dataIndex: 'sellStatus',
            scopedSlots: {customRender: 'sellStatus'},
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '15%',
            scopedSlots: {customRender: 'operation'}
        }
    ]

    export default {
        name: "under",
        data() {
            return {
                download: {
                    seeDowModel: false,
                    columns: [
                        {
                            title: '资料名称',
                            dataIndex: 'fileName',
                            scopedSlots: {customRender: 'fileName'},
                        },
                        {
                            title: '操作',
                            dataIndex: 'operation',
                            key: 'operation',
                            width: '25%',
                            scopedSlots: {customRender: 'operation'}
                        }
                    ],
                    data: [
                        {
                            id: 'adadas',
                        }
                    ],
                    pagination: {
                        current: 1,
                        pageSize: 10,
                        total: 100,
                        onChange: (page, pageSize) => {
                            this.getData(page, pageSize)
                        }
                    },
                },
                upload: {
                    model: false,
                    fileList: [],
                },
                importInfo: {
                    model: false,
                    fileList: [],
                },
                exportInfo: {
                    exportModel: false,
                    exportEndValue: null,
                    endExOpen: false,
                    exportStartValue: null,
                    exportStartTime: null,
                    exportEndTime: null,
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 100,
                    onChange: (page, pageSize) => {
                        this.getData(page, pageSize)
                    }
                },
                search: {
                    orderNumber: null,
                    patentNumber: null,
                    patentName: null,
                    reviewPayStatus: null,
                    orderType: null,
                    userName: null,
                    endTime: null,
                    startTime: null,
                    endOpen: false,
                    userInfo: [],
                },
                userName: null,
                hzNickName: null,
                businessType: null,
                patentType: null,
                idCard: null,
                lxPhone: null,
                lxName: null,
                columns,
                data: [
                ],
                form: {
                    userName: null,
                    userPhone: null,
                    reviewEndTime: null,
                    patentName: null,
                    patentNumber: null,
                    clientType: null,
                    reviewPayStatus: null,
                    changeInfo: null,
                    area: null,
                    yearMoney: null,
                    registerInfo: null,
                    dataSource: [],
                },
                addOrUpdateModel: false,
                seeInfoData: {},
                addOrUpdateOption: '添加',
                formRole: {
                    patentName: [
                        {required: true, message: '专利名称不能为空', trigger: 'blur'},
                    ],
                    patentNumber: [
                        {required: true, message: '专利号不能为空', trigger: 'blur'},
                    ],
                },
                previewVisible: false,
                previewImage: null,
            }
        },
        mounted() {
            this.getData(this.pagination.current, this.pagination.pageSize)
        },
        methods: {
            async getData(pageNum, pageSize) {
                const {data} = await this.$axios.get('/api/v1/admin/operation/under/patent/get/' + pageNum + '/' + pageSize, {
                    params: {
                        orderNumber: this.search.orderNumber,
                        patentNumber: this.search.patentNumber,
                        patentName: this.search.patentName,
                        reviewPayStatus: this.search.reviewPayStatus,
                        orderType: this.search.orderType,
                        userName: this.search.userName,
                        startTime: this.search.startTime,
                        endTime: this.search.endTime,
                    }
                })
                this.data = data.data.list
                this.pagination.current = data.data.pageNum
                this.pagination.pageSize = data.data.pageSize
                this.pagination.total = data.data.total
            },
            selectBusinessType(value) {
                if (value === '0') {
                    this.search.orderType = null
                } else {
                    this.search.orderType = value
                }
                this.getData(1, this.pagination.pageSize)
            },
            selectPatentType(value) {
                if (value === '0') {
                    this.search.reviewPayStatus = null
                } else {
                    this.search.reviewPayStatus = (value === '1')
                }
                this.getData(1, this.pagination.pageSize)
            },
            async handleSearch(value) {
                this.search.userInfo = []
                const {data} = await this.$axios.get('/api/v1/common/search/1/1/99999?search=' + value)
                for (let i = 0; i < data.data.list.length; i++) {
                    this.search.userInfo.push({
                        userName: data.data.list[i].userName,
                        userPhone: data.data.list[i].phone
                    })
                }
            },
            handleChange(value) {
                for (let i = 0; i < this.search.userInfo.length; i++) {
                    let userInfoElement = this.search.userInfo[i];
                    if (value === userInfoElement.userName) {
                        this.form.userPhone = userInfoElement.userPhone
                        this.form.userName = userInfoElement.userName
                    }
                }
            },
            async zrJYDT(id) {
                const {data} = await this.$axios.post('/api/v1/admin/operation/under/patent/transfer/' + id)
                if (data.code === 200) {
                    this.$message.success("操作成功！")
                }
                this.getData(1, this.pagination.pageSize)
            },
            // 查看信息
            seeInfo(record) {
                this.seeInfoModel = true
                this.seeInfoData = record
            },
            // 搜索用户
            async searchUser() {

            },
            // 搜索改变用户
            async onChangeUser(value) {

            },
            // 添加或修改
            addOrUpdate(op, record) {
                this.addOrUpdateModel = true
                this.search.userInfo = []
                this.form = {
                    name: null,
                    userName: null,
                    userPhone: null,
                    reviewEndTime: null,
                    patentName: null,
                    patentNumber: null,
                    clientType: null,
                    reviewPayStatus: null,
                    changeInfo: null,
                    area: null,
                    yearMoney: null,
                    registerInfo: null,
                    dataSource: [],
                }
                this.upload.fileList = []
                this.addOrUpdateOption = op;
                if (op === '修改') {
                    this.form = record
                }
            },
            // 转入交易大厅
            async zrJYDTI(id) {
            },
            // 暂停或启用员工
            setUserAuth(type) {
                this.$message.success("设置成功！")
                this.getData(this.pagination.current, this.pagination.pageSize)
            },
            sublimeOk() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        if (this.addOrUpdateOption === '添加') {
                            var {data} = await this.$axios.post('/api/v1/admin/operation/under/patent/add', this.form)
                        } else {
                            var {data} = await this.$axios.post('/api/v1/admin/operation/under/patent/update', this.form)
                        }
                        this.$message.success(data.message)
                        this.addOrUpdateModel = false
                        this.getData(1, this.pagination.pageSize)
                    }
                })
            },
            // 查看下载资料
            async seeDowData(pageNum, pageSize, id) {
                const {data} = await this.$axios.get('/api/v1/common/get/file/' + id + '/18/' + pageNum + '/' + pageSize)
                this.download.data = data.data.list
                this.download.pagination.current = data.data.pageNum
                this.download.pagination.pageSize = data.data.pageSize
                this.download.pagination.total = data.data.total
                this.download.seeDowModel = true
            },
          async deleteFunc(id) {
            const {data} = await this.$axios.post('/api/v1/admin/operation/under/patent/delete', {
              id: id,
            })
            this.$message.success("删除成功！")
            this.getData(this.pagination.current, this.pagination.pageSize)
          },
            // 上传资料
            uploadHandleRemove(file) {
                const index = this.upload.fileList.indexOf(file);
                const newFileList = this.upload.fileList.slice();
                newFileList.splice(index, 1);
                this.upload.fileList = newFileList;
            },
            uploadBeforeUpload(file) {
                this.upload.fileList = [...this.upload.fileList, file];
                return false;
            },
            async uploadHandleUpload() {
                // 上传代码
                for (let i = 0; i < this.upload.fileList.length; i++) {
                    let file = this.upload.fileList[i]
                    let formData = new FormData();
                    formData.append("file", file);
                    await this.$axios.post('/api/v1/common/upload/file?type=18&uploadTypeId=' + this.seeInfoData.id, formData)
                }
                // 上传代码
                this.upload.fileList = [];
                this.upload.model = false
                this.$message.success("上传成功！")
            },
            //  删除文件
            async deleteFile(url) {
                this.$commJs.deleteFile(this, url)
            },
            // 格式化时间
            disabledStartDate(startValue) {
                const endValue = this.search.endTime;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledEndDate(endValue) {
                const startValue = this.search.startTime;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            onChangeStartTime(value, dateString) {
                this.startTime = this.$commJs.formatStrTime(dateString)
                this.getData(1, this.pagination.pageSize)
            },
            onChangeEndTime(value, dateString) {
                this.endTime = this.$commJs.formatStrTime(dateString)
                this.getData(1, this.pagination.pageSize)
            },
            handleStartOpenChange(open) {
                if (!open) {
                    this.search.endOpen = true;
                }
            },
            handleEndOpenChange(open) {
                this.search.endOpen = open;
            },
            // 导入资料
            importHandleRemove(file) {
                const index = this.importInfo.fileList.indexOf(file);
                const newFileList = this.importInfo.fileList.slice();
                newFileList.splice(index, 1);
                this.importInfo.fileList = newFileList;
            },
            importBeforeUpload(file) {
                this.importInfo.fileList = [...this.importInfo.fileList, file];
                return false;
            },
            async importHandleUpload() {
                // 上传代码
                for (let i = 0; i < this.importInfo.fileList.length; i++) {
                    let file = this.importInfo.fileList[i]
                    let formData = new FormData();
                    formData.append("file", file);
                    await this.$axios.post('/api/v1/admin/operation/under/patent/upload', formData)
                }
                this.importInfo.fileList = [];
                this.importInfo.model = false
                this.$message.success("上传成功！")
                this.getData(1,this.pagination.pageSize)
            },
            //导出
            disabledExStartDate(startValue) {
                const endValue = this.exportInfo.exportEndValue;
                if (!startValue || !endValue) {
                    return false;
                }
                return startValue.valueOf() > endValue.valueOf();
            },
            disabledExEndDate(endValue) {
                const startValue = this.exportInfo.exportStartValue;
                if (!endValue || !startValue) {
                    return false;
                }
                return startValue.valueOf() >= endValue.valueOf();
            },
            onChangePayTime(value, dateString) {
                this.form.payTime = this.$commJs.formatStrTime(dateString)
            },
            onChangeExStartTime(value, dateString) {
                this.exportInfo.exportStartTime = this.$commJs.formatStrTime(dateString)
            },
            onChangeExEndTime(value, dateString) {
                this.exportInfo.exportEndTime = this.$commJs.formatStrTime(dateString)
            },
            handleExStartOpenChange(open) {
                if (!open) {
                    this.exportInfo.endExOpen = true;
                }
            },
            handleExEndOpenChange(open) {
                this.exportInfo.endExOpen = open;
            },
            // 导出
            async exportOk() {
                this.$commJs.exportExcel(this, '/api/v1/admin/export/under/patent/' + this.exportInfo.exportStartTime + '/' + this.exportInfo.exportEndTime,
                    "运营专利-" + this.exportInfo.exportStartTime + "~" + this.exportInfo.exportEndTime)
                this.exportInfo.exportModel = false
            }
            ,
        }
    }
</script>

<style scoped>

</style>
