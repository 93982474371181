<template>
  <div id="cooper">
    <a-row style="margin-bottom: 20px" :gutter="16">
      <a-col :span="3">
        <a-button type="primary" @click="addOrUpdate('添加')">添加合作方</a-button>
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入合作方姓名" v-model="search.name"/>
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入合作方手机号" v-model="search.phone"/>
      </a-col>
      <a-col :span="7">
        <a-button type="primary" @click="getData(1,pagination.pageSize)">搜索</a-button>
      </a-col>
    </a-row>
    <a-table :columns="columns" :data-source="data" bordered :pagination="pagination" rowKey="id">
      <template slot="isAuth" slot-scope="text,record,index">
        <template v-if="record.userPhone === null">
          否
        </template>
        <template v-else>
          是
        </template>
      </template>
      <template slot="status" slot-scope="text,record,index">
        <template v-if="text === 1">
          正常
        </template>
        <template v-else-if="text === 2">
          暂停
        </template>
        <template v-else-if="text === 3">
          未认证
        </template>
      </template>
      <template slot="operation" slot-scope="text,record,index">
        <a-button size="small" type="primary" @click="addOrUpdate('设置密码',record)">设置密码</a-button>
        <a-popconfirm
            v-if="record.status !== 2"
            title="确定要暂停该合作吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="setUserAuth(record.id,2)"
        >
          <a-button size="small">暂停合作</a-button>
        </a-popconfirm>
        <a-popconfirm
            v-if="record.status !== 1"
            title="确定要启用合作吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="setUserAuth(record.id,1)"
        >
          <a-button size="small">启用合作</a-button>
        </a-popconfirm>
        <a-popconfirm
            title="确定要删除吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteFunc(record.id)"
        >
          <a-button size="small" type="danger">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal :width="800" :visible="addOrUpdateModel" @cancel="addOrUpdateModel = false" :okText="addOrUpdateOption"
             @ok="sublimeOk">
      <a-form-model :model="form" ref="form" :rules="formRole" :labelAlign="'left'" :label-col="{span: 3}"
                    :wrapper-col="{span:20}">
        <a-form-model-item label="合作方姓名" ref="name" prop="name" v-if="addOrUpdateOption === '添加'">
          <a-input v-model="form.name"/>
        </a-form-model-item>
        <a-form-model-item label="合作方手机号" ref="phone" prop="phone" v-if="addOrUpdateOption === '添加'">
          <a-input v-model="form.phone"/>
        </a-form-model-item>
        <a-form-model-item label="推荐人姓名" ref="recommendUserName" prop="recommendUserName"
                           v-if="addOrUpdateOption === '添加'">
          <a-input v-model="form.recommendUserName"/>
        </a-form-model-item>
        <a-form-model-item label="推荐人手机号" ref="recommendUserPhone" prop="recommendUserPhone"
                           v-if="addOrUpdateOption === '添加'">
          <a-input v-model="form.recommendUserPhone"/>
        </a-form-model-item>
        <a-form-model-item label="密码" ref="password" prop="password">
          <a-input-password v-model="form.password"/>
        </a-form-model-item>
        <a-form-model-item label="再次输入密码" ref="againPassword" prop="againPassword">
          <a-input-password v-model="form.againPassword"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal :visible="previewVisible" @cancel="previewVisible=false" :footer="null" :width="800" :z-index="9999">
      <img alt="example" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: '系统ID',
    dataIndex: 'id',
    scopedSlots: {customRender: 'id'},
  },
  {
    title: '合作方姓名',
    dataIndex: 'name',
    scopedSlots: {customRender: 'name'},
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    scopedSlots: {customRender: 'phone'},
  },
  {
    title: '用户姓名',
    dataIndex: 'userName',
    scopedSlots: {customRender: 'userName'},
  },
  {
    title: '用户手机号',
    dataIndex: 'userPhone',
    scopedSlots: {customRender: 'userPhone'},
  },
  {
    title: '推荐人',
    dataIndex: 'recommendUserName',
    scopedSlots: {customRender: 'recommendUserName'},
  },
  {
    title: '是否关联用户',
    dataIndex: 'isAuth',
    scopedSlots: {customRender: 'isAuth'},
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: {customRender: 'status'},
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: '15%',
    scopedSlots: {customRender: 'operation'}
  }
]
export default {
  name: "cooper",
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize)
        }
      },
      userName: null,
      hzNickName: null,
      phone: null,
      idCard: null,
      columns,
      data: [],
      form: {
        name: null,
        recommendUserName: null,
        recommendUserPhone: null,
        phone: null,
        password: null,
        againPassword: null,
      },
      addOrUpdateModel: false,
      addOrUpdateOption: '添加',
      formRole: {
        name: [
          {required: true, message: '合作方姓名不能为空', trigger: 'blur'},
        ],
        recommendUserName: [
          {required: true, message: '推荐人姓名不能为空', trigger: 'blur'},
        ],
        recommendUserPhone: [
          {required: true, message: '推荐人手机号不能为空', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '手机号不能为空', trigger: 'blur'},
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
        ],
        againPassword: [
          {required: true, message: '再次输入密码不能为空', trigger: 'blur'},
        ],
      },
      previewVisible: false,
      previewImage: null,
      search: {
        name: null,
        phone: null,
      }
    }
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize)
  },
  methods: {
    async getData(pageNum, pageSize) {
      const {data} = await this.$axios.get('/api/v1/admin/operation/cooper/get/' + pageNum + '/' + pageSize, {
        params: {
          cooperName: this.search.name,
          phone: this.search.phone,
        }
      })
      this.data = data.data.list
      this.pagination.current = data.data.pageNum
      this.pagination.pageSize = data.data.pageSize
      this.pagination.total = data.data.total
    },
    // 添加或修改
    addOrUpdate(op, record) {
      this.addOrUpdateModel = true
      this.form = {
        clientNature: null,
        phone: null,
        linkPhone: null,
        clientType: null,
      }
      this.addOrUpdateOption = op;
      if (op === '设置密码') {
        // xxx
        this.form.name = record.name
        this.form.phone = record.phone
        this.form.id = record.id
      }
    },
    sublimeOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.password !== this.form.againPassword) {
            this.$message.error("两次密码不一致")
            return false
          }
          if (this.addOrUpdateOption === '添加') {
            var {data} = await this.$axios.post('/api/v1/admin/operation/cooper/add', this.form)
          } else if (this.addOrUpdateOption === '设置密码') {
            this.form.type = 2
            var {data} = await this.$axios.post('/api/v1/admin/operation/cooper/update', this.form)
          }
          this.$message.success("操作成功！")
          this.addOrUpdateModel = false
          this.getData(1, this.pagination.pageSize)
        }
      })
    },
    // 暂停或启用合作方
    async setUserAuth(id, status) {
      const {data} = await this.$axios.post('/api/v1/admin/operation/cooper/update', {
        id: id,
        type: 1,
        status: status
      })

      this.$message.success("设置成功！")
      this.getData(this.pagination.current, this.pagination.pageSize)
    },
   async deleteFunc(id) {
     const {data} = await this.$axios.post('/api/v1/admin/operation/cooper/delete', {
       id: id,
     })
     this.$message.success("删除成功！")
     this.getData(this.pagination.current, this.pagination.pageSize)
    },
  }
}
</script>

<style scoped>

</style>
