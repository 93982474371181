<template>
  <div id="transaction">
    <a-row :gutter="16" style="margin-bottom: 10px">
      <a-col :span="5">
        <a-input placeholder="输入系统编号" v-model="search.orderNumber" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入专利号" v-model="search.patentNumber" />
      </a-col>
      <a-col :span="5">
        <a-input placeholder="输入专利名称" v-model="search.patentName" />
      </a-col>
      <a-col :span="3">
        <a-button type="primary" @click="getData(1, pagination.pageSize)"
          >搜索</a-button
        >
      </a-col>
      <a-col :span="1" :offset="4">
        <a-button @click="exportInfo.exportModel = true">导出</a-button>
      </a-col>
    </a-row>
    <a-row style="margin-bottom: 20px">
      <a-col :span="4">
        <span style="font-size: 16px;">专利类型：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectBusinessType"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option value="1">
            发明
          </a-select-option>
          <a-select-option value="2">
            实用
          </a-select-option>
          <a-select-option value="3">
            外观
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4">
        <span style="font-size: 16px;">专利状态：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectPatentStatus"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option value="1">
            已授权
          </a-select-option>
          <a-select-option value="2">
            已下证
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4">
        <span style="font-size: 16px;">合作方选择：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectCooperName"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option v-for="i in userInfo.cooperList" :key="i" :value="i">
            {{ i }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4">
        <span style="font-size: 16px;">所属用户选择：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectUserName"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option
            v-for="i in userInfo.userNameList"
            :key="i"
            :value="i"
          >
            {{ i }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4">
        <span style="font-size: 16px;">购买用户选择：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectBuyUserName"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option
            v-for="i in userInfo.buyUserNameList"
            :key="i"
            :value="i"
          >
            {{ i }}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4">
        <span style="font-size: 16px;">员工选择：</span>
        <a-select
          default-value="0"
          style="width: 150px"
          @change="selectSalesName"
        >
          <a-select-option value="0">
            全部
          </a-select-option>
          <a-select-option v-for="i in userInfo.salesList" :key="i" :value="i">
            {{ i }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="pagination"
      rowKey="id"
      @rowClick="handleRowClick"
    >
      <template slot="operation" slot-scope="text, record, index">
        <a-button size="small" type="primary" @click.stop="seeInfo(record)"
          >查看</a-button
        >
        <a-button
          size="small"
          v-if="record.sellStatus === 1 || record.sellStatus === 4"
          @click.stop="addOrUpdate('进行出售', record)"
          >进行出售
        </a-button>
        <a-popconfirm
          v-if="record.sellStatus === 3"
          title="确定要暂停出售吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="stopSS(record, 4)"
        >
          <a-button size="small" @click.stop>暂停出售</a-button>
        </a-popconfirm>
        <a-button size="small" @click.stop="addOrUpdate('确定售出', record)"
          >已售</a-button
        >
        <a-button
          size="small"
          @click.stop="seeLog(record.id, 1, log.pagination.pageSize)"
          >记录</a-button
        >
        <a-button
          size="small"
          @click.stop="addOrUpdate('立即预定', record)"
          v-if="record.sellStatus !== 2 || record.sellStatus !== 5"
          >专利预定
        </a-button>
        <a-popconfirm
          title="确定要取消预定吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="cancleYD(record)"
        >
          <a-button size="small" @click.stop>取消预定</a-button>
        </a-popconfirm>
        <a-popconfirm
          v-if="record.status !== 2"
          title="确定要删除吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="deleteFunc(record.id)"
        >
          <a-button size="small" type="danger" @click.stop>删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      :visible="seeInfoModel"
      @cancel="seeInfoModel = false"
      :footer="null"
      :width="600"
      :z-index="90"
    >
      <a-descriptions title="业务案件订单" :column="2">
        <a-descriptions-item label="系统订单号">
          {{ seeInfoData.orderNumber }}
        </a-descriptions-item>
        <a-descriptions-item label="合作方名称">
          {{ seeInfoData.cooperName }}
        </a-descriptions-item>
        <a-descriptions-item label="合作方手机号">
          {{ seeInfoData.cooperPhone }}
        </a-descriptions-item>
        <a-descriptions-item label="员工">
          {{ seeInfoData.salesName }}
        </a-descriptions-item>
        <a-descriptions-item label="员工手机号">
          {{ seeInfoData.salesPhone }}
        </a-descriptions-item>
        <a-descriptions-item label="所属用户">
          {{ seeInfoData.userName }}
        </a-descriptions-item>
        <a-descriptions-item label="所属用户手机号">
          {{ seeInfoData.userPhone }}
        </a-descriptions-item>
        <a-descriptions-item label="收购价">
          {{ seeInfoData.buyMoney }}
        </a-descriptions-item>
        <a-descriptions-item label="购买用户">
          {{ seeInfoData.buyUserName }}
        </a-descriptions-item>
        <a-descriptions-item label="购买用户手机号">
          {{ seeInfoData.buyUserPhone }}
        </a-descriptions-item>
        <a-descriptions-item label="定金">
          {{ seeInfoData.depositMoney }}
        </a-descriptions-item>
        <a-descriptions-item label="预定截止日期">
          {{ seeInfoData.scheduledEndTime }}
        </a-descriptions-item>
        <a-descriptions-item label="下载资料">
          <a-button
            type="primary"
            @click="seeDowData(1, download.pagination.pageSize, seeInfoData.id)"
          >
            查看资料
          </a-button>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
    <!--  查看资料模态框  -->
    <a-modal
      :width="700"
      :visible="download.seeDowModel"
      @cancel="download.seeDowModel = false"
      okText="确定"
      @ok="download.seeDowModel = false"
    >
      <a-table
        :columns="download.columns"
        :data-source="download.data"
        bordered
        :pagination="download.pagination"
        rowKey="id"
      >
        <template slot="fileName" slot-scope="text, record, index">
          {{ text }}
          <a
            href="javascript:void(0)"
            v-if="record.isImg"
            @click="
              previewVisible = true;
              previewImage = record.url;
            "
          >
            <img alt="example" style="width: 20%" :src="record.url" />
          </a>
        </template>
        <template slot="operation" slot-scope="text, record, index">
          <a
            :download="record.fileNmame"
            :href="record.url"
            v-if="!record.isImg"
            >下载</a
          >
          <a-popconfirm
            title="确定要删除吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="deleteFile(record.url)"
          >
            <a-button type="link">删除</a-button>
          </a-popconfirm>
        </template>
      </a-table>
    </a-modal>
    <!--  添加或修改  -->
    <a-modal
      :width="700"
      :visible="optionModel"
      @cancel="optionModel = false"
      :okText="optionOk"
      @ok="sublimeOk"
    >
      <a-form-model
        :model="form"
        ref="form"
        :rules="formRole"
        :labelAlign="'left'"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item
          label="输入显示价格"
          ref="showMoney"
          prop="showMoney"
          v-if="optionOk === '进行出售'"
        >
          <a-input type="number" v-model="form.showMoney" />
        </a-form-model-item>
        <a-form-model-item
          label="输入收购价格"
          ref="buyMoney"
          prop="buyMoney"
          v-if="optionOk === '进行出售'"
        >
          <a-input type="number" v-model="form.buyMoney" />
        </a-form-model-item>
        <a-form-model-item
          label="选择咨询人"
          ref="advisoryId"
          prop="advisoryId"
          v-if="optionOk === '进行出售'"
        >
          <a-select style="width: 150px" v-model="form.advisoryId">
            <a-select-option
              v-for="i in totalAdvisory"
              :key="i.id"
              :value="i.id"
            >
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="购买用户"
          ref="buyUserName"
          prop="buyUserName"
          v-if="optionOk === '立即预定' || optionOk === '确定售出'"
        >
          <a-input v-model="form.buyUserName" />
        </a-form-model-item>
        <a-form-model-item
          label="购买用户手机号"
          ref="buyUserPhone"
          prop="buyUserPhone"
          v-if="optionOk === '立即预定' || optionOk === '确定售出'"
        >
          <a-input v-model="form.buyUserPhone" />
        </a-form-model-item>
        <a-form-model-item
          label="定金"
          ref="depositMoney"
          prop="depositMoney"
          v-if="optionOk === '立即预定'"
        >
          <a-input type="number" v-model="form.depositMoney" />
        </a-form-model-item>
        <a-form-model-item
          label="预定截止时间"
          ref="scheduledEndTime"
          prop="scheduledEndTime"
          v-if="optionOk === '立即预定'"
        >
          <a-date-picker
            v-model="form.scheduledEndTime"
            format="YYYY-MM-DD"
            placeholder="选择时间"
            style="margin-right: 10px"
            @change="onChangePayTime"
          />
        </a-form-model-item>
        <a-form-model-item
          label="选择员工"
          ref="salesName"
          prop="salesName"
          v-if="optionOk === '立即预定' || optionOk === '确定售出'"
        >
          <a-select
            style="width: 150px"
            v-model="form.salesName"
            @change="chooseSales"
          >
            <a-select-option
              v-for="i in totalSales"
              :key="i.name"
              :value="i.name"
            >
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          label="成交价"
          ref="dealMoney"
          prop="dealMoney"
          v-if="optionOk === '确定售出'"
        >
          <a-input type="number" v-model="form.dealMoney" />
        </a-form-model-item>
        <a-form-model-item
          label="成交时间"
          ref="dealTime"
          prop="dealTime"
          v-if="optionOk === '确定售出'"
        >
          <a-date-picker
            v-model="form.dealTime"
            format="YYYY-MM-DD"
            placeholder="选择时间"
            style="margin-right: 10px"
            @change="onChangePayTime"
          />
        </a-form-model-item>
        <a-form-model-item
          label="佣金"
          ref="commissionMoney"
          prop="commissionMoney"
          v-if="optionOk === '确定售出'"
        >
          <a-input type="number" v-model="form.commissionMoney" />
        </a-form-model-item>
        <a-form-model-item label="付款凭证" v-if="optionOk === '确定售出'">
          <a-upload
            :file-list="form.fkList"
            :remove="fkHandleRemove"
            :before-upload="fkBeforeUpload"
            :multiple="true"
          >
            <a-button>
              <a-icon type="upload" />
              点击上传
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="合同" v-if="optionOk === '确定售出'">
          <a-upload
            :file-list="form.htList"
            :remove="htHandleRemove"
            :before-upload="htBeforeUpload"
            :multiple="true"
          >
            <a-button>
              <a-icon type="upload" />
              点击上传
            </a-button>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label="其他文件" v-if="optionOk === '确定售出'">
          <a-upload
            :file-list="form.otherList"
            :remove="otherHandleRemove"
            :before-upload="otherBeforeUpload"
            :multiple="true"
          >
            <a-button>
              <a-icon type="upload" />
              点击上传
            </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!--  查看图片  -->
    <a-modal
      :visible="previewVisible"
      @cancel="previewVisible = false"
      :footer="null"
      :width="800"
      :z-index="9999"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <!--  导出  -->
    <a-modal
      v-model="exportInfo.exportModel"
      @ok="exportOk"
      :destroyOnClose="true"
    >
      <p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">
        请选择导出的时间：
      </p>
      <a-date-picker
        v-model="exportInfo.exportStartValue"
        :disabled-date="disabledExStartDate"
        format="YYYY-MM-DD 00:00:00"
        placeholder="开始时间"
        style="margin-right: 10px"
        @change="onChangeExStartTime"
        @openChange="handleExStartOpenChange"
      />
      <a-date-picker
        v-model="exportInfo.exportEndValue"
        :disabled-date="disabledExEndDate"
        format="YYYY-MM-DD 23:59:59"
        placeholder="结束时间"
        :open="exportInfo.endExOpen"
        @change="onChangeExEndTime"
        @openChange="handleExEndOpenChange"
      />
    </a-modal>
    <!--  查看记录  -->
    <a-modal
      :width="1300"
      :visible="log.model"
      @cancel="log.model = false"
      :footer="null"
    >
      <a-table
        :columns="log.columns"
        :data-source="log.data"
        bordered
        :pagination="log.pagination"
        rowKey="id"
      >
        <template slot="operation" slot-scope="text, record, index">
          <a-button
            type="primary"
            @click="
              seeDowData(
                1,
                download.pagination.pageSize,
                record.patentStatus,
                record.proxyPatentId
              )
            "
          >
            查看资料
          </a-button>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  // {
  //     title: '系统订单号',
  //     dataIndex: 'orderNumber',
  //     width: '5%',
  //     scopedSlots: {customRender: 'orderNumber'},
  // },
  {
    title: "专利号",
    dataIndex: "patentNumber",
    width: "5%",
    scopedSlots: { customRender: "patentNumber" },
  },
  {
    title: "专利名称",
    dataIndex: "patentName",
    scopedSlots: { customRender: "patentName" },
  },
  {
    title: "专利类型",
    dataIndex: "clientTypeDesc",
    scopedSlots: { customRender: "clientTypeDesc" },
  },
  {
    title: "专利状态",
    dataIndex: "patentStatusDesc",
    scopedSlots: { customRender: "patentStatusDesc" },
  },
  {
    title: "合作方",
    dataIndex: "cooperName",
    scopedSlots: { customRender: "cooperName" },
  },
  {
    title: "显示价格",
    dataIndex: "showMoney",
    scopedSlots: { customRender: "showMoney" },
  },
  {
    title: "所属用户",
    dataIndex: "userName",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "收购价",
    dataIndex: "buyMoney",
    scopedSlots: { customRender: "buyMoney" },
  },
  {
    title: "购买用户",
    dataIndex: "buyUserName",
    scopedSlots: { customRender: "buyUserName" },
  },
  // {
  //     title: '定金',
  //     dataIndex: 'depositMoney',
  //     scopedSlots: {customRender: 'depositMoney'},
  // },
  // {
  //     title: '预定截止时间',
  //     dataIndex: 'scheduledEndTime',
  //     scopedSlots: {customRender: 'scheduledEndTime'},
  // },
  {
    title: "成交价",
    dataIndex: "dealMoney",
    scopedSlots: { customRender: "dealMoney" },
  },
  {
    title: "成交时间",
    dataIndex: "dealTime",
    scopedSlots: { customRender: "dealTime" },
  },
  {
    title: "员工",
    dataIndex: "salesName",
    scopedSlots: { customRender: "salesName" },
  },
  {
    title: "销售状态",
    dataIndex: "sellStatusDesc",
    scopedSlots: { customRender: "sellStatusDesc" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: "15%",
    scopedSlots: { customRender: "operation" },
  },
];
const logColumns = [
  {
    title: "专利号",
    dataIndex: "patentNumber",
    scopedSlots: { customRender: "patentNumber" },
  },
  {
    title: "专利名称",
    dataIndex: "patentName",
    scopedSlots: { customRender: "patentName" },
  },
  {
    title: "专利类型",
    dataIndex: "clientTypeDesc",
    scopedSlots: { customRender: "clientTypeDesc" },
  },
  {
    title: "专利状态",
    dataIndex: "patentStatusDesc",
    scopedSlots: { customRender: "patentStatusDesc" },
  },
  {
    title: "合作方",
    dataIndex: "cooperName",
    scopedSlots: { customRender: "cooperName" },
  },
  {
    title: "显示价格",
    dataIndex: "showMoney",
    scopedSlots: { customRender: "showMoney" },
  },
  {
    title: "所属用户",
    dataIndex: "userName",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "收购价",
    dataIndex: "buyMoney",
    scopedSlots: { customRender: "buyMoney" },
  },
  {
    title: "购买用户",
    dataIndex: "buyUserName",
    scopedSlots: { customRender: "buyUserName" },
  },
  {
    title: "定金",
    dataIndex: "depositMoney",
    scopedSlots: { customRender: "depositMoney" },
  },
  {
    title: "预定截止时间",
    dataIndex: "scheduledEndTime",
    scopedSlots: { customRender: "scheduledEndTime" },
  },
  {
    title: "成交价",
    dataIndex: "dealMoney",
    scopedSlots: { customRender: "dealMoney" },
  },
  {
    title: "成交时间",
    dataIndex: "dealTime",
    scopedSlots: { customRender: "dealTime" },
  },
  {
    title: "员工",
    dataIndex: "salesName",
    scopedSlots: { customRender: "salesName" },
  },
  {
    title: "销售状态",
    dataIndex: "sellStatusDesc",
    scopedSlots: { customRender: "sellStatusDesc" },
  },
  {
    title: "操作人",
    dataIndex: "optionUser",
    scopedSlots: { customRender: "optionUser" },
  },
  {
    title: "操作人手机号",
    dataIndex: "optionUserPhone",
    scopedSlots: { customRender: "optionUserPhone" },
  },
];

export default {
  name: "transaction",
  data() {
    return {
      log: {
        model: false,
        columns: logColumns,
        data: [
          {
            id: "adadas",
          },
        ],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 100,
          onChange: (page, pageSize) => {
            this.getData(page, pageSize);
          },
        },
      },
      download: {
        seeDowModel: false,
        columns: [
          {
            title: "资料名称",
            dataIndex: "fileName",
            scopedSlots: { customRender: "fileName" },
          },
          {
            title: "操作",
            dataIndex: "operation",
            key: "operation",
            width: "25%",
            scopedSlots: { customRender: "operation" },
          },
        ],
        data: [],
        pagination: {
          current: 1,
          pageSize: 10,
          total: 100,
          onChange: (page, pageSize) => {
            this.getData(page, pageSize);
          },
        },
      },
      exportInfo: {
        exportModel: false,
        exportEndValue: null,
        endExOpen: false,
        exportStartValue: null,
        exportStartTime: null,
        exportEndTime: null,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize);
        },
      },
      search: {
        orderNumber: null,
        patentNumber: null,
        patentName: null,
        clientType: null,
        patentStatus: null,
        cooperName: null,
        userName: null,
        buyUserName: null,
        salesName: null,
        endTime: null,
        startTime: null,
        endOpen: false,
        userInfo: [],
      },
      columns,
      data: [],
      totalAdvisory: [],
      totalSales: [],
      totalUserInfo: [],
      userInfo: [],
      optionModel: false,
      seeInfoData: {},
      seeInfoModel: false,
      optionOk: "添加",
      form: {
        id: null,
        showMoney: null,
        advisoryId: null,
        buyMoney: null,
        buyUserName: null,
        buyUserPhone: null,
        depositMoney: null,
        scheduledEndTime: null,
        salesName: null,
        dealMoney: null,
        commissionMoney: null,
        dealTime: null,
        fkList: [],
        htList: [],
        otherList: [],
      },
      formRole: {
        advisoryId: [{ required: true, message: "不能为空", trigger: "blur" }],
        showMoney: [{ required: true, message: "不能为空", trigger: "blur" }],
        buyMoney: [{ required: true, message: "不能为空", trigger: "blur" }],
        buyUserName: [{ required: true, message: "不能为空", trigger: "blur" }],
        depositMoney: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        scheduledEndTime: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        dealTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        payStatus: [{ required: true, message: "不能为空", trigger: "blur" }],
        salesName: [{ required: true, message: "不能为空", trigger: "blur" }],
        dealMoney: [{ required: true, message: "不能为空", trigger: "blur" }],
        commissionMoney: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
      },
      previewVisible: false,
      previewImage: null
    };
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize);
    this.getUserInfo();
    this.getTotalAdvisory();
    this.getTotalSales();
  },
  methods: {
    async getData(pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/operation/transaction/patent/get/" +
          pageNum +
          "/" +
          pageSize,
        {
          params: {
            orderNumber: this.search.orderNumber,
            patentNumber: this.search.patentNumber,
            patentName: this.search.patentName,
            clientType: this.search.clientType,
            patentStatus: this.search.patentStatus,
            cooperName: this.search.cooperName,
            userName: this.search.userName,
            buyUserName: this.search.buyUserName,
            salesName: this.search.salesName,
          },
        }
      );
      this.data = data.data.list;
      this.pagination.current = data.data.pageNum;
      this.pagination.pageSize = data.data.pageSize;
      this.pagination.total = data.data.total;
    },
    async getUserInfo() {
      const { data } = await this.$axios.get(
        "/api/v1/admin/operation/transaction/patent/get/choose/info"
      );
      this.userInfo = data.data;
    },
    async getTotalAdvisory() {
      const { data } = await this.$axios.get(
        "/api/v1/admin/info/advisory/get/1/999999"
      );
      this.totalAdvisory = data.data.list;
    },
    async getTotalSales() {
      const { data } = await this.$axios.get(
        "/api/v1/admin/info/sales/get/1/999999"
      );
      this.totalSales = data.data.list;
    },
    selectBusinessType(value) {
      if (value === "0") {
        this.search.clientType = null;
      } else {
        this.search.clientType = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    selectPatentStatus(value) {
      if (value === "0") {
        this.search.patentStatus = null;
      } else {
        this.search.patentStatus = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    selectCooperName(value) {
      if (value === "0") {
        this.search.cooperName = null;
      } else {
        this.search.cooperName = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    selectUserName(value) {
      if (value === "0") {
        this.search.userName = null;
      } else {
        this.search.userName = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    selectBuyUserName(value) {
      if (value === "0") {
        this.search.buyUserName = null;
      } else {
        this.search.buyUserName = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    selectSalesName(value) {
      if (value === "0") {
        this.search.salesName = null;
      } else {
        this.search.salesName = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    // 查看信息
    seeInfo(record) {
      this.seeInfoModel = true;
      this.seeInfoData = record;
    },
    async seeLog(id, pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/operation/transaction/patent/log/" +
          id +
          "/" +
          pageNum +
          "/" +
          pageSize
      );
      this.log.data = data.data.list;
      this.log.pagination.current = data.data.pageNum;
      this.log.pagination.pageSize = data.data.pageSize;
      this.log.pagination.total = data.data.total;
      this.log.model = true;
    },
    // 添加或修改
    addOrUpdate(op, record) {
      this.optionModel = true;
      this.optionOk = op;
      this.form = {
        id: null,
        showMoney: null,
        advisoryId: null,
        buyMoney: null,
        buyUserName: null,
        buyUserPhone: null,
        depositMoney: null,
        scheduledEndTime: null,
        salesName: null,
        dealMoney: null,
        dealTime: null,
        commissionMoney: null,
        fkList: [],
        htList: [],
        otherList: [],
      };
      this.form.id = record.id;
      if (op === "出售") {
        this.form.showMoney = record.showMoney;
        this.form.advisoryId = record.advisoryId;
        this.form.buyMoney = record.buyMoney;
      } else if (op === "确定售出" || op === "立即预定") {
        this.form.buyUserName = record.buyUserName;
        this.form.buyUserPhone = record.buyUserPhone;
        this.form.depositMoney = record.depositMoney;
        this.form.scheduledEndTime = record.scheduledEndTime;
        this.form.salesName = record.salesName;
        this.form.dealMoney = record.dealMoney;
        this.form.dealTime = record.dealTime;
        this.form.commissionMoney = record.commissionMoney;
      } else if (op === "进行出售") {
        this.form.showMoney = record.showMoney;
        this.form.buyMoney = record.buyMoney;
        this.form.advisoryId = record.advisoryId;
      }
    },
    async cancleYD(record) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/operation/transaction/patent/update",
        {
          id: record.id,
          sellStatus: 1,
        }
      );
      this.$message.success(data.message);
      this.optionModel = false;
      this.getData(1, this.pagination.pageSize);
    },
    async deleteFunc(id) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/operation/transaction/patent/delete",
        {
          id: id,
        }
      );
      this.$message.success("删除成功！");
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    async stopSS(record, sellStatus) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/operation/transaction/patent/update",
        {
          id: record.id,
          sellStatus: sellStatus,
        }
      );
      this.$message.success(data.message);
      this.optionModel = false;
      this.getData(1, this.pagination.pageSize);
    },
    // 查看下载资料
    async seeDowData(pageNum, pageSize, id) {
      const { data } = await this.$axios.get(
        "/api/v1/common/get/file/" + id + "/6/" + pageNum + "/" + pageSize
      );
      this.download.data = data.data.list;
      this.download.pagination.current = data.data.pageNum;
      this.download.pagination.pageSize = data.data.pageSize;
      this.download.pagination.total = data.data.total;
      this.download.seeDowModel = true;
    },
    //  删除文件
    async deleteFile(url) {
      this.$commJs.deleteFile(this, url);
    },
    sublimeOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.optionOk === "确定售出") {
            // 上传图片
            for (let i = 0; i < this.form.fkList.length; i++) {
              let file = this.form.fkList[i];
              let formData = new FormData();
              formData.append("file", file);
              await this.$axios.post(
                "/api/v1/common/upload/file?type=6&smallType=2&uploadTypeId=" +
                  this.form.id,
                formData
              );
            }
            for (let i = 0; i < this.form.htList.length; i++) {
              let file = this.form.htList[i];
              let formData = new FormData();
              formData.append("file", file);
              await this.$axios.post(
                "/api/v1/common/upload/file?type=6&smallType=1&uploadTypeId=" +
                  this.form.id,
                formData
              );
            }
            for (let i = 0; i < this.form.otherList.length; i++) {
              let file = this.form.otherList[i];
              let formData = new FormData();
              formData.append("file", file);
              await this.$axios.post(
                "/api/v1/common/upload/file?type=6&uploadTypeId=" +
                  this.form.id,
                formData
              );
            }
            this.form.sellStatus = 5;
          }
          if (this.optionOk === "立即预定") {
            this.form.sellStatus = 2;
          }
          if (this.optionOk === "进行出售") {
            this.form.sellStatus = 3;
          }
          const { data } = await this.$axios.post(
            "/api/v1/admin/operation/transaction/patent/update",
            this.form
          );
          this.$message.success(data.message);
          this.optionModel = false;
          this.getData(1, this.pagination.pageSize);
        }
      });
    },
    async handleSearch(value) {
      this.search.userInfo = [];
      const { data } = await this.$axios.get(
        "/api/v1/common/search/1/1/99999?search=" + value
      );
      for (let i = 0; i < data.data.list.length; i++) {
        this.search.userInfo.push({
          userName: data.data.list[i].userName,
          userPhone: data.data.list[i].phone,
        });
      }
    },
    handleChange(value) {
      for (let i = 0; i < this.search.userInfo.length; i++) {
        let userInfoElement = this.search.userInfo[i];
        if (value === userInfoElement.userName) {
          this.form.buyUserPhone = userInfoElement.userPhone;
          this.form.buyUserName = userInfoElement.userName;
        }
      }
    },
    fkHandleRemove(file) {
      const index = this.form.fkList.indexOf(file);
      const newFileList = this.form.fkList.slice();
      newFileList.splice(index, 1);
      this.form.fkList = newFileList;
    },
    fkBeforeUpload(file) {
      this.form.fkList = [...this.form.fkList, file];
      return false;
    },
    htHandleRemove(file) {
      const index = this.form.htList.indexOf(file);
      const newFileList = this.form.htList.slice();
      newFileList.splice(index, 1);
      this.form.htList = newFileList;
    },
    htBeforeUpload(file) {
      this.form.htList = [...this.form.htList, file];
      return false;
    },
    otherHandleRemove(file) {
      const index = this.form.otherList.indexOf(file);
      const newFileList = this.form.otherList.slice();
      newFileList.splice(index, 1);
      this.form.otherList = newFileList;
    },
    otherBeforeUpload(file) {
      this.form.otherList = [...this.form.otherList, file];
      return false;
    },
    //导出
    disabledExStartDate(startValue) {
      const endValue = this.exportInfo.exportEndValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledExEndDate(endValue) {
      const startValue = this.exportInfo.exportStartValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    chooseSales(value) {
      for (let i = 0; i < this.totalSales.length; i++) {
        let totalSale = this.totalSales[i];
        if (value === totalSale.name) {
          this.form.salesName = value;
          this.form.salesPhone = totalSale.phone;
        }
      }
    },
    onChangePayTime(value, dateString) {
      this.form.scheduledEndTime = this.$commJs.formatStrTime(dateString);
    },
    onChangeExStartTime(value, dateString) {
      this.exportInfo.exportStartTime = this.$commJs.formatStrTime(dateString);
    },
    onChangeExEndTime(value, dateString) {
      this.exportInfo.exportEndTime = this.$commJs.formatStrTime(dateString);
    },
    handleExStartOpenChange(open) {
      if (!open) {
        this.exportInfo.endExOpen = true;
      }
    },
    handleExEndOpenChange(open) {
      this.exportInfo.endExOpen = open;
    },
    // 导出
    async exportOk() {
      this.$commJs.exportExcel(
        this,
        "/api/v1/admin/report/export/fertilize/all/" +
          this.exportStartTime +
          "/" +
          this.exportEndTime,
        "肥料包记录-" +
          this.exportInfo.exportStartTime +
          "~" +
          this.exportInfo.exportEndTime
      );
      this.exportInfo.exportModel = false;
    },
    // 点击整行显示详情
    handleRowClick(record) {
      this.seeInfo(record);
    },
  },
};
</script>

<style scoped></style>
