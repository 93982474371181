<template>
    <div id="patent">
        <a-row style="margin-bottom: 20px" :gutter="16">
            <!--            <a-col :span="4">-->
            <!--                <a-button type="primary" @click="addOrUpdate('添加')">添加专利买卖</a-button>-->
            <!--            </a-col>-->
        </a-row>
        <a-table :columns="columns" :data-source="data" bordered :pagination="false" rowKey="id">
            <template slot="icon" slot-scope="text,record,index">
                <a href="javascript:void(0)" @click="previewVisible = true;previewImage = text">
                    <img alt="example" style="width: 20%" :src="text"/>
                </a>
            </template>
            <template slot="advisoryQr" slot-scope="text,record,index">
                <a href="javascript:void(0)" @click="previewVisible = true;previewImage = text">
                    <img alt="example" style="width: 20%" :src="text"/>
                </a>
            </template>
            <template slot="type" slot-scope="text,record,index">
                <template v-if="text === 1">
                    专利出售
                </template>
                <template v-if="text === 2">
                    专利求购
                </template>
            </template>
            <template slot="operation" slot-scope="text,record,index">
                <a-button size="small" type="primary" @click="addOrUpdate('修改',record)">修改</a-button>
                <a-popconfirm
                        title="确定要删除吗?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="confirmDelete(record.id)"
                >
                    <a-button size="small" type="danger">删除</a-button>
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal :visible="previewVisible" @cancel="previewVisible=false" :footer="null" :width="800" :z-index="9999">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
        <a-modal :visible="addOrUpdateModel" @cancel="addOrUpdateModel = false" :okText="addOrUpdateOption"
                 @ok="sublimeOk">
            <a-form-model :model="form" ref="form" :rules="formRole" :labelAlign="'left'" :label-col="{span: 5}"
                          :wrapper-col="{span:16}">
                <a-form-model-item label="介绍" ref="patentDesc" prop="patentDesc">
                    <a-textarea v-model="form.patentDesc" :auto-size="{ minRows: 10, maxRows: 15 }"/>
                </a-form-model-item>
                <a-form-model-item label="选择咨询人" ref="advisoryId" prop="advisoryId">
                    <a-select v-model="form.advisoryId" placeholder="请选择咨询人">
                        <a-select-option v-for="i in advisory" :key="i.id" :value="i.id">
                            {{i.name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="上传图片">
                    <a-upload
                            action="/api/v1/common/upload/file?type=14&uploadTypeId=14"
                            :headers="{'Authorization':'Bearer '+$store.state.tokenValue}"
                            list-type="picture-card"
                            :file-list="fileList"
                            @preview="handlePreview"
                            @change="handleChange"
                    >
                        <div v-if="fileList.length < 1">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">
                                图片大小
                            </div>
                        </div>
                    </a-upload>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
    const columns = [
        {
            title: '日期',
            dataIndex: 'createTime',
            scopedSlots: {customRender: 'createTime'},
        },
        {
            title: '图标预览',
            dataIndex: 'icon',
            width: '20%',
            scopedSlots: {customRender: 'icon'},
        },
        {
            title: '主题',
            dataIndex: 'type',
            scopedSlots: {customRender: 'type'},
        },
        {
            title: '咨询二维码',
            dataIndex: 'advisoryQr',
            width: '20%',
            scopedSlots: {customRender: 'advisoryQr'},
        },
        {
            title: '咨询人姓名',
            dataIndex: 'name',
            scopedSlots: {customRender: 'name'},
        },
        {
            title: '咨询人手机号',
            dataIndex: 'phone',
            scopedSlots: {customRender: 'phone'},
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width: '20%',
            scopedSlots: {customRender: 'operation'}
        }
    ]
    export default {
        name: "patent",
        data() {
            return {
                previewVisible: false,
                previewImage: null,
                type: null,
                columns,
                data: [],
                addOrUpdateModel: false,
                addOrUpdateOption: '添加',
                advisory: [],
                form: {
                    patentDesc: null,
                    icon: null,
                    advisoryId: null,
                },
                formRole: {
                    patentDesc: [
                        {required: true, message: '介绍不能为空', trigger: 'blur'},
                    ],
                    advisoryId: [
                        {required: true, message: '咨询人不能为空', trigger: 'blur'},
                    ],
                },
                fileList: [],
            }
        },
        mounted() {
            this.getData()
            this.getAdvisory()
        },
        methods: {
            async getData(pageNum, pageSize) {
                const {data} = await this.$axios.get('/api/v1/admin/base/patent/get/')
                this.data = data.data
            },
            async getAdvisory() {
                const {data} = await this.$axios.get('/api/v1/admin/info/advisory/get/1/99999')
                this.advisory = data.data.list
            },
            // 添加或修改
            addOrUpdate(op, record) {
                this.addOrUpdateModel = true
                this.fileList = []
                this.form = {
                    serviceContent: null,
                    serviceContentDesc: null,
                    icon: null,
                    advisoryId: null,
                }
                this.addOrUpdateOption = op;
                if (op === '修改') {
                    // xxx
                    this.form = record
                    this.fileList = [
                        {
                            uid: '-1',
                            name: 'image.png',
                            status: 'done',
                            response: {
                                data: record.icon
                            },
                            url: record.icon,
                        },
                    ]
                }
            },
            sublimeOk() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        this.form.icon = this.fileList[0].response.data
                        if (this.addOrUpdateOption === '添加') {
                            var {data} = await this.$axios.post('/api/v1/admin/base/patent/add', this.form)
                        } else {
                            var {data} = await this.$axios.post('/api/v1/admin/base/patent/update', this.form)
                        }
                        this.$message.success(data.message)
                        this.addOrUpdateModel = false
                        this.getData()
                    }
                })
            },
            // 删除
            async confirmDelete(id) {

            },
            selectType(value) {
                if (value === '0') {
                    this.type = null
                } else {
                    this.type = value
                }
                this.getData(1, this.pagination.pageSize)
            },
            async handlePreview(file) {
                this.previewImage = file.response.data
                this.previewVisible = true;
            },
            handleChange({fileList}) {
                this.fileList = fileList;
            },
        }
    }
</script>

<style scoped>

</style>
