<template>
  <div id="product">
    <a-row style="margin-bottom: 20px" :gutter="16">
      <a-col :span="4">
        <a-button type="primary" @click="addOrUpdate('添加')"
          >添加服务产品</a-button
        >
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="pagination"
      rowKey="id"
    >
      <template slot="icon" slot-scope="text, record, index">
        <a
          href="javascript:void(0)"
          @click="
            previewVisible = true;
            previewImage = text;
          "
        >
          <img alt="example" style="width: 20%" :src="text" />
        </a>
      </template>
      <template slot="advisoryQr" slot-scope="text, record, index">
        <a
          href="javascript:void(0)"
          @click="
            previewVisible = true;
            previewImage = text;
          "
        >
          <img alt="example" style="width: 20%" :src="text" />
        </a>
      </template>
      <template slot="operation" slot-scope="text, record, index">
        <a-button
          size="small"
          type="primary"
          @click="addOrUpdate('修改', record)"
          >修改</a-button
        >
        <a-popconfirm
          title="确定要删除吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirmDelete(record.id)"
        >
          <a-button size="small" type="danger">删除</a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      :visible="previewVisible"
      @cancel="previewVisible = false"
      :footer="null"
      :width="800"
      :z-index="9999"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal
      :visible="addOrUpdateModel"
      @cancel="addOrUpdateModel = false"
      :okText="addOrUpdateOption"
      @ok="sublimeOk"
    >
      <a-form-model
        :model="form"
        ref="form"
        :rules="formRole"
        :labelAlign="'left'"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item label="费用" ref="money" prop="money">
          <a-input v-model="form.money" />
        </a-form-model-item>
        <a-form-model-item
          label="服务主题"
          ref="productTitle"
          prop="productTitle"
        >
          <a-input v-model="form.productTitle" />
        </a-form-model-item>
        <a-form-model-item
          label="服务介绍"
          ref="productDesc"
          prop="productDesc"
        >
          <a-textarea
            v-model="form.productDesc"
            :auto-size="{ minRows: 10, maxRows: 15 }"
          />
        </a-form-model-item>
        <a-form-model-item
          label="选择咨询人"
          ref="advisoryId"
          prop="advisoryId"
        >
          <a-select v-model="form.advisoryId" placeholder="请选择咨询人">
            <a-select-option v-for="i in advisory" :key="i.id" :value="i.id">
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="图标上传">
          <a-upload
            action="/api/v1/common/upload/file?type=13&uploadTypeId=13"
            :headers="{ Authorization: 'Bearer ' + $store.state.tokenValue }"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview"
            @change="handleChange"
            :remove="removeFile"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                图片大小
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "日期",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "图标预览",
    dataIndex: "icon",
    width: "20%",
    scopedSlots: { customRender: "icon" },
  },
  {
    title: "产品费用",
    dataIndex: "money",
    scopedSlots: { customRender: "money" },
  },
  {
    title: "服务主题",
    dataIndex: "productTitle",
    scopedSlots: { customRender: "productTitle" },
  },
  {
    title: "服务介绍",
    dataIndex: "productDesc",
    scopedSlots: { customRender: "productDesc" },
  },
  {
    title: "咨询二维码",
    dataIndex: "advisoryQr",
    width: "20%",
    scopedSlots: { customRender: "advisoryQr" },
  },
  {
    title: "咨询人姓名",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "咨询人手机号",
    dataIndex: "phone",
    scopedSlots: { customRender: "phone" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: "20%",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  name: "product",
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 10,
        total: 100,
        onChange: (page, pageSize) => {
          this.getData(page, pageSize);
        },
      },
      previewVisible: false,
      previewImage: null,
      type: null,
      columns,
      data: [],
      advisory: [],
      addOrUpdateModel: false,
      addOrUpdateOption: "添加",
      form: {
        money: null,
        productTitle: null,
        productDesc: null,
        serviceContentDesc: null,
        icon: null,
        advisoryId: null,
      },
      formRole: {
        money: [
          { required: true, message: "产品价格不能为空", trigger: "blur" },
        ],
        productTitle: [
          { required: true, message: "服务主题不能为空", trigger: "blur" },
        ],
        productDesc: [
          { required: true, message: "服务内容不能为空", trigger: "blur" },
        ],
        advisoryId: [
          { required: true, message: "咨询人不能为空", trigger: "blur" },
        ],
      },
      fileList: [],
    };
  },
  mounted() {
    this.getData(this.pagination.current, this.pagination.pageSize);
    this.getAdvisory();
  },
  methods: {
    async getData(pageNum, pageSize) {
      const { data } = await this.$axios.get(
        "/api/v1/admin/base/product/get/" + pageNum + "/" + pageSize
      );
      this.data = data.data.list;
      this.pagination.current = data.data.pageNum;
      this.pagination.pageSize = data.data.pageSize;
      this.pagination.total = data.data.total;
    },
    async getAdvisory() {
      const { data } = await this.$axios.get(
        "/api/v1/admin/info/advisory/get/1/99999"
      );
      this.advisory = data.data.list;
    },
    // 添加或修改
    addOrUpdate(op, record) {
      this.addOrUpdateModel = true;
      this.fileList = [];
      this.form = {
        money: null,
        serviceContent: null,
        serviceContentDesc: null,
        icon: null,
        advisoryId: null,
      };
      this.addOrUpdateOption = op;

      if (op === "修改") {
        // xxx
        this.form = record;
        this.fileList = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            response: {
              data: record.icon,
            },
            url: record.icon,
          },
        ];
      }
    },
    sublimeOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.fileList.length) this.form.icon = this.fileList[0].response.data;
          if (this.addOrUpdateOption === "添加") {
            var { data } = await this.$axios.post(
              "/api/v1/admin/base/product/add",
              this.form
            );
          } else {
            var { data } = await this.$axios.post(
              "/api/v1/admin/base/product/update",
              this.form
            );
          }
          this.$message.success(data.message);
          this.addOrUpdateModel = false;
          this.getData(1, this.pagination.pageSize);
        }
      });
    },
    // 删除
    async confirmDelete(id) {
      const { data } = await this.$axios.post(
        "/api/v1/admin/base/product/delete/" + id
      );
      this.$message.success(data.message);
      this.getData(1, this.pagination.pageSize);
    },
    selectType(value) {
      if (value === "0") {
        this.type = null;
      } else {
        this.type = value;
      }
      this.getData(1, this.pagination.pageSize);
    },
    async handlePreview(file) {
      this.previewImage = file.response.data;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    removeFile(file) {
      this.$commJs.removeFile(this, file);
    },
  },
};
</script>

<style scoped></style>
