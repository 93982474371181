<template>
	<div id="proxy">
		<a-row :gutter="16" style="margin-bottom: 10px">
			<a-col :span="5">
				<a-input placeholder="输入系统编号" v-model="search.orderNumber" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入内部编号" v-model="search.insideNumber" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入客户名称" v-model="search.clientName" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入专利号" v-model="search.patentNumber" />
			</a-col>
			<a-col :span="1" :offset="1">
				<a-button @click="importInfo.model = true">导入</a-button>
			</a-col>
			<a-col :span="1">
				<a-button @click="exportInfo.exportModel = true">导出</a-button>
			</a-col>
		</a-row>
		<a-row :gutter="16" style="margin-bottom: 10px">
			<a-col :span="5">
				<a-input placeholder="输入专利名称" v-model="search.patentName" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入申请名称" v-model="search.applyUser" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入发明人1" v-model="search.inventUser" />
			</a-col>
			<a-col :span="5">
				<a-input placeholder="输入代理机构" v-model="search.proxyUser" />
			</a-col>
			<a-col :span="2">
				<a-button type="primary" @click="getData(1, pagination.pageSize)"
					>搜索</a-button
				>
			</a-col>
		</a-row>
		<a-row style="margin-bottom: 20px">
			<a-col :span="4">
				<span style="font-size: 16px;">专利状态：</span>
				<a-select
					default-value="0"
					style="width: 150px"
					@change="selectBusinessType"
				>
					<a-select-option value="0">
						全部
					</a-select-option>
					<a-select-option value="1">
						待提交
					</a-select-option>
					<a-select-option value="2">
						申请中
					</a-select-option>
					<a-select-option value="3">
						待答复
					</a-select-option>
					<a-select-option value="4">
						已授权
					</a-select-option>
					<a-select-option value="5">
						已下证
					</a-select-option>
					<a-select-option value="6">
						驳回复审
					</a-select-option>
					<a-select-option value="7">
						未通过
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="4">
				<span style="font-size: 16px;">申请缴费状态：</span>
				<a-select
					default-value="2"
					style="width: 150px"
					@change="applyPayChooseType"
				>
					<a-select-option value="2">
						全部
					</a-select-option>
					<a-select-option value="1">
						已缴费
					</a-select-option>
					<a-select-option value="0">
						未缴费
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="4">
				<span style="font-size: 16px;">授权缴费状态：</span>
				<a-select
					default-value="2"
					style="width: 150px"
					@change="warranPayChooseType"
				>
					<a-select-option value="2">
						全部
					</a-select-option>
					<a-select-option value="1">
						已缴费
					</a-select-option>
					<a-select-option value="0">
						未缴费
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="4">
				<span style="font-size: 16px;">复审缴费状态：</span>
				<a-select
					default-value="2"
					style="width: 150px"
					@change="reviewPayChooseType"
				>
					<a-select-option value="2">
						全部
					</a-select-option>
					<a-select-option value="1">
						已缴费
					</a-select-option>
					<a-select-option value="0">
						未缴费
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="4">
				<span style="font-size: 16px;">年费缴费状态：</span>
				<a-select
					default-value="2"
					style="width: 150px"
					@change="yearPayChooseType"
				>
					<a-select-option value="2">
						全部
					</a-select-option>
					<a-select-option value="1">
						已缴费
					</a-select-option>
					<a-select-option value="0">
						未缴费
					</a-select-option>
				</a-select>
			</a-col>
			<a-col :span="4">
				<span style="font-size: 16px;">截止日期排序：</span>
				<a-select
					default-value="0"
					style="width: 150px"
					@change="orderTypeChooseType"
				>
					<a-select-option value="0">
						时间排序
					</a-select-option>
					<a-select-option value="1">
						申请截止日期排序
					</a-select-option>
					<a-select-option value="2">
						授权截止日期排序
					</a-select-option>
					<a-select-option value="3">
						复审截止日期排序
					</a-select-option>
					<a-select-option value="4">
						年费截止日期排序
					</a-select-option>
				</a-select>
			</a-col>
		</a-row>
		<a-row style="margin-bottom: 20px">
			<a-col :span="10">
				<span style="font-size: 16px;">申请日期：</span>
				<a-date-picker
					v-model="search.startTimeVal"
					:disabled-date="disabledStartDate"
					format="YYYY-MM-DD 00:00:00"
					placeholder="开始时间"
					style="margin-right: 10px"
					@change="onChangeStartTime"
					@openChange="handleStartOpenChange"
				/>
				<a-date-picker
					v-model="search.endTimeVal"
					:disabled-date="disabledEndDate"
					format="YYYY-MM-DD 23:59:59"
					placeholder="结束时间"
					:open="search.endOpen"
					@change="onChangeEndTime"
					@openChange="handleEndOpenChange"
				/>
			</a-col>
			<a-col :span="2" :offset="11">
				<a-button type="primary" @click="add('添加')">创建专利案件</a-button>
			</a-col>
		</a-row>
		<a-table
			:columns="columns"
			:data-source="data"
			bordered
			:pagination="pagination"
			rowKey="id"
			:scroll="{ x: 500, y: 1500 }"
			@rowClick="handleRowClick"
		>
			<template slot="clientType" slot-scope="text, record, index">
				<template v-if="text === 1">
					发明专利
				</template>
				<template v-else-if="text === 2">
					实用专利
				</template>
				<template v-else-if="text === 3">
					外观专利
				</template>
			</template>
			<template slot="payStatus" slot-scope="text, record, index">
				<template v-if="text">
					已缴费
				</template>
				<template v-else>
					未缴费
				</template>
			</template>
			<template slot="operation" slot-scope="text, record, index">
				<a-button size="small" type="primary" @click.stop="seeInfo(record)"
					>查看</a-button
				>
				<a-button size="small" @click.stop="update(record)">修改</a-button>
				<a-button size="small" @click.stop="updateInfo(record)"
					>修改资料</a-button
				>
				<!-- <a-popconfirm
					placement="leftTop"
					ok-text="变更"
					cancel-text="取消"
					v-if="record.patentStatus < 6"
					@confirm="changePatentStatus(record.id)"
				>
					<a-icon slot="icon" />
					<template slot="title">
						<a-radio-group name="radioGroup" v-model="changePatentStatusValue">
							<a-radio :value="2">
								申请中
							</a-radio>
							<a-radio :value="3">
								待答复
							</a-radio>
							<a-radio :value="4">
								已授权
							</a-radio>
							<a-radio :value="5">
								已下证
							</a-radio>
							<a-radio :value="6">
								驳回复审
							</a-radio>
							<a-radio :value="7">
								不通过
							</a-radio>
						</a-radio-group>
					</template>
					<a-button
						size="small"
						@click.stop="changePatentStatusValue = record.patentStatus"
						>变更状态</a-button
					>
				</a-popconfirm> -->
				<a-button size="small" @click.stop="changeStatusClick(record)">
					变更状态
				</a-button>
				<!-- <a-button
					size="small"
					@click.stop="seeLog(record.id, 1, log.pagination.pageSize)"
				>
					记录
				</a-button> -->
				<!-- <a-button
					size="small"
					@click.stop="
						upload.model = true;
						upload.fileList = [];
						seeInfoData = record;
						upload.smallType = record.patentStatus;
					"
				>
					上传资料
				</a-button> -->
				<a-popconfirm
					title="确定要删除吗?"
					ok-text="确定"
					cancel-text="取消"
					@confirm="deleteFunc(record.id)"
				>
					<a-button size="small" type="danger" @click.stop>删除</a-button>
				</a-popconfirm>
			</template>
		</a-table>
		<!--  添加  -->
		<a-modal
			:width="700"
			:visible="addOrUpdateModel"
			@cancel="addOrUpdateModel = false"
			:okText="addOrUpdateOption"
			@ok="sublimeOk"
		>
			<a-form-model
				:model="form"
				ref="form"
				:rules="formRole"
				labelAlign="right"
				:label-col="{ span: 4 }"
				:wrapper-col="{ span: 19 }"
			>
				<a-form-model-item
					label="内部编号"
					ref="insideNumber"
					prop="insideNumber"
				>
					<a-input v-model="form.insideNumber" />
				</a-form-model-item>
				<a-form-model-item
					label="选择客户"
					ref="clientPhone"
					prop="clientPhone"
				>
					<a-select
						style="width: 200px"
						v-model="form.clientPhone"
						@change="chooseClient"
						@search="getClienInfo"
						:filterOption="filterOption"
						show-search
					>
						<a-select-option
							v-for="i in clientInfo"
							:key="i.clientPhone"
							:value="i.clientPhone"
						>
							{{ i.clientName }}-{{ i.clientPhone }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="员工" ref="salesName" prop="salesName">
					<a-select
						allowClear
						mode="multiple"
						placeholder="请选择员工"
						style="width: 100%"
						v-model="form.salesSelect"
					>
						<a-select-option v-for="i in totalSales" :key="i.id" :value="i.id">
							{{ i.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="专利类型" ref="clientType" prop="clientType">
					<a-select style="width: 150px" v-model="form.clientType">
						<a-select-option :value="1">
							发明
						</a-select-option>
						<a-select-option :value="2">
							实用
						</a-select-option>
						<a-select-option :value="3">
							外观
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="专利号">
					<a-input v-model="form.patentNumber" />
				</a-form-model-item>
				<a-form-model-item label="专利名称" ref="patentName" prop="patentName">
					<a-input v-model="form.patentName" />
				</a-form-model-item>
				<a-form-model-item label="申请日">
					<a-date-picker
						v-model="form.applyTime"
						format="YYYY-MM-DD"
						placeholder="选择时间"
						style="margin-right: 10px"
						@change="onChangePayTime"
					/>
				</a-form-model-item>
				<a-form-model-item label="申请人名称" ref="applyUser" prop="applyUser">
					<a-input v-model="form.applyUser" />
				</a-form-model-item>
				<a-form-model-item label="发明人1" ref="inventUser" prop="inventUser">
					<a-input v-model="form.inventUser" />
				</a-form-model-item>
				<a-form-model-item label="代理机构" ref="proxyUser" prop="proxyUser">
					<a-input v-model="form.proxyUser" />
				</a-form-model-item>
				<a-form-model-item label="备注">
					<a-textarea
						v-model="form.remark"
						:auto-size="{ minRows: 5, maxRows: 15 }"
					/>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!--  修改  -->
		<a-modal
			:width="700"
			:visible="updateModel"
			@cancel="updateModel = false"
			okText="修改"
			@ok="updateModelSublimeOk"
		>
			<a-form-model
				:model="updateForm"
				ref="updateForm"
				:labelAlign="'left'"
				:label-col="{ span: 3 }"
				:wrapper-col="{ span: 20 }"
			>
				<a-form-model-item label="金额">
					<a-input v-model="updateForm.money" />
				</a-form-model-item>
				<a-form-model-item label="缴费状态">
					<a-select style="width: 150px" v-model="updateForm.payStatus">
						<a-select-option :value="true">
							已缴费
						</a-select-option>
						<a-select-option :value="false">
							未缴费
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="截止日期">
					<a-date-picker
						v-model="updateForm.stopTime"
						format="YYYY-MM-DD"
						placeholder="开始时间"
						style="margin-right: 10px"
						@change="onChangeUpdateStopTime"
					/>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
		<!--  查看  -->
		<a-modal
			:visible="seeInfoModel"
			@cancel="seeInfoModel = false"
			:footer="null"
			:width="600"
		>
			<a-descriptions title="业务案件订单" :column="2">
				<a-descriptions-item label="系统订单号">
					{{ seeInfoData.orderNumber }}
				</a-descriptions-item>
				<a-descriptions-item label="客户名称">
					{{ seeInfoData.clientName }}
				</a-descriptions-item>
				<a-descriptions-item label="员工">
					{{ seeInfoData.salesName }}
				</a-descriptions-item>
				<a-descriptions-item label="专利名称">
					{{ seeInfoData.patentName }}
				</a-descriptions-item>
				<a-descriptions-item label="专利状态">
					{{ seeInfoData.patentStatusDesc }}
				</a-descriptions-item>
				<a-descriptions-item label="专利号">
					{{ seeInfoData.patentNumber }}
				</a-descriptions-item>
				<a-descriptions-item label="申请名称">
					{{ seeInfoData.applyUser }}
				</a-descriptions-item>
				<a-descriptions-item :label="seeInfoData.moneyType">
					{{ seeInfoData.money }}
				</a-descriptions-item>
				<a-descriptions-item label="发明人1">
					{{ seeInfoData.inventUser }}
				</a-descriptions-item>
				<a-descriptions-item label="代理机构">
					{{ seeInfoData.proxyUser }}
				</a-descriptions-item>
				<a-descriptions-item label="备注">
					{{ seeInfoData.remark }}
				</a-descriptions-item>
				<a-descriptions-item> </a-descriptions-item>
				<a-descriptions-item label="历史日志">
					<!-- <a-button
						type="primary"
						@click="
							seeDowData(
								1,
								download.pagination.pageSize,
								seeInfoData.patentStatus,
								seeInfoData.id
							)
						"
					>
						查看资料
					</a-button> -->
				</a-descriptions-item>
			</a-descriptions>
			<div style="padding-bottom: 20px;">
				<a-table
					:columns="history.columns"
					:data-source="history.data"
					bordered
					rowKey="id"
				>
					<template slot="operation" slot-scope="text, record, index">
						<a-button
							type="primary"
							@click="
								seeDowData(1, 10, record.patentStatus, record.proxyPatentId)
							"
						>
							查看资料
						</a-button>
					</template>
				</a-table>
			</div>
		</a-modal>
		<!--  查看图片  -->
		<a-modal
			:visible="previewVisible"
			@cancel="previewVisible = false"
			:footer="null"
			:width="800"
			:z-index="9999"
		>
			<img alt="example" style="width: 100%" :src="previewImage" />
		</a-modal>
		<!--  上传资料模态框  -->
		<!-- <a-modal
			:width="700"
			:visible="upload.model"
			@cancel="upload.model = false"
			okText="确定"
			@ok="uploadHandleUpload"
			:ok-button-props="{ props: { disabled: upload.fileList.length === 0 } }"
		>
			<div style="margin-bottom: 15px">
				<span>选择资料类型：</span>
				<a-radio-group name="radioGroup" v-model="upload.smallType">
					<a-radio :value="1">
						待提交状态资料
					</a-radio>
					<a-radio :value="2">
						申请中状态资料
					</a-radio>
					<a-radio :value="3">
						待答复状态资料
					</a-radio>
					<a-radio :value="4">
						已授权状态资料
					</a-radio>
					<a-radio :value="5">
						已下证状态资料
					</a-radio>
					<a-radio :value="6">
						驳回复审状态资料
					</a-radio>
				</a-radio-group>
			</div>
			<a-upload
				:file-list="upload.fileList"
				:remove="uploadHandleRemove"
				:before-upload="uploadBeforeUpload"
				:multiple="true"
			>
				<a-button>
					<a-icon type="upload" />
					点击上传
				</a-button>
			</a-upload>
		</a-modal> -->
		<!--  导入资料模态框  -->
		<a-modal
			:width="700"
			:visible="importInfo.model"
			@cancel="importInfo.model = false"
			okText="确定"
			@ok="importHandleUpload"
			:ok-button-props="{
				props: { disabled: importInfo.fileList.length === 0 }
			}"
		>
			<a href="/代理专利上传模板.xlsx" download>下载模板</a>
			<a-upload
				:file-list="importInfo.fileList"
				:remove="importHandleRemove"
				:before-upload="importBeforeUpload"
			>
				<a-button :disabled="importInfo.fileList.length >= 1">
					<a-icon type="upload" />
					点击上传
				</a-button>
			</a-upload>
		</a-modal>
		<a-modal
			v-model="exportInfo.exportModel"
			@ok="exportOk"
			:destroyOnClose="true"
		>
			<p style="font-size: 16px;font-weight: 600;margin-bottom: 10px">
				请选择导出的时间：
			</p>
			<a-date-picker
				v-model="exportInfo.exportStartValue"
				:disabled-date="disabledExStartDate"
				format="YYYY-MM-DD 00:00:00"
				placeholder="开始时间"
				style="margin-right: 10px"
				@change="onChangeExStartTime"
				@openChange="handleExStartOpenChange"
			/>
			<a-date-picker
				v-model="exportInfo.exportEndValue"
				:disabled-date="disabledExEndDate"
				format="YYYY-MM-DD 23:59:59"
				placeholder="结束时间"
				:open="exportInfo.endExOpen"
				@change="onChangeExEndTime"
				@openChange="handleExEndOpenChange"
			/>
		</a-modal>
		<!--  查看记录  -->
		<!-- <a-modal
			:width="1300"
			:visible="log.model"
			@cancel="log.model = false"
			:footer="null"
		>
			<a-table
				:columns="log.columns"
				:data-source="log.data"
				bordered
				:pagination="log.pagination"
				rowKey="id"
			>
				<template slot="operation" slot-scope="text, record, index">
					<a-button
						type="primary"
						@click="
							seeDowData(
								1,
								download.pagination.pageSize,
								record.patentStatus,
								record.proxyPatentId
							)
						"
					>
						查看资料
					</a-button>
				</template>
			</a-table>
		</a-modal> -->
		<!--  查看资料模态框  -->
		<a-modal
			:width="700"
			:visible="download.seeDowModel"
			@cancel="download.seeDowModel = false"
			okText="确定"
			@ok="download.seeDowModel = false"
		>
			<div style="height:30px"></div>
			<a-table
				:columns="download.columns"
				:data-source="download.data"
				bordered
				:pagination="download.pagination"
				rowKey="id"
			>
				<template slot="fileName" slot-scope="text, record, index">
					{{ text }}
					<a
						href="javascript:void(0)"
						v-if="record.isImg"
						@click="
							previewVisible = true;
							previewImage = record.url;
						"
					>
						<img alt="example" style="width: 20%" :src="record.url" />
					</a>
				</template>
				<template slot="operation" slot-scope="text, record, index">
					<a
						:download="record.fileNmame"
						:href="record.url"
						v-if="!record.isImg"
						>下载</a
					>
					<a-popconfirm
						title="确定要删除吗?"
						ok-text="确定"
						cancel-text="取消"
						@confirm="deleteFile(record.url)"
					>
						<a-button type="link">删除</a-button>
					</a-popconfirm>
				</template>
			</a-table>
		</a-modal>

		<!-- 变更状态 -->
		<a-modal
			title="变更状态"
			:width="700"
			:visible="statusModelVisible"
			@cancel="statusModelVisible = false"
			@ok="submitStatus"
		>
			<a-form-model
				:model="statusForm"
				ref="statusForm"
				:rules="statusFormRule"
				labelAlign="right"
				:label-col="{ span: 3 }"
				:wrapper-col="{ span: 18 }"
			>
				<a-form-model-item label="状态" prop="patentStatus">
					<a-radio-group name="radioGroup" v-model="statusForm.patentStatus">
						<a-radio :value="2">
							申请中
						</a-radio>
						<a-radio :value="3">
							待答复
						</a-radio>
						<a-radio :value="4">
							已授权
						</a-radio>
						<a-radio :value="5">
							已下证
						</a-radio>
						<a-radio :value="6">
							驳回复审
						</a-radio>
						<a-radio :value="7">
							不通过
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item label="通知时间" prop="stopTime">
					<a-date-picker
						v-model="statusForm.stopTime"
						show-time
						format="YYYY-MM-DD"
					/>
				</a-form-model-item>
				<a-form-model-item label="员工" prop="salesId">
					<a-select
						allowClear
						placeholder="请选择员工"
						style="width: 150px"
						v-model="statusForm.salesId"
					>
						<a-select-option v-for="i in totalSales" :key="i.id" :value="i.id">
							{{ i.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="资料上传">
					<a-upload
						:file-list="upload.fileList"
						:remove="uploadHandleRemove"
						:before-upload="uploadBeforeUpload"
						:multiple="true"
					>
						<a-button>
							<a-icon type="upload" />
							点击上传
						</a-button>
					</a-upload>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	const columns = [
		{
			title: '系统编号',
			dataIndex: 'orderNumber',
			scopedSlots: { customRender: 'orderNumber' }
		},
		{
			title: '内部编号',
			dataIndex: 'insideNumber',
			scopedSlots: { customRender: 'insideNumber' }
		},
		{
			title: '客户名称',
			dataIndex: 'clientName',
			scopedSlots: { customRender: 'clientName' }
		},
		{
			title: '客户手机号',
			dataIndex: 'clientPhone',
			scopedSlots: { customRender: 'clientPhone' }
		},
		{
			title: '员工',
			dataIndex: 'salesName',
			scopedSlots: { customRender: 'salesName' }
		},
		{
			title: '员工手机号',
			dataIndex: 'salesPhone',
			scopedSlots: { customRender: 'salesPhone' }
		},
		{
			title: '专利类型',
			dataIndex: 'clientType',
			scopedSlots: { customRender: 'clientType' }
		},
		{
			title: '专利号',
			dataIndex: 'patentNumber',
			scopedSlots: { customRender: 'patentNumber' }
		},
		{
			title: '专利名称',
			dataIndex: 'patentName',
			scopedSlots: { customRender: 'patentName' }
		},
		// {
		//     title: '申请日期',
		//     dataIndex: 'applyTime',
		//     scopedSlots: {customRender: 'applyTime'},
		// },
		// {
		//     title: '申请人',
		//     dataIndex: 'applyUser',
		//     scopedSlots: {customRender: 'applyUser'},
		// },
		// {
		//     title: '发明人',
		//     dataIndex: 'inventUser',
		//     scopedSlots: {customRender: 'inventUser'},
		// },
		// {
		//     title: '代理人',
		//     dataIndex: 'proxyUser',
		//     scopedSlots: {customRender: 'proxyUser'},
		// },
		{
			title: '专利状态',
			dataIndex: 'patentStatusDesc',
			scopedSlots: { customRender: 'patentStatusDesc' }
		},
		{
			title: '费用类型',
			dataIndex: 'moneyType',
			scopedSlots: { customRender: 'moneyType' }
		},
		{
			title: '金额',
			dataIndex: 'money',
			scopedSlots: { customRender: 'money' }
		},
		{
			title: '缴费状态',
			dataIndex: 'payStatus',
			scopedSlots: { customRender: 'payStatus' }
		},
		{
			title: '截止日期',
			dataIndex: 'stopTime',
			scopedSlots: { customRender: 'stopTime' }
		},
		{
			title: '操作',
			dataIndex: 'operation',
			key: 'operation',
			width: 150,
			scopedSlots: { customRender: 'operation' }
		}
	];
	const logColumns = [
		{
			title: '客户名称',
			dataIndex: 'clientName',
			scopedSlots: { customRender: 'clientName' }
		},
		{
			title: '员工',
			dataIndex: 'salesName',
			scopedSlots: { customRender: 'salesName' }
		},
		{
			title: '专利类型',
			dataIndex: 'clientType',
			scopedSlots: { customRender: 'clientType' }
		},
		{
			title: '专利号',
			dataIndex: 'patentNumber',
			scopedSlots: { customRender: 'patentNumber' }
		},
		{
			title: '专利名称',
			dataIndex: 'patentName',
			scopedSlots: { customRender: 'patentName' }
		},
		{
			title: '申请日期',
			dataIndex: 'applyTime',
			scopedSlots: { customRender: 'applyTime' }
		},
		{
			title: '专利状态',
			dataIndex: 'patentStatusDesc',
			scopedSlots: { customRender: 'patentStatusDesc' }
		},
		{
			title: '费用类型',
			dataIndex: 'moneyType',
			scopedSlots: { customRender: 'moneyType' }
		},
		{
			title: '金额',
			dataIndex: 'money',
			scopedSlots: { customRender: 'money' }
		},
		{
			title: '缴费状态',
			dataIndex: 'payStatus',
			scopedSlots: { customRender: 'payStatus' }
		},
		{
			title: '截止日期',
			dataIndex: 'stopTime',
			scopedSlots: { customRender: 'stopTime' }
		},
		{
			title: '备注',
			dataIndex: 'remark',
			scopedSlots: { customRender: 'remark' }
		},
		{
			title: '操作人',
			dataIndex: 'optionUser',
			scopedSlots: { customRender: 'optionUser' }
		},
		{
			title: '操作人手机号',
			dataIndex: 'optionUserPhone',
			scopedSlots: { customRender: 'optionPeople' }
		},
		{
			title: '操作',
			dataIndex: 'operation',
			key: 'operation',
			scopedSlots: { customRender: 'operation' }
		}
	];
	const historyColumns = [
		{
			title: '时间',
			dataIndex: 'createTime'
		},
		{
			title: '变更状态',
			dataIndex: 'patentStatusDesc'
		},
		{
			title: '操作人',
			dataIndex: 'optionUser'
		},
		{
			title: '操作',
			dataIndex: 'operation',
			key: 'operation',
			scopedSlots: { customRender: 'operation' }
		}
	];
	export default {
		name: 'proxy',
		data() {
			return {
				download: {
					seeDowModel: false,
					columns: [
						{
							title: '资料名称',
							dataIndex: 'fileName',
							scopedSlots: { customRender: 'fileName' }
						},
						{
							title: '操作',
							dataIndex: 'operation',
							key: 'operation',
							width: '25%',
							scopedSlots: { customRender: 'operation' }
						}
					],
					data: [],
					pagination: {
						current: 1,
						pageSize: 10,
						total: 100,
						onChange: (page, pageSize) => {
							this.getData(page, pageSize);
						}
					}
				},
				clientInfo: [],
				log: {
					model: false,
					columns: logColumns,
					data: [],
					pagination: {
						current: 1,
						pageSize: 10,
						total: 0,
						onChange: (current) => {
							this.log.pagination.current = current;
							this.seeLog(this.selectId);
						}
					}
				},
				upload: {
					model: false,
					fileList: [],
					smallType: 0
				},
				importInfo: {
					model: false,
					fileList: []
				},
				exportInfo: {
					exportModel: false,
					exportEndValue: null,
					endExOpen: false,
					exportStartValue: null,
					exportStartTime: null,
					exportEndTime: null
				},
				pagination: {
					current: 1,
					pageSize: 10,
					total: 100,
					onChange: (page, pageSize) => {
						this.getData(page, pageSize);
					}
				},
				search: {
					endTimeVal: null,
					startTimeVal: null,
					endTime: null,
					startTime: null,
					endOpen: false,
					orderNumber: null,
					insideNumber: null,
					clientName: null,
					clientPhone: null,
					patentNumber: null,
					patentName: null,
					applyUser: null,
					inventUser: null,
					proxyUser: null,
					patentStatus: null,
					applyPayStatus: null,
					replyPayStatus: null,
					warrantPayStatus: null,
					reviewPayStatus: null,
					yearPayStatus: null,
					orderType: null
				},
				columns,
				data: [],
				form: {
					clientType: null,
					proxyUser: null,
					remark: null,
					clientName: null,
					clientPhone: null,
					salesName: null,
					salesPhone: null,
					patentNumber: null,
					patentName: null,
					applyUser: null,
					inventUser: null,
					applyTime: null,
					salesSelect: []
				},
				updateForm: {
					money: null,
					payStatus: null,
					stopTime: null,
					patentStatus: null,
					id: null,
					time: null
				},
				changePatentStatusValue: null,
				addOrUpdateModel: false,
				updateModel: false,
				seeInfoData: {},
				seeInfoModel: false,
				insideNumber: null,
				addOrUpdateOption: '添加',
				formRole: {
					insideNumber: [
						{ required: true, message: '内部编号不能为空', trigger: 'blur' }
					],
					clientPhone: [
						{ required: true, message: '客户不能为空', trigger: 'blur' }
					],
					clientType: [
						{ required: true, message: '专利类型不能为空', trigger: 'blur' }
					],
					patentName: [
						{ required: true, message: '专利名称不能为空', trigger: 'blur' }
					],
					applyTime: [
						{ required: true, message: '申请日期不能为空', trigger: 'blur' }
					],
					proxyUser: [
						{ required: true, message: '代理机构不能为空', trigger: 'blur' }
					],
					applyUser: [
						{ required: true, message: '申请名称不能为空', trigger: 'blur' }
					],
					inventUser: [
						{ required: true, message: '发明人1不能为空', trigger: 'blur' }
					]
				},
				previewVisible: false,
				previewImage: null,
				statusModelVisible: false,
				statusForm: {
					patentStatus: '',
					stopTime: '',
					salesId: ''
				},
				statusFormRule: {
					patentStatus: [
						{ required: true, message: '请选状态', trigger: 'change' }
					],
					stopTime: [
						{ required: true, message: '请选时间', trigger: 'change' }
					],
					salesId: [{ required: true, message: '请选员工', trigger: 'change' }]
				},
				totalSales: [],
				history: {
					columns: historyColumns,
					data: [],
					pagination: {
						current: 1,
						pageSize: 10,
						total: 0,
						onChange: (current) => {
							this.history.pagination.current = current;
							this.seeLog(this.selectId);
						}
					}
				},
				selectId: ''
			};
		},
		mounted() {
			this.getData(this.pagination.current, this.pagination.pageSize);
			this.getClienInfo();
			this.getTotalSales();
		},
		methods: {
			async getData(pageNum, pageSize) {
				const { data } = await this.$axios.get(
					'/api/v1/admin/proxy/proxy/patent/get/' + pageNum + '/' + pageSize,
					{
						params: {
							orderNumber: this.search.orderNumber,
							insideNumber: this.search.insideNumber,
							clientName: this.search.clientName,
							clientPhone: this.search.clientPhone,
							patentNumber: this.search.patentNumber,
							patentName: this.search.patentName,
							applyUser: this.search.applyUser,
							inventUser: this.search.inventUser,
							proxyUser: this.search.proxyUser,
							patentStatus: this.search.patentStatus,
							applyPayStatus: this.search.applyPayStatus,
							replyPayStatus: this.search.replyPayStatus,
							warrantPayStatus: this.search.warrantPayStatus,
							reviewPayStatus: this.search.reviewPayStatus,
							yearPayStatus: this.search.yearPayStatus,
							orderType: this.search.orderType,
							startTime: this.search.startTime,
							endTime: this.search.endTime
						}
					}
				);
				this.data = data.data.list;
				this.pagination.current = data.data.pageNum;
				this.pagination.pageSize = data.data.pageSize;
				this.pagination.total = data.data.total;
			},
			// 查询客户信息
			async getClienInfo(v) {
				if (v != undefined) {
					var { data } = await this.$axios.get(
						'/api/v1/common/search/3/1/100000',
						{
							params: {
								search: v
							}
						}
					);
				} else {
					var { data } = await this.$axios.get(
						'/api/v1/common/search/3/1/100000'
					);
				}
				this.clientInfo = data.data.list;
			},
			filterOption(input, option) {
				return (
					option.componentOptions.children[0].text
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				);
			},
			selectBusinessType(value) {
				if (value === '0') {
					this.search.patentStatus = null;
				} else {
					this.search.patentStatus = value;
				}
				this.getData(1, this.pagination.pageSize);
			},
			applyPayChooseType(value) {
				if (value === '2') {
					this.search.applyPayStatus = null;
				} else {
					this.search.applyPayStatus = value;
				}
				this.getData(1, this.pagination.pageSize);
			},
			warranPayChooseType(value) {
				if (value === '2') {
					this.search.warrantPayStatus = null;
				} else {
					this.search.warrantPayStatus = value;
				}
				this.getData(1, this.pagination.pageSize);
			},
			reviewPayChooseType(value) {
				if (value === '2') {
					this.search.reviewPayStatus = null;
				} else {
					this.search.reviewPayStatus = value;
				}
				this.getData(1, this.pagination.pageSize);
			},
			yearPayChooseType(value) {
				if (value === '2') {
					this.search.yearPayStatus = null;
				} else {
					this.search.yearPayStatus = value;
				}
				this.getData(1, this.pagination.pageSize);
			},
			orderTypeChooseType(value) {
				this.search.orderType = value;
				this.getData(1, this.pagination.pageSize);
			},
			// 查看信息
			seeInfo(record) {
				this.selectId = record.id;
				this.seeInfoData = record;
				this.seeInfoModel = true;
				this.history.data = [];
				this.seeLog();
			},
			async changePatentStatus(id) {
				const { data } = await this.$axios.post(
					'/api/v1/admin/proxy/proxy/patent/update',
					{
						id: id,
						patentStatus: this.changePatentStatusValue
					}
				);
				this.$message.success('修改成功！');
				this.getData(1, this.pagination.pageSize);
			},
			// 查看记录
			async seeLog() {
				const { data } = await this.$axios.get(
					'/api/v1/admin/proxy/proxy/patent/log/get/' +
						this.selectId +
						'/' +
						this.history.pagination.current +
						'/' +
						this.history.pagination.pageSize
				);
				console.log(data);
				this.history.data = data.data.list || [];
				this.history.pagination.total = data.data.total;
			},
			async deleteFunc(id) {
				const { data } = await this.$axios.post(
					'/api/v1/admin/proxy/proxy/patent/delete',
					{
						id: id
					}
				);
				this.$message.success('删除成功！');
				this.getData(this.pagination.current, this.pagination.pageSize);
			},
			// 添加或修改
			add(op) {
				this.addOrUpdateModel = true;
				this.form = {
					clientType: null,
					proxyUser: null,
					clientName: null,
					cooperPhone: null,
					recommendUserName: null,
					recommendUserPhone: null,
					patentNumber: null,
					applyUser: null,
					inventUser: null,
					applyTime: null
				};
				this.upload.fileList = [];
				this.addOrUpdateOption = op;
			},
			updateInfo(record) {
				this.addOrUpdateModel = true;
				this.form = {
					...record,
					salesSelect: record.salesId ? record.salesId.split(',').map(item => Number(item)) : []
				};
				this.addOrUpdateOption = '修改';
			},
			async updateModelSublimeOk() {
				const { data } = await this.$axios.post(
					'/api/v1/admin/proxy/proxy/patent/update',
					this.updateForm
				);
				this.$message.success('操作成功！');
				this.updateModel = false;
				this.getData(1, this.pagination.pageSize);
			},
			update(record) {
				this.updateModel = true;
				this.updateForm.id = record.id;
				this.updateForm.money = record.money;
				this.updateForm.stopTime = record.stopTime;
				this.updateForm.payStatus = record.payStatus;
				this.updateForm.patentStatus = record.patentStatus;
			},
			// 选择客户后
			chooseClient(v) {
				console.log(v);
				for (let i = 0; i < this.clientInfo.length; i++) {
					let clientInfoElement = this.clientInfo[i];
					if (clientInfoElement.clientPhone === v) {
						// this.form.salesName = clientInfoElement.salesName;
						// this.form.salesPhone = clientInfoElement.salesPhone;
						this.form.clientName = clientInfoElement.clientName;
					}
				}
			},
			sublimeOk() {
				this.$refs.form.validate(async (valid) => {
					if (valid) {
						const salesName = [];
						const salesPhone = [];
						this.form.salesSelect.forEach((id) => {
							console.log(id);
							for (const item of this.totalSales) {
								if (item.id === id) {
									if (item.name) salesName.push(item.name);
									if (item.phone) salesPhone.push(item.phone);
									break;
								}
							}
						});
						this.form.salesName = salesName.join(',');
						this.form.salesPhone = salesPhone.join(',');
						this.form.salesId = this.form.salesSelect.join(',');
						if (this.addOrUpdateOption === '添加') {
							const { data } = await this.$axios.post(
								'/api/v1/admin/proxy/proxy/patent/add',
								this.form
							);
						} else {
							const { data } = await this.$axios.post(
								'/api/v1/admin/proxy/proxy/patent/update',
								this.form
							);
						}
						this.$message.success('操作成功！');
						this.addOrUpdateModel = false;
						this.getData(1, this.pagination.pageSize);
					}
				});
			},
			// 查看下载资料
			async seeDowData(pageNum, pageSize, smallType, id) {
				const { data } = await this.$axios.get(
					'/api/v1/common/get/file/' +
						id +
						'/3/' +
						pageNum +
						'/' +
						pageSize +
						'?smallType=' +
						smallType
				);
				this.download.data = data.data.list;
				this.download.pagination.current = data.data.pageNum;
				this.download.pagination.pageSize = data.data.pageSize;
				this.download.pagination.total = data.data.total;
				this.download.seeDowModel = true;
			},
			//  删除文件
			async deleteFile(url) {
				this.$commJs.deleteFile(this, url);
			},
			// 上传资料
			uploadHandleRemove(file) {
				const index = this.upload.fileList.indexOf(file);
				const newFileList = this.upload.fileList.slice();
				newFileList.splice(index, 1);
				this.upload.fileList = newFileList;
			},
			uploadBeforeUpload(file) {
				this.upload.fileList = [...this.upload.fileList, file];
				return false;
			},
			async uploadHandleUpload() {
				// 上传代码
				for (let i = 0; i < this.upload.fileList.length; i++) {
					let file = this.upload.fileList[i];
					let formData = new FormData();
					formData.append('file', file);
					await this.$axios.post(
						'/api/v1/common/upload/file?type=3&uploadTypeId=' +
							this.seeInfoData.id +
							'&smallType=' +
							this.upload.smallType,
						formData
					);
				}
				this.upload.fileList = [];
				this.upload.model = false;
				this.$message.success('上传成功！');
			},
			// 格式化时间
			disabledStartDate(startValue) {
				const endValue = this.search.endTimeVal;
				if (!startValue || !endValue) {
					return false;
				}
				return startValue.valueOf() > endValue.valueOf();
			},
			disabledEndDate(endValue) {
				const startValue = this.search.startTimeVal;
				if (!endValue || !startValue) {
					return false;
				}
				return startValue.valueOf() >= endValue.valueOf();
			},
			onChangeUpdateStopTime(value, dataString) {
				this.updateForm.stopTime = this.$commJs.formatStrTime(dataString);
			},
			onChangeStartTime(value, dateString) {
				this.search.startTime = this.$commJs.formatStrTime(dateString);
				this.getData(1, this.pagination.pageSize);
			},
			onChangeEndTime(value, dateString) {
				this.search.endTime = this.$commJs.formatStrTime(dateString);
				this.getData(1, this.pagination.pageSize);
			},
			handleStartOpenChange(open) {
				if (!open) {
					this.search.endOpen = true;
				}
			},
			handleEndOpenChange(open) {
				this.search.endOpen = open;
			},
			// 导入资料
			importHandleRemove(file) {
				const index = this.importInfo.fileList.indexOf(file);
				const newFileList = this.importInfo.fileList.slice();
				newFileList.splice(index, 1);
				this.importInfo.fileList = newFileList;
			},
			importBeforeUpload(file) {
				this.importInfo.fileList = [...this.importInfo.fileList, file];
				return false;
			},
			async importHandleUpload() {
				// 上传代码
				for (let i = 0; i < this.importInfo.fileList.length; i++) {
					let file = this.importInfo.fileList[i];
					let formData = new FormData();
					formData.append('file', file);
					await this.$axios.post(
						'/api/v1/admin/proxy/proxy/patent/add/upload',
						formData
					);
				}
				// 上传代码
				this.importInfo.fileList = [];
				this.importInfo.model = false;
				this.$message.success('上传成功！');
				this.getData(1, this.pagination.pageSize);
			},
			//导出
			disabledExStartDate(startValue) {
				const endValue = this.exportInfo.exportEndValue;
				if (!startValue || !endValue) {
					return false;
				}
				return startValue.valueOf() > endValue.valueOf();
			},
			disabledExEndDate(endValue) {
				const startValue = this.exportInfo.exportStartValue;
				if (!endValue || !startValue) {
					return false;
				}
				return startValue.valueOf() >= endValue.valueOf();
			},
			onChangePayTime(value, dateString) {
				this.form.applyTime = this.$commJs.formatStrTime(dateString);
			},
			onChangeExStartTime(value, dateString) {
				this.exportInfo.exportStartTime = this.$commJs.formatStrTime(
					dateString
				);
			},
			onChangeExEndTime(value, dateString) {
				this.exportInfo.exportEndTime = this.$commJs.formatStrTime(dateString);
			},
			handleExStartOpenChange(open) {
				if (!open) {
					this.exportInfo.endExOpen = true;
				}
			},
			handleExEndOpenChange(open) {
				this.exportInfo.endExOpen = open;
			},
			// 导出
			async exportOk() {
				this.$commJs.exportExcel(
					this,
					'/api/v1/admin/export/proxy/patent/' +
						this.exportInfo.exportStartTime +
						'/' +
						this.exportInfo.exportEndTime,
					'代理专利-' +
						this.exportInfo.exportStartTime +
						'~' +
						this.exportInfo.exportEndTime
				);
				this.exportInfo.exportModel = false;
			},
			async getTotalSales() {
				const { data } = await this.$axios.get(
					'/api/v1/admin/info/sales/get/1/100000'
				);
				this.totalSales = data.data.list;
			},
			// 点击整行显示详情
			handleRowClick(record) {
				this.seeInfo(record);
			},
			changeStatusClick(record) {
				console.log(record);
				this.selectId = record.id;
				this.statusForm.stopTime = '';
				this.statusForm.salesId = '';
				this.statusForm.patentStatus = record.patentStatus;
				this.statusModelVisible = true;
			},
			submitStatus() {
				console.log(this.statusForm);
				this.$refs.statusForm.validate(async (valid) => {
					if (valid) {
						try {
							await this.$axios.post(
								'/api/v1/admin/proxy/proxy-update-status-new',
								{
									id: this.selectId,
									patentStatus: this.statusForm.patentStatus,
									stopTime: moment(this.statusForm.stopTime).format(
										'YYYY-MM-DD'
									),
									salesId: this.statusForm.salesId
								}
							);
							// 上传资料
							for (let i = 0; i < this.upload.fileList.length; i++) {
								let file = this.upload.fileList[i];
								let formData = new FormData();
								formData.append('file', file);
								await this.$axios.post(
									'/api/v1/common/upload/file?type=3&uploadTypeId=' +
										this.selectId +
										'&smallType=' +
										this.statusForm.patentStatus,
									formData
								);
							}
							this.upload.fileList = [];
							this.$message.success('成功！');
							this.statusModelVisible = false;
							this.getData(this.pagination.current, this.pagination.pageSize);
						} catch (error) {
							console.log(error);
							this.$message.error('失败！');
						}
					}
				});
			}
		}
	};
</script>

<style scoped></style>
