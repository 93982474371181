import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import commonJs from "./js/commonJs";
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(Antd);

Vue.config.productionTip = false

Vue.prototype.$axios = axios;

Vue.prototype.$commJs = commonJs

// 默认域名
// Vue.prototype.$axios.defaults.baseURL = 'http://127.';

// token校验
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(store.state.tokenName)
    if (token) {
        config.headers.Authorization = token
        store.state.tokenValue = token
    }
    return config
})

// 路由导航守卫
router.beforeEach((to, from, next) => {
    let isAuthenticated = false;
    let token = localStorage.getItem(store.state.tokenName);
    if (token) {
        isAuthenticated = true
    }
    if (to.name !== 'login' && !isAuthenticated) next({name: 'login'})
    else next()
    next()
})
//ajax超时
Vue.prototype.$axios.defaults.timeout = 10000;
Vue.filter('coin', function (val) {
    if (!isNaN(val)) {
        let coin = Number(val);
        return coin.toFixed(2);
    } else {
        return '';
    }
});
// 请求结束
Vue.prototype.$axios.interceptors.response.use((res) => {
    store.state.loadding = false; //在请求发出之后进行一些操作
    if (res.status === 200) {
        // token失效
        if (res.data.code === 402) {
            router.push('/login')
            Vue.prototype.$message.error(res.data.message)
        }else if(res.data.code === 411){
            router.push('/login')
        }
        else if (res.data.code !== 200 && res.data.code !== undefined) {
            Vue.prototype.$message.error(res.data.message)
        }
        return res
    } else {
        Vue.prototype.$message.error(res.data.message)
    }
}, (err) => {
    if (err.response) {
    }
    return Promise.reject(err)
})
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
